.site-layout .site-layout-background{
  position: relative;
}
.UserPermissions {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // position: relative;
  table {
    width: 30%;
    margin-top: 30px;
    th + th {
      width: 30%;
    }
    th {
      border-bottom: 1px solid rgba(128, 128, 128, 0.452);
      height: 30px;
    }
    td {
      border-bottom: 1px solid rgba(128, 128, 128, 0.295);
    }
  }
  .seperater {
    width: 1px;
    height: 100%;
    background-color: red;
  }
  .buttonDiv {
    width: calc(100%);
    padding: 10px;
    background: white;
    position: sticky;
    top: -24px;
    // margin-top: -24px;
    z-index: 999;
    display: -webkit-flex; 
     display: flex; 
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-left: -10px;
  }
}
