@font-face {
  font-family: Lato;
  src: url("./assets/Lato/Lato-Regular.ttf");
}



html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Lato !important;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.ant-layout-has-sider .logo {
  height: 32px;
  margin: 16px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-has-sider .logo img {
  height: 100%;
}
.ant-layout-has-sider .logo h3 {
  color: white;
  margin: 0;
  margin-left: 10px;
}

.site-layout .site-layout-background {
  background: #fff;
}
.ant-layout-has-sider {
  height: 100vh;
}
.ant-layout-has-sider .anticon-menu-fold,
.ant-layout-has-sider .anticon-menu-unfold {
  padding: 10px;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-header {
  display: flex;
  text-transform: capitalize;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #001529;
  border-color: #001529;
}

.ant-checkbox-checked::after,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #001529;
}
@media print{
  #bigCertificate {
      zoom:75%
    }
      .hollograme{
        display: none;
      }
}
#bigCertificate{
  text-transform: capitalize;
}
