.Consultant {
  .innerCons {
    width: 500px;
    margin: auto;
    padding: 10px;
    box-shadow: 1px 1px 8px 0px #00000061;
    margin-top: 20px;
    table {
      th {
        font-weight: bold;
      }
      th,
      td {
        font-size: 14px;
        button {
          border: none;
          color: white;
          background-color: rgb(0, 21, 41);
        }
      }
    }
  }
  .searchDiv {
    .spaceBetween {
      align-items: flex-end;
    }
    .inputFields {
      width: 100%;
      font-size: 14px;
      .ant-input-number {
        width: 100%;
      }
      .select {
        width: 100%;
      }
      .ant-input-number,
      .select {
        height: 35px;
      }
      label {
        h5 {
          margin: 5px 0px 4px 0px;
        }
      }
      .checkboxGroup {
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center;
      }
      input {
        width: 100%;
        height: 40px;
      }
    }
    .consultSearchBtn {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin-right: 5px;
      }
    }
  }
  .innerConHead {
    text-align: center;
    padding: 20px 0px;
    font-weight: bold;
    font-size: 24px;
  }
}
.consModal {
  .buttonDivCon {
    button {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  .consultForm {
    .spaceBetween {
      align-items: flex-end;
    }
    p {
      margin: 0px;
      font-weight: bold;
    }
  }
  .consultFormInput {
    margin-top: 10px;
  }
}
.ConsultantDetailsModal {
  .consultationHeading {
    display: flex;
    align-items: center;
    h3 {
      width: 50%;
      text-align: center;
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
}
.consultattionWeightInput {
  display: flex;
  align-items: center;
  select {
    height: 32px;
    width: 70px;
    position: relative;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  input {
    width: calc(100% - 70px);
  }
}
