.AddPrices {
    .innerAddPrice {
        display: flex;
        .tabsDiv {
            max-width: 210px;
            width: 100%;
            // padding: 0px 20px;
            box-shadow: 2px 0px 2px -2px black;
            .tab {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 20px;
                cursor: pointer;
                transition: 0.3s;
                &:hover{
                    background: #80808014;
                }
                p {
                    font-size: 12px;
                    margin: 0%;
                }
                svg {
                    font-size: 12px;
                }
            }
        }
        .priceForm {
            padding: 10px 0px 0px;
            padding-left: 50px;
            .fieldPrice {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                padding: 10px 0px;
                h3 {
                    margin-right: 20px;
                }
                input {
                    margin-right: 10px;
                }
            }
            button {
                background-color: #ce0001;
                color: white;
                font-size: 12px;
                font-weight: bold;
                border: none;
                padding: 4px 15px;
                border-radius: 15px;
                box-shadow: 0 0 8px -2px black;
            }
        }
    }
}
