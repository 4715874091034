.takeInPrint {
    padding: 24px;
    .innerRecieptPage {
        width: 100%;
        zoom: 100% !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .printRecipt {
            width: 47%;
            .headReciept {
                font-size: 16px;
                text-align: center;
            }
            .recieptDetail {
                display: flex;
                .table1 {
                    width: 60%;
                }
                .table2 {
                    width: 40%;
                }
                .customerDet {
                    th {
                        font-size: 11.4px;
                        font-weight: bold;
                    }
                    td {
                        font-size: 14.4px;
                    }
                }
                .table2 {
                    font-size: 14.4px;
                    td {
                        text-align: right;
                    }
                }
            }
        }
        .itemListRecipt {
            table {
                border-collapse: collapse;
                border: 1px solid black;
                width: 100%;
                font-size: 0.84em;
                th {
                    border: 1px solid black;
                    background: #b2b2b2;
                    font-size: 13.44px;
                    text-align: center;
                }
                td {
                    text-align: center;
                    font-size: 13.44px;
                }
                .td1 {
                    font-size: 0.9em;
                    font-style: italic;
                }
            }
        }
        .recipetTotalTable {
            width: 45%;
            float: right;
            font-size: 12.8px;
            margin-top: 15px;
            tr {
                th + th {
                    text-align: right;
                }
            }
        }
    }
    .recipetTotal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .signCust {
            margin-top: 20px;
            padding: 0px 15px;
            p {
                font-size: 11px;
                text-align: center;
                font-weight: 700;
            }
            .signBox{
                text-align: center;
                font-weight: 600;
                margin-top: 30px;
            }
        }
    }

}
.printModal{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: #ffffff8f;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    flex-direction: column;

}
.printReciptBtn {
    background: #d40000;
    color: white;
    border: none;
    padding: 5px 20px;
    font-weight: bold;
    border-radius: 25px;
    margin: 0px 10px;
}
.backReciptBtn {
    background: #41a4ff;
    color: white;
    border: none;
    padding: 5px 20px;
    font-weight: bold;
    border-radius: 25px;
    margin: 0px 10px;
    margin-right: 5px;
}
