button {
	cursor: pointer;
}

@font-face {
	font-family: "bookOld";
	src: url(./../../assets/bookman-old-style/BOOKOSB.TTF);
}
@media print {
	.printBtnCert {
		display: none;
	}
	.linedevClassName {
		border-right: none !important;
	}
	.linedevClass {
		border-right: none !important;
	}
	.capture {
		margin-top: 0px;
	}
	.logoName {
		font-size: 28px;
		font-family: "bookOld";
	}
	.frontside,
	.backside {
		margin: 0px auto;
		width: 8.7cm;
		height: 5.3cm;
		/* border: 1px solid #5F5E5E; */
		border-radius: 15px;
		margin: 0 auto;
		padding: 6px 6px 6px 7px;
		background-image: url(./../../Images/certificate-gradient.png);
	}

	// .cardMain{
	//     transform: scale(1.3);
	//     // margin: 60px;
	// }
	// #capture{
	//     transform: scale(110%);
	//   }
}
#capture {
	.MsoNormal {
		font-family: bookOld !important;
	}
}
.tableDiv {
	position: relative;
	.tableLoader {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 99;
		img {
			width: 60px;
		}
	}
}
.diamondGrading {
	.innerDiamondGrading {
		.filter {
			margin-bottom: 10px;
			.spaceBetween {
				align-items: flex-end;
			}
			.filterButtons {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				.searchButton {
					background: #1890ff;
					color: white;
					border: none;
					margin-right: 5px;
				}
				.toFormBtn {
					border: 1px solid #001529;
					/* height: 100%; */
					padding: 5px 6px;
					font-size: 14px;
					background: #001529;
					color: white;
					font-weight: mar;
					font-weight: 500;
				}
			}
		}
		.diamondImage {
			width: 70px;
			height: 48px;
		}
		.itemsTable {
			.actionBtn {
				padding: 5px 20px;
				background: transparent;
				border: 1px solid #001529;
				font-weight: bold;
				color: #001529;
				border-radius: 5px;
				outline: none;
				transition: 0.2s;
				&:hover {
					background: #001529;
					color: white;
				}
			}
			.editButton {
				background: #1890ff;
				border: 1px solid #045fb3;
				color: white;
			}
			.editIcon {
				background: transparent;
				border: none;
			}
			.DeleteBtn {
				background: #d40000;
				border: 1px solid #d40000;
				color: white;
			}
			.entryNoBtn {
				border-radius: 2px;
				padding: 5px 10px;
				font-size: 11px;
				font-weight: 700;
				border: 1px solid #001529;
				background: #001529;
				color: white;
				transition: 0.2s;
				cursor: pointer;
				width: fit-content;
				&:hover {
					background: white;
					color: #001529;
				}
			}
		}
	}
}
.DiamondGradingFrom {
	.inputDiv {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		&.commnetInput{
			flex-direction: column;
		}
		.keyToSymbol {
			display: flex;
			flex-wrap: wrap;
			img {
				width: 20px;
			}
			label {
				width: auto;
				margin: 0;
			}
		}
		.ant-upload-picture-card-wrapper.avatar-uploader {
			width: 107px;
			margin-right: 10px;
		}
		input,
		label,
		.select {
			width: 100%;
		}
		.weightDiv {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.wieghtNumber {
				display: flex;
				align-items: center;
				.ant-input-number {
					width: 70px;
				}
			}
		}
		.dflex {
			display: flex;
		}
		.cutDiv {
			.select {
				width: 60%;
			}
			input {
				width: 40%;
			}
		}
		.griddle {
			.select {
				width: 25%;
			}
			input {
				width: 50%;
			}
		}
	}
	.conditionTale {
		width: 100%;
		th,
		td {
			border: 1px solid #afafaf;
			padding: 6px 5px;
			font-size: 12px;
		}
		th {
			display: flex;
			align-items: center;
			justify-content: space-between;
			input {
				width: 30%;
			}
			p {
				margin: 0px;
			}
		}
	}
	.saveButton {
		button {
			padding: 7px 27px;
			font-size: 11px;
			background: #cd0101;
			color: white;
			font-weight: bold;
			border: 1px solid #cd0101;
			border-radius: 5px;
		}
	}
}
.innerDiamondGrading {
	.itemsTable {
		border: 1px solid #0000000f;
	}
}

main {
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 2px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 50px;
	}

	/* Handle on hover */
	& ::-webkit-scrollbar-thumb:hover {
		background: #555;
		border-radius: 50px;
	}
}
.remarkOrigin {
	justify-content: space-between;
	label {
		width: 49% !important;
	}
}
.smallJewellery,
.smallGemstone {
	.searchButton,
	.toFormBtn,
	.printButton {
		width: 32%;
		padding: 5px 6px;
		text-align: center;
	}
	.searchButton {
		width: 100%;
	}
	.printButton {
		background: #d10001;
		color: white;
		border: none;
		margin-right: 5px;
	}
}
.SmallJewelleryForm,
.smallGemstone {
	.maxWord {
		font-size: 9px;
	}
}
.desFormJewellery {
	background-color: gray;
	text-align: right;
	padding: 10px 20px;
	font-weight: bold;

	h2 {
		color: white;
		margin: 0;
	}
}
.jewelleryReport {
	.inputDiv {
		padding: 5px;
	}
	.desFormJewellery {
		margin: 20px 0px;
	}
	.addFieldBtn {
		background: #1890ff;
		margin-right: 10px;
		border: #1890ff;
		margin: 10px 10px 10px 0px;
	}
	.removeItemButton {
		border-radius: 6px;
		background: #001529;
		color: white;
		font-size: 11px;
		border: none;
		padding: 8px 33px;
	}
}

// @page
// {
//     size: A4;   /* auto is the current printer page size */
//     /*margin-top: 5mm;*/  /* this affects the margin in the printer settings */
//     margin: 0;
// }
// body
// {
//     background-color:#FFFFFF;
//     /* border: solid 1px black ;*/
//     /*   margin: 0px;  the margin on the content before printing */
// }
// @media print {
//     #printbtn { display :  none !important}

//     /* .backside {display :  none;} */

//     .frontside{
//         height: 8.5cm;
//         width: 5.3cm;
//     }
//     .backside{
//         height: 8.5cm;
//         width: 5.3cm;
//     }
//     .frontside, .backside{
//       /*  border: 1px solid rgba(94,94,94, .3)!important;*/
//     }
//     .report-wrapper{

//         width: 100%;

//     }
//     .pagebreak { page-break-before: always; }

// }

// // @font-face {
// //     font-family: bookman;
// //     src: url('fonts/bookmanoldstyle.ttf');
// //     font-weight: bold;
// // }
// // @font-face {
// //     font-family: calibri;
// //     src: url('fonts/calibri.ttf');
// //     font-weight: bold;
// // }
// body{
//     margin-top: 1px;
//     font-family:"Times New Roman";
// }
// .report-wrapper table{
//     margin:0 auto;
// }
// .report-wrapper {
//     width: 100%;
// }
// .backside, .frontside {
//     margin: 0px auto;
//     width: 8.5cm;
//     height:5.3cm;
//     /*border: 1px solid #5F5E5E;*/
//     border-radius: 15px;
//     margin: 0 auto;
//     // background-image: url("background-gradient.png");
//     padding: 6px 6px 6px 7px;
// }
// .verticaltext {
//     transform: rotate(-90deg);
//     transform-origin: right, top;
//     -ms-transform: rotate(-90deg);
//     -ms-transform-origin: right, top;
//     -webkit-transform: rotate(-90deg);
//     -webkit-transform-origin: right, top;
//     position: relative;
//     right: 0px;
//     color: black;
//     display: inline-block;
//     top: -22px;
//     float: right;
//     left: 20px;
// }

// .top{
//     height:1.1cm;
//     border-bottom: 2px solid rgba(0, 0, 0, 1);
// }
// .logo{
//     float:left;
//     width:20%;
// margin-left: 10px;
// margin-right: -15px;

//     /*border:1px solid grey;*/
//     /*	margin-top:6px;
//         margin-left:18px;*/
// }
// .top h1, .top h2 {
//     float: left;
//     font-weight: 900;
//     margin-top: 5px;
//     margin-bottom: 5px;
// }
// .top h1 {
//     font-size: 17px;
//     /*font-size: 26px;*/
//     margin-top: 1px;
//     font-family: "Bookman Old Style","serif";
// }
// .top h2{
//     font-family: Arial;
//     font-size: 11px;
//     font-style:italic;
//     float:left;
//     margin-top: -4px;
// }
// .middle{
//     float:left;
//     width: 8.4cm;
//     height:3.8cm;
//     // background-image: url('background-transparent.png');
//     background-size: 125px;
//     background-repeat: no-repeat;
//     background-position: center;
//     position: relative;
// }
// .content{
//     width: 8.2cm;
//     float: left;
//     padding: 4px;
// }
// .content ul{
//     padding:2px;
//     margin:5px;
//     list-style:none;

// }
//         .product-image {
//     width: 2.5cm;
//     height: 1.65cm;
//     position: absolute;
//     top: 1px;
//     right: 0px;
//     float: right;
//     text-align: center;
//     display: inline-block;
// }
// .content ul li {
//     padding: 2.5px;
//     list-style: none;
//     font-family: arial,verdana,sans-serif;
//     font-size:8px;
//     width: 8cm;
// }

// .bottom p{
//     text-align:center;
// }
// .bottom {
//     clear: both;
//     /*height: 0.2cm;*/
//     border-top: 2px solid rgba(0, 0, 0, 1);
//     text-align:center;

// }
// .backside {
//     font-family:arial,verdana,sans-serif;
//     text-align:center;
// }
// .backside h4{
//     font-size:10px ;
//     margin:10px 0px 1px 0px !important;
// }
// .backside h2{
//     color:#939393;
//     font-size:10px ;
//     margin:10px 0px 13px 0px !important;

// }

// .backside p{
//     text-align: justify;
//     padding: 3px;
//     font-size:6.1px;
//     word-spacing: 2px;
//     margin:8px 0px 1px 0px !important;
// }
// .line{
//     border: 1px solid rgba(0, 0, 0, 1);
//     margin:7px 0px 3px 0px !important;
// }
//  .backside span.last{
//     text-align: center;
//     word-spacing: normal;
//     font-size:6px;
//     vertical-align: top;
// }
// .frontside span.last{
//     text-align: center ;
//     word-spacing: normal;
//     font-weight: 700;
//     font-size:9px;
//     vertical-align: middle;
//     font-family: Sans-Serif;
// }

// .backside,
// .frontside {
//     font-family: "Times New Roman";
//     width: 8.5cm;
//     height: 5.3cm;
//     border-radius: 15px;
//     margin: 0 auto;
//     // background-image: url("background-gradient.png");
//     padding: 6px 6px 6px 7px;
// .top {
//     font-family: "Times New Roman";
//     height: 1.1cm;
//     border-bottom: 2px solid rgba(0, 0, 0, 1);
// }
// .logo {
//     font-family: "Times New Roman";
//     float: left;
//     width: 20%;
//     margin-left: 10px;
//     margin-right: -15px;
// }
// .top h1 {
//     float: left;
//     font-weight: 900;
//     margin-bottom: 5px;
//     font-size: 17px;
//     margin-top: 1px;
//     font-family: "Bookman Old Style", "serif";
// }
// .top h2 {
//     font-weight: 900;
//     margin-bottom: 5px;
//     font-family: Arial;
//     font-size: 11px;
//     font-style: italic;
//     float: left;
//     margin-top: -4px;
// }
// .middle {
//     font-family: "Times New Roman";
//     float: left;
//     width: 8.4cm;
//     height: 3.8cm;
//     // background-image: url("background-transparent.png");
//     background-size: 125px;
//     background-repeat: no-repeat;
//     background-position: center;
//     position: relative;
// }
// .middle .content{

//     font-family: "Times New Roman";
//     width: 8.2cm;
//     float: left;
//     padding: 4px;
// }
// .middle .content ul{
//     font-family: "Times New Roman";
//     padding: 2px;
//     margin: 5px;
//     list-style: none;
// }
// .middle .content ul{
//     padding: 2.5px;
//     list-style: none;
//     font-family: arial,verdana,sans-serif;
//     font-size: 8px;
//     width: 8cm;
// }
// .middle .product-image{
//     font-family: "Times New Roman";
//     width: 2.5cm;
//     height: 1.65cm;
//     position: absolute;
//     top: 1px;
//     right: 0px;
//     float: right;
//     text-align: center;
//     display: inline-block;
// }
// .bottom{
//     clear: both;
//     /* height: 0.2cm; */
//     border-top: 2px solid rgba(0, 0, 0, 1);
//     text-align: center;
// }
// .frontside span.last{
//     text-align: center;
//     word-spacing: normal;
//     font-weight: 700;
//     font-size: 9px;
//     vertical-align: middle;
//     font-family: Sans-Serif;
//     color: #d80018;
// }
// }
// .backside{
//     width: 8.5cm;
//     height: 5.3cm;
//     border-radius: 15px;
//     margin: 0 auto;
//     // background-image: url("background-gradient.png");
//     padding: 6px 6px 6px 7px;
//     font-family: arial,verdana,sans-serif;
//     text-align: center;
//     h4{
//         font-family: arial,verdana,sans-serif;
//         text-align: center;
//         font-size: 10px;
//         margin: 10px 0px 1px 0px !important;
//     }
//     h2{
//         font-family: arial,verdana,sans-serif;
//         text-align: center;
//         color: #939393;
//         font-size: 10px;
//         margin: 10px 0px 13px 0px !important;
//       }
//       p{
//         font-family: arial,verdana,sans-serif;
//         text-align: justify;
//         padding: 3px;
//         font-size: 6.1px;
//         word-spacing: 2px;
//         margin: 8px 0px 1px 0px !important;
//       }
//       .line {
//         border: 1px solid rgba(0, 0, 0, 1);
//         margin: 7px 0px 3px 0px !important;
//     }
//     span.last{
//         font-family: arial,verdana,sans-serif;
//         text-align: center;
//         word-spacing: normal;
//         font-size: 6px;
//         vertical-align: top;
//     }
// }
.cardMain {
	margin-top: 5px;
}
.certificaateDiv {
	margin: 4px 0px;
	@media screen and (min-width: 1000px) {
		&.appraCertificaateDiv {
			justify-content: center;
		}
	}
	.CardSizeBorder {
		position: relative;
		border-bottom: 1px Solid black;
		border-top: 1px Solid black;
		width: 9cm !important;
	}
	.BlackText {
		color: #000;
	}
	.ColHeadings {
		font-weight: 700;
	}
	.dynamic-data {
		font-weight: 400;
		text-transform: none !important;
	}
	.report-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.frontside,
	.backside {
		margin: 0px auto;
		width: 11.1cm !important;
		height: 7cm !important;
		/* border: 1px solid #5F5E5E; */
		border-radius: 15px;
		margin: 0 auto;
		padding: 6px 6px 6px 7px;
		background-image: url(./../../Images/certificate-gradient.png);
	}

	.content {
		width: 10.5cm;
	}
	.smallCard {
		ul {
			li{
				line-height: 16px !important;
			}
		}
	}
	.top {
		display: flex;
		align-items: center;
		border-bottom: 2px solid rgba(0, 0, 0, 1);
		position: relative;
		.logo {
			float: left;
			width: 20%;
			margin: 0px;
			margin-left: 10px;
			margin-right: -15px;
			height: 100%;
			img {
				width: 64%;
				height: 100%;
				overflow: visible;
				z-index: 99999;
			}
		}
		h1 {
			font-size: 20px;
			margin-top: 1px;
			font-family: "Bookman Old Style", "serif";
			margin-bottom: 1px;
			font-weight: 900;
		}
		h2 {
			font-family: Arial;
			font-size: 13px;
			font-style: italic;
			float: left;
			margin-top: -4px;
			font-weight: 900;
			margin-bottom: 0px;
		}
		.date {
			float: right;
			font-size: 10px;
			margin-right: 2px;
			position: absolute;
			right: 0px;
			font-weight: 900;
			color: #000;
		}
	}
	.middle {
		position: relative;
		display: flex;
		justify-content: center;
		padding: 5px 0px;
		position: relative;
		// background-image: url(./../../Images/background-transparent.png);
		background-size: 150px;
		background-repeat: no-repeat;
		background-position: center;
		height: 5cm;
		.content {
			ul {
				padding-left: 0px;
				margin: 0px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-bottom: 20px;
				li {
					padding: 2.8px;
					list-style: none;
					font-family: arial, verdana, sans-serif;
					font-size: 11px;
					width: 10.5cm;
					line-height: 11px;
				}
			}
		}
		.product-image {
			width: 3.3cm;
			height: 3.5cm;
			position: absolute;
			top: 4px;
			right: 0px;
			float: right;
			text-align: center;
			display: inline-block;
			overflow: hidden;
			img {
				width: 2.85cm;
				height: 2cm;
			}
			.verticaltext {
				font-size: 6px;
				margin-top: -15px;
				left: 33px;
				transform: rotate(270deg);
				position: absolute;
				top: 40px;
				right: -67px;
			}
		}
		.certStatement {
			position: absolute;
			font-size: 8px;
			bottom: 8px;
		}
	}
	.bottom {
		border-top: 2px solid rgba(0, 0, 0, 1);
		text-align: center;
		font-size: 9px;
		color: #d80018;
		font-weight: 700;
	}
	span.last {
		text-align: center;
		font-size: 9px;
		color: #d80018;
		font-weight: 700;
	}
	.backside {
		font-family: arial, verdana, sans-serif;
		text-align: center;
		h4 {
			font-size: 12px;
			margin: 10px 0px 1px 0px !important;
		}
		h2 {
			font-family: arial, verdana, sans-serif;
			text-align: center;
			color: #939393;
			font-size: 12px;
			margin: 0px 0px 0px 0px !important;
		}
		p {
			font-family: arial, verdana, sans-serif;
			text-align: justify;
			padding: 3px;
			font-size: 8.1px;
			word-spacing: 2px;
			margin: 0px 0px 1px 0px !important;
			line-height: 9.5px;
			padding-top: 17px;
		}
		.line {
			font-family: arial, verdana, sans-serif;
			text-align: center;
			border: 1px solid rgba(0, 0, 0, 1);
			margin: 7px 0px 3px 0px !important;
			position: absolute;
			width: 10.7cm;
			bottom: 20px;
		}
		.last {
			font-family: arial, verdana, sans-serif;
			text-align: center;
			word-spacing: normal;
			font-size: 8px;
			vertical-align: top;
			color: #000;
			position: absolute;
			bottom: 10px;
			left: 0;
			right: 0;
		}
	}
}
.saveChangeBtn {
	background: #1890ff;
	border: 1px solid #1890ff;
	outline: none;
	padding: 5px 10px;
	font-size: 13px;
	font-weight: 600;
	color: white;
}
.inputFlex {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	input {
		width: 50% !important;
	}
	label.ant-checkbox-group-item {
		width: 47%;
	}
}
.addFieldsBtn {
	background: #1890ff;
	border: 1px solid #1890ff;
	padding: 5px 12px;
	font-size: 13px;
	color: white;
	font-weight: 600;
	margin-left: 5px;
}
.certificateLoader {
	width: 100%;
	height: 100vh;
	z-index: 9999;
	background: white;
	position: absolute;
	.innerLoader {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		max-width: 400px;
		max-height: 400px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.ant-space-item {
			width: 100%;
			height: 100%;
			.ant-spin {
				width: 100%;
				height: 100%;
				.ant-spin-dot {
					width: 100%;
					height: 100%;
				}
			}
			.ant-spin-lg .ant-spin-dot i {
				width: 24px;
				height: 24px;
			}
		}
	}
	.loadingText {
		margin-top: 40px;
		font-size: 28px;
		text-align: center;
		font-weight: 700;
		color: #1a91ff;
		animation: loadingAnime 2s infinite;
	}
	@keyframes loadingAnime {
		33% {
			opacity: 1;
		}
		66% {
			opacity: 0.3;
		}
		100% {
			opacity: 1;
		}
	}
}
.mcMesSelect {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum", "tnum";
	position: relative;
	display: inline-block;
	cursor: pointer;
	position: relative;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	height: 32px;
	outline: none;
	&:hover {
		border-color: #1890ff;
	}
}

@media screen and (min-width: 1200px) {
	#bigCertificate {
		justify-content: center !important;
	}
}
.appraContainer {
	@media screen and (max-width: 1000px) {
		min-width: 1140px !important;
	}
}




// <button
// className="printBtnCert"
// style={{
// 	backgroundColor: "#4f81bc",
// 	padding: "10px 20px",
// 	color: "#fff",
// 	fontFamily: "arial",
// 	float: "right",
// 	marginRight: "20px",
// 	textDecoration: "none",
// 	border: "0px solid #385d89"
// }}
// id="printbtn" 
// onClick
// >
// Print
// </button>



// <div className="isoImage" style={{
// 	right: "20px",
// 	width: "95px",
// 	position: "absolute",
// 	bottom: "27px",
// 	transform: "translateZ(-1px)",


// }}>
// 	<img src={qrUrl} style={{ width: "100%" }} />
// </div>