.spaceBetween {
	align-items: center;
	justify-content: space-between;
	display: flex;
}

.w75p {
	width: 75%;
}

.w65p {
	width: 65%;
}

.w125p {
	width: 25%;
}

.w134p {
	width: 34%;
}

.saveDelete {
	display: flex;
}

.newForm {
	font-size: 13px;
	background: #001529;
	color: white;
	padding: 0px 14px;
	margin: 10px 0px;
	border-radius: 2px;
}

.takeInItem {
	.selectCustomer {
		position: relative;

		.select,
		.ant-btn.ant-btn-primary {
			border-radius: 50px;
		}

		.ant-select-selector {
			width: 100%;
			height: 40px;
			padding: 0 10px;
			border-radius: 50px;

			.ant-select-selection-search-input {
				height: 100%;
			}

			.ant-select-selection-item {
				display: flex;
				align-items: center;
				// justify-content: center;
			}
		}
	}

	.addCustomer {
		border-radius: 50px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: 600;
		color: white;
		transition: 0.2s;
		background-color: #001529;
		border: 1px solid #001529;

		&:hover {
			background-color: #001529e1;
			color: white;
			border: 1px solid #001529e1;
		}
	}
}

.customerDetailSec {

	// margin-bottom: 10px;
	.infoButton {
		cursor: pointer;
		margin-right: 4px;

		&:hover {
			text-decoration: underline;
		}
	}
}

.customerDetail {
	.CustomerDetTable {
		width: 100%;

		th,
		td {
			font-size: 12px;
		}
	}

	.ant-collapse-arrow {
		right: 16px;
		left: unset !important;
	}

	.custInnerTable {
		width: 100%;
		border-collapse: collapse;

		tr {
			border-bottom: 1px solid #0000001f;

			&:last-child {
				border-bottom: 0px;
			}
		}

		th,
		td {
			padding: 10px 0px;

			font-size: 12px;

			// &:last-child{
			//     border-bottom: 0px;
			// }
		}
	}

	.ant-collapse-header {
		padding-left: 16px;
	}
	.detailMembership{
		display: flex;
		// align-items: center;
		div{
			margin-right: 10px;
		}
	}
}

.custDetModal {
	width: 70vw !important;
}

.expenseModal {
	width: 350px !important;

	.ant-badge {
		width: 100%;
	}

	.addedExpensesList {
		table {
			margin-bottom: 20px;
			border-collapse: collapse;
			width: 100%;

			tr {
				border-bottom: 1px solid gray;

				.sNo {
					width: 10%;
				}

				.name {
					width: 50%;
					padding-left: 10px;
				}

				.amount {
					width: 20%;
					padding-left: 10px;
				}

				.action {
					width: 20%;
					padding-left: 10px;
				}
			}
		}
	}
}

.customerModalButton {
	color: white;
	border: 1px solid #001529;
	background: #001529;
	margin-left: 10px;
	border-radius: 15px;

	&:hover,
	&:active,
	&:focus {
		background-color: #001529e1;
		border: 1px solid #001529e1;
	}
}

.addItemSec {
	margin-top: 10px;
	width: 100%;

	.notAligned {
		align-items: flex-start;
	}

	.addItemHead {
		text-align: center;
	}

	.entrieButtonDiv {
		display: flex;
		align-items: center;

		.customerModalButton {
			&:first-child {
				// margin-right: 5px;
			}
		}
	}

	.customerPortion {
		border: 1px solid #f7f7f7;
		margin-bottom: 10px;
		padding: 5px 5px;
		background: #f7f7f7;
		box-shadow: 0px 0px 3px -1px black;
		// border-radius: 10px;
		border-radius: 50px;
	}

	.formMainDiv {
		padding: 0px 10px;
	}

	.formDiv {
		padding: 10px;
		border: 1px solid #f7f7f7;
		margin-bottom: 10px;
		/* padding: 5px 5px; */
		background: #f7f7f7;
		box-shadow: 0px 0px 3px -1px black;
		border-radius: 10px;
	}

	.innerForm {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		.inputFields {
			width: 32%;
			font-size: 14px;

			.ant-picker {
				height: 35px;
			}

			&.checboxes {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.ant-input-number {
				width: 100%;
			}

			.select {
				width: 100%;
			}

			.ant-input-number,
			.select {
				height: 35px;

				.ant-select-selector {
					height: 100%;
				}
			}

			label {
				h5 {
					margin: 5px 0px 4px 0px;
				}
			}

			.checkboxGroup {
				width: 100%;
				justify-content: space-between;
				display: flex;
				align-items: center;
			}
		}
	}

	.itemDiv {
		border-left: 1px solid #0000001c;
		border-top: 1px solid #0000001c;
		position: relative;
		box-shadow: 0px 0px 5px -3px black;

		.spinnerItemDiv {
			position: absolute;
			top: 0;
			background: white;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
	}

	.addEnteryDiv {
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// margin: 0 auto;
		// margin-top: 20px;
		width: 100%;

		button {
			width: 100%;
			margin: 0px;
		}
	}
}

.itemsTable {

	th,
	td {
		font-size: 11px;
		padding: 6.9px !important;
	}

	.ant-pagination {
		display: none;
	}

	&.customerSummaryTable {
		.ant-pagination {
			display: block;

		}

		.customerIdCol {
			width: 270px;
		}

		.ant-table-column-sorter {
			position: absolute;
			right: 0px;
		}

		.ant-table-cell.ant-table-column-has-sorters {
			&:hover {
				.ant-table-filter-trigger-container {
					background-color: transparent;

					.ant-table-filter-trigger:hover {
						color: #bfbfbf;
					}
				}
			}
		}

		.ant-table-filter-trigger-container {
			top: auto;
			bottom: auto;
			right: 20px;
			display: unset !important;
			flex: unset;
			align-self: unset;

			&:hover {
				background-color: transparent;

				.ant-table-filter-trigger:hover {
					color: #bfbfbf;
				}
			}
		}

		.ant-table-filter-column {
			position: relative;
		}
	}

	// .ant-table-cell.ant-table-row-expand-icon-cell{
	//   display: none !important;
	// }
	// .ant-table-cell.ant-table-row-expand-icon-cell{
	//   display: none !important;
	// }
	// .ant-table-expanded-row.ant-table-expanded-row-level-1{
	//   display: contents !important;
	// }
}

.AddModal {
	widows: 60vw;

	.addItemForm {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.inputFields {
			width: 48%;

			&.checboxes {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.ant-input-number {
				width: 100%;
			}

			.select {
				width: 100%;
			}

			.ant-input-number,
			.select {
				height: 35px;
			}

			label {
				h5 {
					margin: 5px 0px 4px 0px;
				}
			}

			.checkboxGroup {
				width: 100%;
				justify-content: space-between;
				display: flex;
				align-items: center;
			}
		}

		.w100P {
			width: 100%;

			.checkboxGroup {
				width: 100%;
				justify-content: space-between;
				display: flex;
				align-items: center;
			}
		}
	}
}

.stoneFiedls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	.inputFields {
		width: 48%;

		&.checboxes {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.ant-input-number {
			width: 100%;
		}

		.select {
			width: 100%;
		}

		.ant-input-number,
		.select {
			height: 35px;
		}

		label {
			h5 {
				margin: 5px 0px 4px 0px;
			}
		}

		.checkboxGroup {
			width: 100%;
			justify-content: space-between;
			display: flex;
			align-items: center;
		}
	}
}

.w100P {
	width: 100% !important;
}

.w64p {
	width: 64% !important;
}

.addCustomerModelMain {
	display: flex;
	justify-content: space-between;

	.addCustomerInputMain {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 49%;

		input,
		textArea {
			margin-bottom: 10px;
		}

		.addMemBut {
			width: 100%;
			height: 40px;
			background-color: #001529;
			color: white;
			border-radius: 5px;
		}
		.addMemButdisabled {
			width: 100%;
			height: 40px;
			background-color: #9aa9b6;
			color: white;
			border-radius: 5px;
		}

	}

	.memTableDiv {
		width: 49%;
		padding: 5px;
		border: 1px solid #001529;
		border-radius: 5px;
		max-height: 400px;
		overflow: scroll;

		table {
			width: 100%;
			border-collapse: collapse;

			tr {
				border-bottom: 1px solid #001529;
				text-align: center;

				td {
					padding-top: 5px;
					padding-bottom: 5px;
				}

				i {
					color: #00152981;
					cursor: pointer;
					margin-right: 10px;

					&:hover {
						color: #001529;
					}
				}
			}
		}
	}
}

.addMemMain {
	.addMemInputMain {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.centerAlign {
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			&.two {
				.span {
					color: red;
					font-size: 12px;
					font-weight: 600;
				}
			}

			label {
				color: #001529;
				font-size: 12px;
				font-weight: 600;
			}
		}

		input {
			margin-bottom: 10px;
		}

		.ant-picker {
			width: 100%;
			height: 42px;
			margin-bottom: 10px;

			input {
				margin-bottom: 0;
			}
		}

		.ant-select-selector,
		.ant-select {
			width: 100%;
			height: 42px;
			margin-bottom: 10px;

			.ant-select-selection-item {
				display: flex;
				align-items: center;
			}
		}
	}
}

.filteredDropDown {
	position: absolute;
	top: 42px;
	width: 100%;
	z-index: 999;
	background-color: #f5f5f5;
	box-shadow: 1px 1px 8px 0px #00000080;
	border: 1px solid rgba(128, 128, 128, 0.151);
	max-height: 200px;
	overflow-y: scroll;
	border-radius: 5px;

	div {
		padding: 5px 15px;
		border-bottom: 1px solid rgba(128, 128, 128, 0.151);
		cursor: pointer;

		&:hover {
			background-color: rgba(128, 128, 128, 0.329);
		}
	}
}

.cashRecieveModalMain {
	width: 100% !important;
	max-width: 960px;

	.cashRecieveModal {

		// padding: 0 24px 24px;
		#main {
			width: 100%;
			/* height: 100vh; */
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: space-between;
			justify-content: space-between;
			margin-top: 5%;
		}

		#mainDiv {
			width: 49%;
			height: 100%;
			// margin-left: 20px;
		}

		#mainDiv2 {
			width: 49%;
		}

		#heading {
			width: 100%;
			text-align: center;
		}

		#headDiv {
			width: 100%;
			display: inline-block;
			font-size: 0.9em;
		}

		#table1 {
			float: left;
			width: 60%;
		}

		#table2 {
			float: right;
			width: 39%;
		}

		#table1 .bold {
			font-size: 0.8em;
		}

		.bold {
			font-weight: bold;
		}

		.text1 {
			padding-left: 5px;
		}

		#center {
			text-align: right;
		}

		#div2 {
			width: 100%;
			/* height: 50vh; */
		}

		#mainTable {
			border-collapse: collapse;
		}

		#mainTable {
			border: 2px solid black;
			width: 100%;
			font-size: 0.84em;
		}

		.color {
			background: rgba(0, 0, 0, 0.3);
			-webkit-print-color-adjust: exact;
		}

		.tr {
			border-right: 1px solid black;
		}

		#div3 {
			width: 100%;
			height: 15vh;
			display: -webkit-flex;
			display: flex;
			margin-top: 15px;
		}

		#divP {
			width: 50%;
			-webkit-flex: 1 1;
			flex: 1 1;
			/* float: left; */
			font-size: 0.7em;
			font-style: italic;
		}

		#tableDiv {
			width: 50%;
			-webkit-flex: 1 1;
			flex: 1 1;
			/* text-align: right; */
			/* float: right; */
		}

		#bottomTable {
			border-collapse: collapse;
		}

		#bottomTable {
			width: 78%;
			float: right;
			font-size: 0.8em;
		}

		.bold th {
			text-align: left;
		}

		.bold td {
			text-align: right;
		}

		.textI {
			font-style: italic;
		}

		#sign {
			width: 50%;
			text-align: center;
		}

		#sign h3 {
			display: inline-block;
			border-top: 2px solid black;
			width: 70%;
			text-align: center;
		}

		#heading {
			width: 100%;
			text-align: center;
		}

		#headDiv {
			width: 100%;
			display: inline-block;
			font-size: 0.9em;
		}

		#table1 {
			float: left;
			width: 60%;
		}

		#table2 {
			float: right;
			width: 39%;
		}

		#mainTable1 {
			border-collapse: collapse;
		}

		#bottomTable {
			border-collapse: collapse;
		}

		#bottomTable {
			width: 78%;
			float: right;
			font-size: 0.8em;
		}

		.textI {
			font-style: italic;
		}

		#sign {
			width: 50%;
			text-align: center;
		}

		#sign h3 {
			display: inline-block;
			border-top: 2px solid black;
			width: 70%;
			text-align: center;
		}

		.mainTable th,
		.mainTable td {
			text-align: center;
		}

		#mainTable1 {
			border: 2px solid black;
			font-size: 0.84em;
			// width: 100%;
		}
	}
}

.draftTabs {
	position: fixed;
	bottom: 0;
	right: 0;

	.ant-row {
		flex-direction: row;
	}

	.dTab {
		border: 1px solid #001529;
		/* width: 50px; */
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px 20px;
		/* border-radius: 8px; */
		border-top-left-radius: 10px;
		border-bottom: 0px;
		// border-left: 0px;
		font-size: 21px;
		position: relative;

		&.active {
			background: #001529;

			svg,
			.tabNumber {
				color: white !important;
			}
		}

		&:first-child {
			border-top-right-radius: 10px;
		}

		&:not(:first-child) {
			border-top-right-radius: 10px;
		}

		.tabNumber {
			font-size: 12px;
			font-size: 12px;
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 0px 3px;
		}
	}
}

.DeleteDraft {
	background-color: red !important;
}

.saveButton {
	width: 32%;
	margin-top: 20px;
	margin: 20px 0px 0px auto;
	display: flex;
	justify-content: space-between;

	button {
		background: #001529;
		color: white;
		font-weight: 700;
		padding: 21px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 17px;
		border: none;

	}
}

.highlight {
	border-radius: 50px;
	// border: 1px solid red;
	animation: buttonAnimation 1.5s infinite;
}

@keyframes buttonAnimation {
	0% {
		// border: 1px solid red;
		box-shadow: 0px 0px 0px -1px black;
	}

	50% {
		box-shadow: 0px 0px 15px -1px black;
	}

	100% {
		// border: 3px solid red;
		box-shadow: 0px 0px 0px -1px black;
	}
}

.loading1 {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 4px solid;
	border-color: white rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1);
	animation: spin 0.6s ease infinite;
	margin: 0px 12px;

	&.reportLoder {
		width: 15px;
		height: 15px;
	}

	&.notiLoader {
		border-color: rgb(0, 0, 0) rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}

.takeinAddDeleteButton {
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		height: 40px;
		margin-right: 10px;
		border-radius: 50px;
	}
}

.AddModal {
	.cancelButton {
		background: rgb(245, 0, 87);
		color: white;
		border-color: rgb(245, 0, 87);
	}

	.submitButton {
		background: rgb(0, 21, 41);
		color: white;
		border-color: rgb(0, 21, 41);
	}

	
}