.recieptReports {
    table {
        th {
            font-weight: bold;
        }
        td,
        th {
            font-size: 12px;
            text-align: center;
            svg {
                height: 15px;
                width: 15px;
            }
            &:last-child{
                .ant-table-column-sorter-inner{
                        margin-right: 10px;
                }
            }
        }
        td:hover {
            background-color: rgba(128, 128, 128, 0.24) !important;
        }
    }
    .balanceButton {
        width: 100%;
        color: white;
        border: none;
    }
    .balanceTd {
        padding: 0px !important;
    }
}
.CustomerSumModal {
    max-width: 450px;
    width: 100% !important;
    table {
        width: 100%;
    }
}
.CustomerBalanceModal {
    max-width: 1060px;
    width: 100%!important;
    .footertable {
        th {
            font-weight: bold;
        }
        td,
        th {
            text-align: center;
        }
    }
    table {
        width: 100%;
    }
}
.itemReportModal {
    max-width: 700px !important;
    width: auto !important;
    //     background: black;
    .modalBodyItem {
        .detail {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            .entryDetail {
                h3 {
                    font-size: 20px;
                    font-weight: bold;
                }
                td {
                    font-size: 12px;
                }
                th {
                    padding-right: 10px;
                    font-size: 12px;
                }
            }
            .statusDetail {
                h3 {
                    color: green;
                    margin: 0px;
                    font-weight: bold;
                }
                p {
                    margin: 0px;
                    font-size: 12px;
                }
            }
        }
        .customerDetail,
        .itemsDetail {
            h3 {
                margin-top: 20px;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                td {
                    border-top: 1px solid black;
                    border-bottom: 1px solid black;
                }
            }
        }
        .totalSection {
            display: flex;
            margin-top: 20px;
            justify-content: space-between;

            h6 {
                text-align: center;
                font-style: 12px;
            }
            div {
                width: 40%;
                table {
                    width: 100%;
                }
            }
        }
    }
}
.DirectCashItem {
    .actions {
        .printActions {
            font-size: 15px;
        }
    }
}
.ServiceReport {
    .printActions {
        font-size: 15px;
    }
}

.expenseSearch{
    margin-top: 10px;
}
.ExpenseReport {
    .expensesBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        button {
            border: none;
            font-size: 12px;
            font-weight: bold;
            padding: 5px 11px;
            box-shadow: 0px 0px 9px -1px grey;
            border-radius: 50px;
            background-color: #001529;
            color: #fff;
            &:first-child {
                margin-right: 5px;
            }
            &.active {
                color: #fff;
                background-color: #f50057;
            }
        }
    }
}

.RecievableModal {
    max-width: 85% !important;
    width: 100% !important;
    .itemsTable {
        width: 100% !important;
        margin: 10px 0px;
        thead {
            background: #fafafa;
        }
        th,
        td {
            border-bottom: 1px solid #f0f0f0;
        }
        tr:hover {
            background: #fafafa;
        }
        .bordertop {
            border-top: 1px solid black;
        }
    }
}
.textCenter {
    text-align: center;
}
.modalReportNotiLoader {
    position: unset;
    img {
        margin: 20px;
        width: 70px;
    }

}
.deleteAllEntryButton{
    width: 100%;
    background: #1890ff;
    color: white;
    border: none;
    padding: 5px 0px;
    font-weight: 100;
}

    .filterRow{
        row-gap: 0px;
        /* padding: 10px; */
        /* border: 1px solid #ad3a3a; */
        /* background: #ad3a3a; */
        box-shadow: 0px 0px 7px -3px black;
        padding: 4px 5px;
        border-radius: 10px;
        .filterText{
            margin-right: 10px;
        }
    }

