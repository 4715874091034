@media print {
	@page {
		width: 980px !important;
		max-width: 1000px !important;
		margin: 10px 50px !important;
	}
	body {
		margin: 1.6cm;
		width: 980px;
		max-width: 1000px;
		margin: 5px;
	}
}

.certificateDiv {
	.innerCertificate {
		.results {
			width: 50%;
			.logoDiv {
				text-align: center;
				img {
					margin-right: 81px;
					width: 188px;
				}
			}
		}
		.details {
			width: 50%;
		}
	}
}

.tablerow_height {
	height: 40px;
	&.td_heading {
		height: 25px;
	}
}
