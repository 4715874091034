.expButton {
  padding: 0px;
}
.alreadyDelivered {
  color: rgb(245, 0, 87);
  text-align: center;
  margin-top: 50px;
  width: 100%;
  font-weight: bold;
}

.highlightRed{
    border: 1px solid red;
    color: red;
  }
