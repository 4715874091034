.CustomerReport {
  .itemsTable {
    margin-top: 20px;
  }
}
.ItemReport {
  .w100P {
    padding-right: 5px;
  }
  .itemsTable {
    margin-top: 20px;
  }
}
.summaryReport {
  .fiveYearsReport {
    padding-top: 20px;
    h4 {
      text-align: center;
    }
  }
  .table {
    .itemsTable {
      border: 1px solid #0000001c;
    }
  }
  .inputFields {
    width: 100%;
    .ant-space-vertical {
      width: 100%;
      .ant-space-item {
        width: 100%;
        .ant-picker,
        .ant-select {
          width: 100%;
        }
      }
    }
  }
  .reportsSec {
    margin: 25px;
    .centerAlign {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .reportsDiv {
      .modalButton {
        width: 100%;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid #00000024;
        .iconDiv {
          svg {
            font-size: 70px;
            color: #d10001;
          }
          min-height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          font-size: 13px;
          font-weight: bold;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #001529;
        }
      }
    }
  }
  .totalTable {
    width: 100%;
  }
}
.summarDetailDiv {
  .expensetd {
    // &>:hover{
       
    // }
    img {
        opacity: 0;
      height: 20px;
      margin-left: 10px;
      width: 20px;
      padding: 0px;
    }
  }
  h1 {
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }
  td,
  th {
    text-align: center;
    border: 1px solid;
  }
  tr:hover {
    background-color: #fde1e1;
    img{
        opacity: 1;
    }
  }
  table {
    border-radius: 20px;
  }
}
.summarDetail {
  width: 100%;
  td {
    text-align: center;
  }
  tr:hover {
    background-color: #fde1e1;
    img{
        opacity: 1;
    }
  }
  img {
    opacity: 0;
  height: 18px;
  margin-left: 10px;
  width: 18px;
  padding: 0px;
  cursor: pointer;
}
}
.reportModal {
  width: 70vw !important;
  .expenseTotal {
    display: flex;
    justify-content: space-between;
    p {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
.UserList {
  .inputDiv {
    h6 {
      margin: 0px;
      font-size: 10px;
    }
  }
  .filter {
    .ant-select {
      width: 100%;
    }
  }
  .itemsTable {
    // border: 1px solid #0000001c;
  }
  .userTableList {
    margin-top: 30px;
  }
  .userTableButton {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
    width: max-content;
    svg {
      margin-right: 10px;
    }
    button {
      margin-left: 5px;
    }
  }
}
.AddAccount {
  .addHead {
    text-align: center;
    margin-bottom: 20px;
  }
  .inputFields {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ant-select,
    .ant-input,
    .ant-input-number {
      width: 270px;
    }
  }
  .addAccBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      background: #d40000;
      color: white;
      padding: 5px 70px;
      text-align: center;
      font-weight: bold;
      border: none;
      cursor: pointer;
    }
  }
}
.centerAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemsTable {
  width: 100%;
  th {
    background: #ad3a3a !important;
    color: white !important;
    border: 1px solid white;
  }
  td {
    background-color: #fde1e1 !important;
    font-size: 11px;
    padding: 6.9px !important;
    .yearDiv {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        background: #ad3a3a !important;
        color: white !important;
        border: 1px solid #ad3a3a;
      }
    }
  }
}

.snippet {
  width: 100%;
  // padding: 0px 40px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ad3a3a;
  color: #ad3a3a;
  box-shadow: 9999px 0 0 -5px #ad3a3a;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ad3a3a;
  color: #ad3a3a;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #ad3a3a;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #ad3a3a;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #ad3a3a;
  }
  30% {
    box-shadow: 9984px 0 0 2px #ad3a3a;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #ad3a3a;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #ad3a3a;
  }
  30% {
    box-shadow: 9999px 0 0 2px #ad3a3a;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #ad3a3a;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #ad3a3a;
  }
  30% {
    box-shadow: 10014px 0 0 2px #ad3a3a;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #ad3a3a;
  }
}

.montSumaryModal {
  max-width: 600px !important;
  width: 100% !important;
}
