.layoutHeader {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .layoutHeader .pageNameDiv {
    display: flex;
    align-items: center; }
    .layoutHeader .pageNameDiv h3 {
      margin: 0px; }
  .layoutHeader .avatarAvatar {
    display: flex;
    align-items: center;
    padding: 0px 30px; }
    .layoutHeader .avatarAvatar h5 {
      margin: 0px;
      padding: 0px 10px; }
  .layoutHeader .stoneRemain {
    background-color: black;
    opacity: 0.7;
    color: white;
    width: 40%;
    text-align: center;
    border-radius: 15px;
    height: 40px;
    z-index: 2;
    overflow: hidden;
    transition: 0.3s;
    position: relative;
    top: -22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 15px -3px black; }
    .layoutHeader .stoneRemain svg {
      margin-top: 10px;
      font-size: 20px; }
    .layoutHeader .stoneRemain .countDiv {
      display: none;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
    .layoutHeader .stoneRemain.stoneRemainOpen {
      height: 200px;
      display: flex;
      top: 18px;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column; }
      .layoutHeader .stoneRemain.stoneRemainOpen svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
      .layoutHeader .stoneRemain.stoneRemainOpen .countDiv {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin-top: 40px; }
        .layoutHeader .stoneRemain.stoneRemainOpen .countDiv h5 {
          color: white;
          line-height: 22px;
          font-size: 20px;
          font-weight: bold; }
        .layoutHeader .stoneRemain.stoneRemainOpen .countDiv p {
          color: #03fb03;
          line-height: 20px;
          font-size: 18px;
          font-weight: bold; }
  .layoutHeader .notificationsDiv {
    margin: 0px 10px;
    position: relative; }
    .layoutHeader .notificationsDiv .notiBellButn {
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none; }
    .layoutHeader .notificationsDiv .notifications {
      position: absolute;
      right: -4px;
      background: white;
      z-index: 1;
      box-shadow: 0px 4px 12px -5px black;
      line-height: 23px;
      width: 70vw;
      max-height: 70vh;
      overflow: auto;
      z-index: 90;
      /* Track */
      /* Handle */
      /* Handle on hover */ }
      .layoutHeader .notificationsDiv .notifications::-webkit-scrollbar {
        width: 5px; }
      .layoutHeader .notificationsDiv .notifications::-webkit-scrollbar-track {
        background: #f1f1f1; }
      .layoutHeader .notificationsDiv .notifications::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px; }
      .layoutHeader .notificationsDiv .notifications::-webkit-scrollbar-thumb:hover {
        background: #555;
        border-radius: 5px; }
      .layoutHeader .notificationsDiv .notifications .innerNoti {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center; }
        .layoutHeader .notificationsDiv .notifications .innerNoti .notiheadDiv {
          z-index: 9999;
          width: 100%; }
      .layoutHeader .notificationsDiv .notifications .TabsNoti {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px 15px; }
        .layoutHeader .notificationsDiv .notifications .TabsNoti .tabBadge {
          width: 23%; }
          .layoutHeader .notificationsDiv .notifications .TabsNoti .tabBadge .ant-scroll-number {
            background: #e66a6a; }
        .layoutHeader .notificationsDiv .notifications .TabsNoti .tab {
          width: 100%;
          text-align: center;
          border: 1px solid black;
          padding: 7px;
          border-radius: 10px;
          color: black;
          font-weight: bold;
          cursor: pointer;
          transition: 0.2s;
          padding: 10px 0px; }
          .layoutHeader .notificationsDiv .notifications .TabsNoti .tab:hover {
            background: #e66a6a;
            color: white;
            border: 1px solid #e66a6a;
            box-shadow: 0px 0px 4px -1px black; }
      .layoutHeader .notificationsDiv .notifications .notHead {
        font-weight: 600;
        padding: 10px 15px;
        background: white;
        box-shadow: 0px 1px 6px -2px grey;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        width: 100%; }
      .layoutHeader .notificationsDiv .notifications .notiDiv {
        transition: 0.2s;
        padding: 10px 15px;
        width: 49%; }
        .layoutHeader .notificationsDiv .notifications .notiDiv:hover {
          background-color: #00000008; }
        .layoutHeader .notificationsDiv .notifications .notiDiv .NotiInfo {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .layoutHeader .notificationsDiv .notifications .notiDiv .NotiInfo .notiName h5 {
            font-weight: bold;
            padding: 0px;
            color: #d40000; }
          .layoutHeader .notificationsDiv .notifications .notiDiv .NotiInfo .notiDate p {
            font-size: 12px;
            font-weight: bold;
            color: #999999; }
          .layoutHeader .notificationsDiv .notifications .notiDiv .NotiInfo .notiDate h6 {
            font-weight: bold;
            color: #1890ff;
            text-align: right; }
        .layoutHeader .notificationsDiv .notifications .notiDiv h6,
        .layoutHeader .notificationsDiv .notifications .notiDiv p,
        .layoutHeader .notificationsDiv .notifications .notiDiv h5 {
          margin: 0px; }
        .layoutHeader .notificationsDiv .notifications .notiDiv .innerNotiDetail {
          font-size: 12px;
          color: #999999; }
          .layoutHeader .notificationsDiv .notifications .notiDiv .innerNotiDetail p {
            display: flex;
            align-items: center;
            justify-content: space-between; }
            .layoutHeader .notificationsDiv .notifications .notiDiv .innerNotiDetail p span {
              font-weight: 700;
              color: black; }
        .layoutHeader .notificationsDiv .notifications .notiDiv .notiButtons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0px 5px; }
          .layoutHeader .notificationsDiv .notifications .notiDiv .notiButtons button {
            width: 45%;
            text-align: center;
            border: 1px solid black;
            padding: 7px;
            border-radius: 10px;
            color: black;
            font-weight: bold;
            cursor: pointer;
            transition: 0.2s;
            background: white; }
            .layoutHeader .notificationsDiv .notifications .notiDiv .notiButtons button.loadButton {
              background: #e66a6a;
              color: white;
              border: 1px solid #e66a6a;
              box-shadow: 0px 0px 4px -1px black;
              display: flex;
              align-items: center;
              justify-content: center; }
            .layoutHeader .notificationsDiv .notifications .notiDiv .notiButtons button:hover {
              background: #e66a6a;
              color: white;
              border: 1px solid #e66a6a;
              box-shadow: 0px 0px 4px -1px black; }
      .layoutHeader .notificationsDiv .notifications hr {
        border: 0.1px solid #00000012;
        margin: 0px; }
      .layoutHeader .notificationsDiv .notifications .loaderMore {
        border: 1px solid #001529;
        background: #001529;
        color: white;
        /* display: flex; */
        width: 20%;
        padding: 7px 10px;
        text-align: center;
        font-weight: bold;
        margin: 15px auto 20px auto;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.3s; }
        .layoutHeader .notificationsDiv .notifications .loaderMore:hover {
          box-shadow: 0px 0px 11px -4px black;
          -webkit-transform: scale(1.02);
                  transform: scale(1.02); }

.siderLay {
  height: 100vh;
  overflow-y: auto;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .siderLay .ant-menu-item-selected {
    background-color: #1890ff !important; }
    .siderLay .ant-menu-item-selected svg {
      color: #fff !important; }
  .siderLay::-webkit-scrollbar {
    width: 2px; }
  .siderLay::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .siderLay::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px; }
  .siderLay ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 50px; }

.ant-menu-submenu {
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .ant-menu-submenu::-webkit-scrollbar {
    width: 2px; }
  .ant-menu-submenu::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .ant-menu-submenu::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px; }
  .ant-menu-submenu ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 50px; }

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-submenu
> .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center; }

.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-dark.ant-menu-inline .ant-menu-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title img,
  .ant-menu-dark.ant-menu-inline .ant-menu-item img {
    margin-right: 12px; }

.layoutIcon {
  width: 21px; }
  .layoutIcon.setting {
    margin-right: 5px; }

.dashbaordIcon {
  width: 22px;
  fill: #a6adb4; }

.imageBox {
  display: flex;
  flex-direction: column; }
  .imageBox .box {
    height: 120px;
    width: 120px;
    border: 1px solid #999999;
    margin-bottom: 10px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    padding: 10px; }
  .imageBox input {
    height: 35px;
    font-size: 12px; }

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #00000066 !important;
  position: relative; }

.notificationloader {
  margin: 20px 0px;
  position: unset !important; }
  .notificationloader img {
    width: 70px !important; }

.NoDataText {
  font-weight: bold;
  color: #00000036; }

.branchSelectStone {
  color: white !important; }
  .branchSelectStone .ant-select-selector {
    background-color: rgba(0, 0, 0, 0.7) !important; }

.ant-layout-sider {
  box-shadow: black 4px 0px 9px -5px; }

.ant-layout-header {
  box-shadow: 0px 1px 6px -3px black; }

.ant-menu-title-content a {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0; }

.tobeHide {
  position: unset !important; }

.tobehonest {
  position: relative; }

.site-layout .site-layout-background.ant-layout-content {
  box-shadow: 0px 0px 10px -6px black;
  border-radius: 20px;
  padding: 14px !important; }

.ant-table-content th.ant-table-cell {
  background: #ad3a3a !important;
  color: white !important;
  border: 1px solid white; }

.ant-table-content .ant-table-tbody tr:hover td {
  background-color: #dfc1c1 !important; }

.ant-table-content .ant-table-row tr:hover,
.ant-table-content .ant-table-tbody tr:hover,
.ant-table-content .ant-table-tbody td:hover {
  background-color: #dfc1c1 !important; }
  .ant-table-content .ant-table-row tr:hover td,
  .ant-table-content .ant-table-tbody tr:hover td,
  .ant-table-content .ant-table-tbody td:hover td {
    background-color: #dfc1c1 !important; }

.ant-table-content .ant-table-tbody > tr > td {
  background: #fde1e1; }

.ant-table-content .ant-table-expanded-row th {
  padding: 1px 10px !important;
  color: #ad3a3a; }

.ant-table-content .ant-table-expanded-row td {
  padding: 1px 10px !important; }

.ItemReport .w100P {
  border-radius: 50px; }
  .ItemReport .w100P .ant-select-selector {
    border-radius: 50px !important; }
  .ItemReport .w100P .ant-input-search {
    border-top-left-radius: 50px; }
    .ItemReport .w100P .ant-input-search input {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px; }
    .ItemReport .w100P .ant-input-search button {
      border-top-right-radius: 50px !important;
      border-bottom-right-radius: 50px !important; }

.tableDiv {
  box-shadow: 0px 0px 6px -2px black;
  margin-bottom: 10px; }

.spaceBetween {
  align-items: center;
  justify-content: space-between;
  display: flex; }

.w75p {
  width: 75%; }

.w65p {
  width: 65%; }

.w125p {
  width: 25%; }

.w134p {
  width: 34%; }

.saveDelete {
  display: flex; }

.newForm {
  font-size: 13px;
  background: #001529;
  color: white;
  padding: 0px 14px;
  margin: 10px 0px;
  border-radius: 2px; }

.takeInItem .selectCustomer {
  position: relative; }
  .takeInItem .selectCustomer .select,
  .takeInItem .selectCustomer .ant-btn.ant-btn-primary {
    border-radius: 50px; }
  .takeInItem .selectCustomer .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border-radius: 50px; }
    .takeInItem .selectCustomer .ant-select-selector .ant-select-selection-search-input {
      height: 100%; }
    .takeInItem .selectCustomer .ant-select-selector .ant-select-selection-item {
      display: flex;
      align-items: center; }

.takeInItem .addCustomer {
  border-radius: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  background-color: #001529;
  border: 1px solid #001529; }
  .takeInItem .addCustomer:hover {
    background-color: #001529e1;
    color: white;
    border: 1px solid #001529e1; }

.customerDetailSec .infoButton {
  cursor: pointer;
  margin-right: 4px; }
  .customerDetailSec .infoButton:hover {
    text-decoration: underline; }

.customerDetail .CustomerDetTable {
  width: 100%; }
  .customerDetail .CustomerDetTable th,
  .customerDetail .CustomerDetTable td {
    font-size: 12px; }

.customerDetail .ant-collapse-arrow {
  right: 16px;
  left: unset !important; }

.customerDetail .custInnerTable {
  width: 100%;
  border-collapse: collapse; }
  .customerDetail .custInnerTable tr {
    border-bottom: 1px solid #0000001f; }
    .customerDetail .custInnerTable tr:last-child {
      border-bottom: 0px; }
  .customerDetail .custInnerTable th,
  .customerDetail .custInnerTable td {
    padding: 10px 0px;
    font-size: 12px; }

.customerDetail .ant-collapse-header {
  padding-left: 16px; }

.customerDetail .detailMembership {
  display: flex; }
  .customerDetail .detailMembership div {
    margin-right: 10px; }

.custDetModal {
  width: 70vw !important; }

.expenseModal {
  width: 350px !important; }
  .expenseModal .ant-badge {
    width: 100%; }
  .expenseModal .addedExpensesList table {
    margin-bottom: 20px;
    border-collapse: collapse;
    width: 100%; }
    .expenseModal .addedExpensesList table tr {
      border-bottom: 1px solid gray; }
      .expenseModal .addedExpensesList table tr .sNo {
        width: 10%; }
      .expenseModal .addedExpensesList table tr .name {
        width: 50%;
        padding-left: 10px; }
      .expenseModal .addedExpensesList table tr .amount {
        width: 20%;
        padding-left: 10px; }
      .expenseModal .addedExpensesList table tr .action {
        width: 20%;
        padding-left: 10px; }

.customerModalButton {
  color: white;
  border: 1px solid #001529;
  background: #001529;
  margin-left: 10px;
  border-radius: 15px; }
  .customerModalButton:hover, .customerModalButton:active, .customerModalButton:focus {
    background-color: #001529e1;
    border: 1px solid #001529e1; }

.addItemSec {
  margin-top: 10px;
  width: 100%; }
  .addItemSec .notAligned {
    align-items: flex-start; }
  .addItemSec .addItemHead {
    text-align: center; }
  .addItemSec .entrieButtonDiv {
    display: flex;
    align-items: center; }
  .addItemSec .customerPortion {
    border: 1px solid #f7f7f7;
    margin-bottom: 10px;
    padding: 5px 5px;
    background: #f7f7f7;
    box-shadow: 0px 0px 3px -1px black;
    border-radius: 50px; }
  .addItemSec .formMainDiv {
    padding: 0px 10px; }
  .addItemSec .formDiv {
    padding: 10px;
    border: 1px solid #f7f7f7;
    margin-bottom: 10px;
    /* padding: 5px 5px; */
    background: #f7f7f7;
    box-shadow: 0px 0px 3px -1px black;
    border-radius: 10px; }
  .addItemSec .innerForm {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    .addItemSec .innerForm .inputFields {
      width: 32%;
      font-size: 14px; }
      .addItemSec .innerForm .inputFields .ant-picker {
        height: 35px; }
      .addItemSec .innerForm .inputFields.checboxes {
        display: flex;
        align-items: center;
        justify-content: space-between; }
      .addItemSec .innerForm .inputFields .ant-input-number {
        width: 100%; }
      .addItemSec .innerForm .inputFields .select {
        width: 100%; }
      .addItemSec .innerForm .inputFields .ant-input-number,
      .addItemSec .innerForm .inputFields .select {
        height: 35px; }
        .addItemSec .innerForm .inputFields .ant-input-number .ant-select-selector,
        .addItemSec .innerForm .inputFields .select .ant-select-selector {
          height: 100%; }
      .addItemSec .innerForm .inputFields label h5 {
        margin: 5px 0px 4px 0px; }
      .addItemSec .innerForm .inputFields .checkboxGroup {
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center; }
  .addItemSec .itemDiv {
    border-left: 1px solid #0000001c;
    border-top: 1px solid #0000001c;
    position: relative;
    box-shadow: 0px 0px 5px -3px black; }
    .addItemSec .itemDiv .spinnerItemDiv {
      position: absolute;
      top: 0;
      background: white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; }
  .addItemSec .addEnteryDiv {
    width: 100%; }
    .addItemSec .addEnteryDiv button {
      width: 100%;
      margin: 0px; }

.itemsTable th,
.itemsTable td {
  font-size: 11px;
  padding: 6.9px !important; }

.itemsTable .ant-pagination {
  display: none; }

.itemsTable.customerSummaryTable .ant-pagination {
  display: block; }

.itemsTable.customerSummaryTable .customerIdCol {
  width: 270px; }

.itemsTable.customerSummaryTable .ant-table-column-sorter {
  position: absolute;
  right: 0px; }

.itemsTable.customerSummaryTable .ant-table-cell.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background-color: transparent; }
  .itemsTable.customerSummaryTable .ant-table-cell.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container .ant-table-filter-trigger:hover {
    color: #bfbfbf; }

.itemsTable.customerSummaryTable .ant-table-filter-trigger-container {
  top: auto;
  bottom: auto;
  right: 20px;
  display: unset !important;
  flex: unset;
  align-self: unset; }
  .itemsTable.customerSummaryTable .ant-table-filter-trigger-container:hover {
    background-color: transparent; }
    .itemsTable.customerSummaryTable .ant-table-filter-trigger-container:hover .ant-table-filter-trigger:hover {
      color: #bfbfbf; }

.itemsTable.customerSummaryTable .ant-table-filter-column {
  position: relative; }

.AddModal {
  widows: 60vw; }
  .AddModal .addItemForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .AddModal .addItemForm .inputFields {
      width: 48%; }
      .AddModal .addItemForm .inputFields.checboxes {
        display: flex;
        align-items: center;
        justify-content: space-between; }
      .AddModal .addItemForm .inputFields .ant-input-number {
        width: 100%; }
      .AddModal .addItemForm .inputFields .select {
        width: 100%; }
      .AddModal .addItemForm .inputFields .ant-input-number,
      .AddModal .addItemForm .inputFields .select {
        height: 35px; }
      .AddModal .addItemForm .inputFields label h5 {
        margin: 5px 0px 4px 0px; }
      .AddModal .addItemForm .inputFields .checkboxGroup {
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center; }
    .AddModal .addItemForm .w100P {
      width: 100%; }
      .AddModal .addItemForm .w100P .checkboxGroup {
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center; }

.stoneFiedls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }
  .stoneFiedls .inputFields {
    width: 48%; }
    .stoneFiedls .inputFields.checboxes {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .stoneFiedls .inputFields .ant-input-number {
      width: 100%; }
    .stoneFiedls .inputFields .select {
      width: 100%; }
    .stoneFiedls .inputFields .ant-input-number,
    .stoneFiedls .inputFields .select {
      height: 35px; }
    .stoneFiedls .inputFields label h5 {
      margin: 5px 0px 4px 0px; }
    .stoneFiedls .inputFields .checkboxGroup {
      width: 100%;
      justify-content: space-between;
      display: flex;
      align-items: center; }

.w100P {
  width: 100% !important; }

.w64p {
  width: 64% !important; }

.addCustomerModelMain {
  display: flex;
  justify-content: space-between; }
  .addCustomerModelMain .addCustomerInputMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 49%; }
    .addCustomerModelMain .addCustomerInputMain input,
    .addCustomerModelMain .addCustomerInputMain textArea {
      margin-bottom: 10px; }
    .addCustomerModelMain .addCustomerInputMain .addMemBut {
      width: 100%;
      height: 40px;
      background-color: #001529;
      color: white;
      border-radius: 5px; }
    .addCustomerModelMain .addCustomerInputMain .addMemButdisabled {
      width: 100%;
      height: 40px;
      background-color: #9aa9b6;
      color: white;
      border-radius: 5px; }
  .addCustomerModelMain .memTableDiv {
    width: 49%;
    padding: 5px;
    border: 1px solid #001529;
    border-radius: 5px;
    max-height: 400px;
    overflow: scroll; }
    .addCustomerModelMain .memTableDiv table {
      width: 100%;
      border-collapse: collapse; }
      .addCustomerModelMain .memTableDiv table tr {
        border-bottom: 1px solid #001529;
        text-align: center; }
        .addCustomerModelMain .memTableDiv table tr td {
          padding-top: 5px;
          padding-bottom: 5px; }
        .addCustomerModelMain .memTableDiv table tr i {
          color: #00152981;
          cursor: pointer;
          margin-right: 10px; }
          .addCustomerModelMain .memTableDiv table tr i:hover {
            color: #001529; }

.addMemMain .addMemInputMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .addMemMain .addMemInputMain .centerAlign {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    .addMemMain .addMemInputMain .centerAlign.two .span {
      color: red;
      font-size: 12px;
      font-weight: 600; }
    .addMemMain .addMemInputMain .centerAlign label {
      color: #001529;
      font-size: 12px;
      font-weight: 600; }
  .addMemMain .addMemInputMain input {
    margin-bottom: 10px; }
  .addMemMain .addMemInputMain .ant-picker {
    width: 100%;
    height: 42px;
    margin-bottom: 10px; }
    .addMemMain .addMemInputMain .ant-picker input {
      margin-bottom: 0; }
  .addMemMain .addMemInputMain .ant-select-selector,
  .addMemMain .addMemInputMain .ant-select {
    width: 100%;
    height: 42px;
    margin-bottom: 10px; }
    .addMemMain .addMemInputMain .ant-select-selector .ant-select-selection-item,
    .addMemMain .addMemInputMain .ant-select .ant-select-selection-item {
      display: flex;
      align-items: center; }

.filteredDropDown {
  position: absolute;
  top: 42px;
  width: 100%;
  z-index: 999;
  background-color: #f5f5f5;
  box-shadow: 1px 1px 8px 0px #00000080;
  border: 1px solid rgba(128, 128, 128, 0.151);
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 5px; }
  .filteredDropDown div {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.151);
    cursor: pointer; }
    .filteredDropDown div:hover {
      background-color: rgba(128, 128, 128, 0.329); }

.cashRecieveModalMain {
  width: 100% !important;
  max-width: 960px; }
  .cashRecieveModalMain .cashRecieveModal #main {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: space-between;
    margin-top: 5%; }
  .cashRecieveModalMain .cashRecieveModal #mainDiv {
    width: 49%;
    height: 100%; }
  .cashRecieveModalMain .cashRecieveModal #mainDiv2 {
    width: 49%; }
  .cashRecieveModalMain .cashRecieveModal #heading {
    width: 100%;
    text-align: center; }
  .cashRecieveModalMain .cashRecieveModal #headDiv {
    width: 100%;
    display: inline-block;
    font-size: 0.9em; }
  .cashRecieveModalMain .cashRecieveModal #table1 {
    float: left;
    width: 60%; }
  .cashRecieveModalMain .cashRecieveModal #table2 {
    float: right;
    width: 39%; }
  .cashRecieveModalMain .cashRecieveModal #table1 .bold {
    font-size: 0.8em; }
  .cashRecieveModalMain .cashRecieveModal .bold {
    font-weight: bold; }
  .cashRecieveModalMain .cashRecieveModal .text1 {
    padding-left: 5px; }
  .cashRecieveModalMain .cashRecieveModal #center {
    text-align: right; }
  .cashRecieveModalMain .cashRecieveModal #div2 {
    width: 100%;
    /* height: 50vh; */ }
  .cashRecieveModalMain .cashRecieveModal #mainTable {
    border-collapse: collapse; }
  .cashRecieveModalMain .cashRecieveModal #mainTable {
    border: 2px solid black;
    width: 100%;
    font-size: 0.84em; }
  .cashRecieveModalMain .cashRecieveModal .color {
    background: rgba(0, 0, 0, 0.3);
    -webkit-print-color-adjust: exact; }
  .cashRecieveModalMain .cashRecieveModal .tr {
    border-right: 1px solid black; }
  .cashRecieveModalMain .cashRecieveModal #div3 {
    width: 100%;
    height: 15vh;
    display: flex;
    margin-top: 15px; }
  .cashRecieveModalMain .cashRecieveModal #divP {
    width: 50%;
    flex: 1 1;
    /* float: left; */
    font-size: 0.7em;
    font-style: italic; }
  .cashRecieveModalMain .cashRecieveModal #tableDiv {
    width: 50%;
    flex: 1 1;
    /* text-align: right; */
    /* float: right; */ }
  .cashRecieveModalMain .cashRecieveModal #bottomTable {
    border-collapse: collapse; }
  .cashRecieveModalMain .cashRecieveModal #bottomTable {
    width: 78%;
    float: right;
    font-size: 0.8em; }
  .cashRecieveModalMain .cashRecieveModal .bold th {
    text-align: left; }
  .cashRecieveModalMain .cashRecieveModal .bold td {
    text-align: right; }
  .cashRecieveModalMain .cashRecieveModal .textI {
    font-style: italic; }
  .cashRecieveModalMain .cashRecieveModal #sign {
    width: 50%;
    text-align: center; }
  .cashRecieveModalMain .cashRecieveModal #sign h3 {
    display: inline-block;
    border-top: 2px solid black;
    width: 70%;
    text-align: center; }
  .cashRecieveModalMain .cashRecieveModal #heading {
    width: 100%;
    text-align: center; }
  .cashRecieveModalMain .cashRecieveModal #headDiv {
    width: 100%;
    display: inline-block;
    font-size: 0.9em; }
  .cashRecieveModalMain .cashRecieveModal #table1 {
    float: left;
    width: 60%; }
  .cashRecieveModalMain .cashRecieveModal #table2 {
    float: right;
    width: 39%; }
  .cashRecieveModalMain .cashRecieveModal #mainTable1 {
    border-collapse: collapse; }
  .cashRecieveModalMain .cashRecieveModal #bottomTable {
    border-collapse: collapse; }
  .cashRecieveModalMain .cashRecieveModal #bottomTable {
    width: 78%;
    float: right;
    font-size: 0.8em; }
  .cashRecieveModalMain .cashRecieveModal .textI {
    font-style: italic; }
  .cashRecieveModalMain .cashRecieveModal #sign {
    width: 50%;
    text-align: center; }
  .cashRecieveModalMain .cashRecieveModal #sign h3 {
    display: inline-block;
    border-top: 2px solid black;
    width: 70%;
    text-align: center; }
  .cashRecieveModalMain .cashRecieveModal .mainTable th,
  .cashRecieveModalMain .cashRecieveModal .mainTable td {
    text-align: center; }
  .cashRecieveModalMain .cashRecieveModal #mainTable1 {
    border: 2px solid black;
    font-size: 0.84em; }

.draftTabs {
  position: fixed;
  bottom: 0;
  right: 0; }
  .draftTabs .ant-row {
    flex-direction: row; }
  .draftTabs .dTab {
    border: 1px solid #001529;
    /* width: 50px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 20px;
    /* border-radius: 8px; */
    border-top-left-radius: 10px;
    border-bottom: 0px;
    font-size: 21px;
    position: relative; }
    .draftTabs .dTab.active {
      background: #001529; }
      .draftTabs .dTab.active svg,
      .draftTabs .dTab.active .tabNumber {
        color: white !important; }
    .draftTabs .dTab:first-child {
      border-top-right-radius: 10px; }
    .draftTabs .dTab:not(:first-child) {
      border-top-right-radius: 10px; }
    .draftTabs .dTab .tabNumber {
      font-size: 12px;
      font-size: 12px;
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0px 3px; }

.DeleteDraft {
  background-color: red !important; }

.saveButton {
  width: 32%;
  margin-top: 20px;
  margin: 20px 0px 0px auto;
  display: flex;
  justify-content: space-between; }
  .saveButton button {
    background: #001529;
    color: white;
    font-weight: 700;
    padding: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    border: none; }

.highlight {
  border-radius: 50px;
  -webkit-animation: buttonAnimation 1.5s infinite;
          animation: buttonAnimation 1.5s infinite; }

@-webkit-keyframes buttonAnimation {
  0% {
    box-shadow: 0px 0px 0px -1px black; }
  50% {
    box-shadow: 0px 0px 15px -1px black; }
  100% {
    box-shadow: 0px 0px 0px -1px black; } }

@keyframes buttonAnimation {
  0% {
    box-shadow: 0px 0px 0px -1px black; }
  50% {
    box-shadow: 0px 0px 15px -1px black; }
  100% {
    box-shadow: 0px 0px 0px -1px black; } }

.loading1 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid;
  border-color: white rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1);
  -webkit-animation: spin 0.6s ease infinite;
          animation: spin 0.6s ease infinite;
  margin: 0px 12px; }
  .loading1.reportLoder {
    width: 15px;
    height: 15px; }
  .loading1.notiLoader {
    border-color: black rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1); }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

.takeinAddDeleteButton {
  display: flex;
  align-items: center;
  justify-content: center; }
  .takeinAddDeleteButton button {
    height: 40px;
    margin-right: 10px;
    border-radius: 50px; }

.AddModal .cancelButton {
  background: #f50057;
  color: white;
  border-color: #f50057; }

.AddModal .submitButton {
  background: #001529;
  color: white;
  border-color: #001529; }

.expButton {
  padding: 0px; }

.alreadyDelivered {
  color: #f50057;
  text-align: center;
  margin-top: 50px;
  width: 100%;
  font-weight: bold; }

.highlightRed {
  border: 1px solid red;
  color: red; }

.loaderDiv {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffffa1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto; }

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out; }

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.CustomerReport .itemsTable {
  margin-top: 20px; }

.ItemReport .w100P {
  padding-right: 5px; }

.ItemReport .itemsTable {
  margin-top: 20px; }

.summaryReport .fiveYearsReport {
  padding-top: 20px; }
  .summaryReport .fiveYearsReport h4 {
    text-align: center; }

.summaryReport .table .itemsTable {
  border: 1px solid #0000001c; }

.summaryReport .inputFields {
  width: 100%; }
  .summaryReport .inputFields .ant-space-vertical {
    width: 100%; }
    .summaryReport .inputFields .ant-space-vertical .ant-space-item {
      width: 100%; }
      .summaryReport .inputFields .ant-space-vertical .ant-space-item .ant-picker,
      .summaryReport .inputFields .ant-space-vertical .ant-space-item .ant-select {
        width: 100%; }

.summaryReport .reportsSec {
  margin: 25px; }
  .summaryReport .reportsSec .centerAlign {
    display: flex;
    align-items: center;
    justify-content: center; }
  .summaryReport .reportsSec .reportsDiv .modalButton {
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #00000024; }
    .summaryReport .reportsSec .reportsDiv .modalButton .iconDiv {
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .summaryReport .reportsSec .reportsDiv .modalButton .iconDiv svg {
        font-size: 70px;
        color: #d10001; }
    .summaryReport .reportsSec .reportsDiv .modalButton p {
      font-size: 13px;
      font-weight: bold;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #001529; }

.summaryReport .totalTable {
  width: 100%; }

.summarDetailDiv .expensetd img {
  opacity: 0;
  height: 20px;
  margin-left: 10px;
  width: 20px;
  padding: 0px; }

.summarDetailDiv h1 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px; }

.summarDetailDiv td,
.summarDetailDiv th {
  text-align: center;
  border: 1px solid; }

.summarDetailDiv tr:hover {
  background-color: #fde1e1; }
  .summarDetailDiv tr:hover img {
    opacity: 1; }

.summarDetailDiv table {
  border-radius: 20px; }

.summarDetail {
  width: 100%; }
  .summarDetail td {
    text-align: center; }
  .summarDetail tr:hover {
    background-color: #fde1e1; }
    .summarDetail tr:hover img {
      opacity: 1; }
  .summarDetail img {
    opacity: 0;
    height: 18px;
    margin-left: 10px;
    width: 18px;
    padding: 0px;
    cursor: pointer; }

.reportModal {
  width: 70vw !important; }
  .reportModal .expenseTotal {
    display: flex;
    justify-content: space-between; }
    .reportModal .expenseTotal p:first-child {
      margin-right: 10px; }

.UserList .inputDiv h6 {
  margin: 0px;
  font-size: 10px; }

.UserList .filter .ant-select {
  width: 100%; }

.UserList .userTableList {
  margin-top: 30px; }

.UserList .userTableButton {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  width: -webkit-max-content;
  width: max-content; }
  .UserList .userTableButton svg {
    margin-right: 10px; }
  .UserList .userTableButton button {
    margin-left: 5px; }

.AddAccount .addHead {
  text-align: center;
  margin-bottom: 20px; }

.AddAccount .inputFields {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .AddAccount .inputFields .ant-select,
  .AddAccount .inputFields .ant-input,
  .AddAccount .inputFields .ant-input-number {
    width: 270px; }

.AddAccount .addAccBtn {
  display: flex;
  align-items: center;
  justify-content: center; }
  .AddAccount .addAccBtn button {
    background: #d40000;
    color: white;
    padding: 5px 70px;
    text-align: center;
    font-weight: bold;
    border: none;
    cursor: pointer; }

.centerAlign {
  display: flex;
  align-items: center;
  justify-content: center; }

.itemsTable {
  width: 100%; }
  .itemsTable th {
    background: #ad3a3a !important;
    color: white !important;
    border: 1px solid white; }
  .itemsTable td {
    background-color: #fde1e1 !important;
    font-size: 11px;
    padding: 6.9px !important; }
    .itemsTable td .yearDiv {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .itemsTable td .yearDiv button {
        background: #ad3a3a !important;
        color: white !important;
        border: 1px solid #ad3a3a; }

.snippet {
  width: 100%; }

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ad3a3a;
  color: #ad3a3a;
  box-shadow: 9999px 0 0 -5px #ad3a3a;
  -webkit-animation: dotPulse 1.5s infinite linear;
          animation: dotPulse 1.5s infinite linear;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s; }

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ad3a3a;
  color: #ad3a3a; }

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #ad3a3a;
  -webkit-animation: dotPulseBefore 1.5s infinite linear;
          animation: dotPulseBefore 1.5s infinite linear;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #ad3a3a;
  -webkit-animation: dotPulseAfter 1.5s infinite linear;
          animation: dotPulseAfter 1.5s infinite linear;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

@-webkit-keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #ad3a3a; }
  30% {
    box-shadow: 9984px 0 0 2px #ad3a3a; }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #ad3a3a; } }

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #ad3a3a; }
  30% {
    box-shadow: 9984px 0 0 2px #ad3a3a; }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #ad3a3a; } }

@-webkit-keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #ad3a3a; }
  30% {
    box-shadow: 9999px 0 0 2px #ad3a3a; }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #ad3a3a; } }

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #ad3a3a; }
  30% {
    box-shadow: 9999px 0 0 2px #ad3a3a; }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #ad3a3a; } }

@-webkit-keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #ad3a3a; }
  30% {
    box-shadow: 10014px 0 0 2px #ad3a3a; }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #ad3a3a; } }

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #ad3a3a; }
  30% {
    box-shadow: 10014px 0 0 2px #ad3a3a; }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #ad3a3a; } }

.montSumaryModal {
  max-width: 600px !important;
  width: 100% !important; }

.recieptReports table th {
  font-weight: bold; }

.recieptReports table td,
.recieptReports table th {
  font-size: 12px;
  text-align: center; }
  .recieptReports table td svg,
  .recieptReports table th svg {
    height: 15px;
    width: 15px; }
  .recieptReports table td:last-child .ant-table-column-sorter-inner,
  .recieptReports table th:last-child .ant-table-column-sorter-inner {
    margin-right: 10px; }

.recieptReports table td:hover {
  background-color: rgba(128, 128, 128, 0.24) !important; }

.recieptReports .balanceButton {
  width: 100%;
  color: white;
  border: none; }

.recieptReports .balanceTd {
  padding: 0px !important; }

.CustomerSumModal {
  max-width: 450px;
  width: 100% !important; }
  .CustomerSumModal table {
    width: 100%; }

.CustomerBalanceModal {
  max-width: 1060px;
  width: 100% !important; }
  .CustomerBalanceModal .footertable th {
    font-weight: bold; }
  .CustomerBalanceModal .footertable td,
  .CustomerBalanceModal .footertable th {
    text-align: center; }
  .CustomerBalanceModal table {
    width: 100%; }

.itemReportModal {
  max-width: 700px !important;
  width: auto !important; }
  .itemReportModal .modalBodyItem .detail {
    display: flex;
    justify-content: space-between; }
    .itemReportModal .modalBodyItem .detail .entryDetail h3 {
      font-size: 20px;
      font-weight: bold; }
    .itemReportModal .modalBodyItem .detail .entryDetail td {
      font-size: 12px; }
    .itemReportModal .modalBodyItem .detail .entryDetail th {
      padding-right: 10px;
      font-size: 12px; }
    .itemReportModal .modalBodyItem .detail .statusDetail h3 {
      color: green;
      margin: 0px;
      font-weight: bold; }
    .itemReportModal .modalBodyItem .detail .statusDetail p {
      margin: 0px;
      font-size: 12px; }
  .itemReportModal .modalBodyItem .customerDetail h3,
  .itemReportModal .modalBodyItem .itemsDetail h3 {
    margin-top: 20px; }
  .itemReportModal .modalBodyItem .customerDetail table,
  .itemReportModal .modalBodyItem .itemsDetail table {
    width: 100%;
    border-collapse: collapse; }
    .itemReportModal .modalBodyItem .customerDetail table td,
    .itemReportModal .modalBodyItem .itemsDetail table td {
      border-top: 1px solid black;
      border-bottom: 1px solid black; }
  .itemReportModal .modalBodyItem .totalSection {
    display: flex;
    margin-top: 20px;
    justify-content: space-between; }
    .itemReportModal .modalBodyItem .totalSection h6 {
      text-align: center;
      font-style: 12px; }
    .itemReportModal .modalBodyItem .totalSection div {
      width: 40%; }
      .itemReportModal .modalBodyItem .totalSection div table {
        width: 100%; }

.DirectCashItem .actions .printActions {
  font-size: 15px; }

.ServiceReport .printActions {
  font-size: 15px; }

.expenseSearch {
  margin-top: 10px; }

.ExpenseReport .expensesBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px; }
  .ExpenseReport .expensesBtn button {
    border: none;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 11px;
    box-shadow: 0px 0px 9px -1px grey;
    border-radius: 50px;
    background-color: #001529;
    color: #fff; }
    .ExpenseReport .expensesBtn button:first-child {
      margin-right: 5px; }
    .ExpenseReport .expensesBtn button.active {
      color: #fff;
      background-color: #f50057; }

.RecievableModal {
  max-width: 85% !important;
  width: 100% !important; }
  .RecievableModal .itemsTable {
    width: 100% !important;
    margin: 10px 0px; }
    .RecievableModal .itemsTable thead {
      background: #fafafa; }
    .RecievableModal .itemsTable th,
    .RecievableModal .itemsTable td {
      border-bottom: 1px solid #f0f0f0; }
    .RecievableModal .itemsTable tr:hover {
      background: #fafafa; }
    .RecievableModal .itemsTable .bordertop {
      border-top: 1px solid black; }

.textCenter {
  text-align: center; }

.modalReportNotiLoader {
  position: unset; }
  .modalReportNotiLoader img {
    margin: 20px;
    width: 70px; }

.deleteAllEntryButton {
  width: 100%;
  background: #1890ff;
  color: white;
  border: none;
  padding: 5px 0px;
  font-weight: 100; }

.filterRow {
  grid-row-gap: 0px;
  row-gap: 0px;
  /* padding: 10px; */
  /* border: 1px solid #ad3a3a; */
  /* background: #ad3a3a; */
  box-shadow: 0px 0px 7px -3px black;
  padding: 4px 5px;
  border-radius: 10px; }
  .filterRow .filterText {
    margin-right: 10px; }

.AddAccount .accountForm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.Consultant .innerCons {
  width: 500px;
  margin: auto;
  padding: 10px;
  box-shadow: 1px 1px 8px 0px #00000061;
  margin-top: 20px; }
  .Consultant .innerCons table th {
    font-weight: bold; }
  .Consultant .innerCons table th,
  .Consultant .innerCons table td {
    font-size: 14px; }
    .Consultant .innerCons table th button,
    .Consultant .innerCons table td button {
      border: none;
      color: white;
      background-color: #001529; }

.Consultant .searchDiv .spaceBetween {
  align-items: flex-end; }

.Consultant .searchDiv .inputFields {
  width: 100%;
  font-size: 14px; }
  .Consultant .searchDiv .inputFields .ant-input-number {
    width: 100%; }
  .Consultant .searchDiv .inputFields .select {
    width: 100%; }
  .Consultant .searchDiv .inputFields .ant-input-number,
  .Consultant .searchDiv .inputFields .select {
    height: 35px; }
  .Consultant .searchDiv .inputFields label h5 {
    margin: 5px 0px 4px 0px; }
  .Consultant .searchDiv .inputFields .checkboxGroup {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center; }
  .Consultant .searchDiv .inputFields input {
    width: 100%;
    height: 40px; }

.Consultant .searchDiv .consultSearchBtn {
  display: flex;
  align-items: center;
  justify-content: center; }
  .Consultant .searchDiv .consultSearchBtn button {
    margin-right: 5px; }

.Consultant .innerConHead {
  text-align: center;
  padding: 20px 0px;
  font-weight: bold;
  font-size: 24px; }

.consModal .buttonDivCon button:first-child {
  margin-right: 10px; }

.consModal .consultForm .spaceBetween {
  align-items: flex-end; }

.consModal .consultForm p {
  margin: 0px;
  font-weight: bold; }

.consModal .consultFormInput {
  margin-top: 10px; }

.ConsultantDetailsModal .consultationHeading {
  display: flex;
  align-items: center; }
  .ConsultantDetailsModal .consultationHeading h3 {
    width: 50%;
    text-align: center; }

.ConsultantDetailsModal table {
  border-collapse: collapse;
  width: 100%; }

.consultattionWeightInput {
  display: flex;
  align-items: center; }
  .consultattionWeightInput select {
    height: 32px;
    width: 70px;
    position: relative;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s; }
  .consultattionWeightInput input {
    width: calc(100% - 70px); }

.accountModal .addDiv .inputFields .inputDiv {
  display: flex; }
  .accountModal .addDiv .inputFields .inputDiv button {
    background: #4094ea;
    border: 1px solid #4094ea;
    color: white;
    font-weight: bold;
    padding: 3px 20px;
    font-size: 12px; }

.lockIcon {
  font-size: 13px; }

button {
  cursor: pointer; }

@font-face {
  font-family: "bookOld";
  src: url(/static/media/BOOKOSB.e6ad3e94.TTF); }

@media print {
  .printBtnCert {
    display: none; }
  .linedevClassName {
    border-right: none !important; }
  .linedevClass {
    border-right: none !important; }
  .capture {
    margin-top: 0px; }
  .logoName {
    font-size: 28px;
    font-family: "bookOld"; }
  .frontside,
  .backside {
    margin: 0px auto;
    width: 8.7cm;
    height: 5.3cm;
    /* border: 1px solid #5F5E5E; */
    border-radius: 15px;
    margin: 0 auto;
    padding: 6px 6px 6px 7px;
    background-image: url(/static/media/certificate-gradient.bf389bf7.png); } }

#capture .MsoNormal {
  font-family: bookOld !important; }

.tableDiv {
  position: relative; }
  .tableDiv .tableLoader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99; }
    .tableDiv .tableLoader img {
      width: 60px; }

.diamondGrading .innerDiamondGrading .filter {
  margin-bottom: 10px; }
  .diamondGrading .innerDiamondGrading .filter .spaceBetween {
    align-items: flex-end; }
  .diamondGrading .innerDiamondGrading .filter .filterButtons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    .diamondGrading .innerDiamondGrading .filter .filterButtons .searchButton {
      background: #1890ff;
      color: white;
      border: none;
      margin-right: 5px; }
    .diamondGrading .innerDiamondGrading .filter .filterButtons .toFormBtn {
      border: 1px solid #001529;
      /* height: 100%; */
      padding: 5px 6px;
      font-size: 14px;
      background: #001529;
      color: white;
      font-weight: mar;
      font-weight: 500; }

.diamondGrading .innerDiamondGrading .diamondImage {
  width: 70px;
  height: 48px; }

.diamondGrading .innerDiamondGrading .itemsTable .actionBtn {
  padding: 5px 20px;
  background: transparent;
  border: 1px solid #001529;
  font-weight: bold;
  color: #001529;
  border-radius: 5px;
  outline: none;
  transition: 0.2s; }
  .diamondGrading .innerDiamondGrading .itemsTable .actionBtn:hover {
    background: #001529;
    color: white; }

.diamondGrading .innerDiamondGrading .itemsTable .editButton {
  background: #1890ff;
  border: 1px solid #045fb3;
  color: white; }

.diamondGrading .innerDiamondGrading .itemsTable .editIcon {
  background: transparent;
  border: none; }

.diamondGrading .innerDiamondGrading .itemsTable .DeleteBtn {
  background: #d40000;
  border: 1px solid #d40000;
  color: white; }

.diamondGrading .innerDiamondGrading .itemsTable .entryNoBtn {
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 700;
  border: 1px solid #001529;
  background: #001529;
  color: white;
  transition: 0.2s;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .diamondGrading .innerDiamondGrading .itemsTable .entryNoBtn:hover {
    background: white;
    color: #001529; }

.DiamondGradingFrom .inputDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; }
  .DiamondGradingFrom .inputDiv.commnetInput {
    flex-direction: column; }
  .DiamondGradingFrom .inputDiv .keyToSymbol {
    display: flex;
    flex-wrap: wrap; }
    .DiamondGradingFrom .inputDiv .keyToSymbol img {
      width: 20px; }
    .DiamondGradingFrom .inputDiv .keyToSymbol label {
      width: auto;
      margin: 0; }
  .DiamondGradingFrom .inputDiv .ant-upload-picture-card-wrapper.avatar-uploader {
    width: 107px;
    margin-right: 10px; }
  .DiamondGradingFrom .inputDiv input,
  .DiamondGradingFrom .inputDiv label,
  .DiamondGradingFrom .inputDiv .select {
    width: 100%; }
  .DiamondGradingFrom .inputDiv .weightDiv {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .DiamondGradingFrom .inputDiv .weightDiv .wieghtNumber {
      display: flex;
      align-items: center; }
      .DiamondGradingFrom .inputDiv .weightDiv .wieghtNumber .ant-input-number {
        width: 70px; }
  .DiamondGradingFrom .inputDiv .dflex {
    display: flex; }
  .DiamondGradingFrom .inputDiv .cutDiv .select {
    width: 60%; }
  .DiamondGradingFrom .inputDiv .cutDiv input {
    width: 40%; }
  .DiamondGradingFrom .inputDiv .griddle .select {
    width: 25%; }
  .DiamondGradingFrom .inputDiv .griddle input {
    width: 50%; }

.DiamondGradingFrom .conditionTale {
  width: 100%; }
  .DiamondGradingFrom .conditionTale th,
  .DiamondGradingFrom .conditionTale td {
    border: 1px solid #afafaf;
    padding: 6px 5px;
    font-size: 12px; }
  .DiamondGradingFrom .conditionTale th {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .DiamondGradingFrom .conditionTale th input {
      width: 30%; }
    .DiamondGradingFrom .conditionTale th p {
      margin: 0px; }

.DiamondGradingFrom .saveButton button {
  padding: 7px 27px;
  font-size: 11px;
  background: #cd0101;
  color: white;
  font-weight: bold;
  border: 1px solid #cd0101;
  border-radius: 5px; }

.innerDiamondGrading .itemsTable {
  border: 1px solid #0000000f; }

main {
  overflow-y: auto;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  main::-webkit-scrollbar {
    width: 2px; }
  main::-webkit-scrollbar-track {
    background: #f1f1f1; }
  main::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px; }
  main ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 50px; }

.remarkOrigin {
  justify-content: space-between; }
  .remarkOrigin label {
    width: 49% !important; }

.smallJewellery .searchButton,
.smallJewellery .toFormBtn,
.smallJewellery .printButton,
.smallGemstone .searchButton,
.smallGemstone .toFormBtn,
.smallGemstone .printButton {
  width: 32%;
  padding: 5px 6px;
  text-align: center; }

.smallJewellery .searchButton,
.smallGemstone .searchButton {
  width: 100%; }

.smallJewellery .printButton,
.smallGemstone .printButton {
  background: #d10001;
  color: white;
  border: none;
  margin-right: 5px; }

.SmallJewelleryForm .maxWord,
.smallGemstone .maxWord {
  font-size: 9px; }

.desFormJewellery {
  background-color: gray;
  text-align: right;
  padding: 10px 20px;
  font-weight: bold; }
  .desFormJewellery h2 {
    color: white;
    margin: 0; }

.jewelleryReport .inputDiv {
  padding: 5px; }

.jewelleryReport .desFormJewellery {
  margin: 20px 0px; }

.jewelleryReport .addFieldBtn {
  background: #1890ff;
  margin-right: 10px;
  border: #1890ff;
  margin: 10px 10px 10px 0px; }

.jewelleryReport .removeItemButton {
  border-radius: 6px;
  background: #001529;
  color: white;
  font-size: 11px;
  border: none;
  padding: 8px 33px; }

.cardMain {
  margin-top: 5px; }

.certificaateDiv {
  margin: 4px 0px; }
  @media screen and (min-width: 1000px) {
    .certificaateDiv.appraCertificaateDiv {
      justify-content: center; } }
  .certificaateDiv .CardSizeBorder {
    position: relative;
    border-bottom: 1px Solid black;
    border-top: 1px Solid black;
    width: 9cm !important; }
  .certificaateDiv .BlackText {
    color: #000; }
  .certificaateDiv .ColHeadings {
    font-weight: 700; }
  .certificaateDiv .dynamic-data {
    font-weight: 400;
    text-transform: none !important; }
  .certificaateDiv .report-wrapper {
    display: flex;
    align-items: center;
    justify-content: center; }
  .certificaateDiv .frontside,
  .certificaateDiv .backside {
    margin: 0px auto;
    width: 11.1cm !important;
    height: 7cm !important;
    /* border: 1px solid #5F5E5E; */
    border-radius: 15px;
    margin: 0 auto;
    padding: 6px 6px 6px 7px;
    background-image: url(/static/media/certificate-gradient.bf389bf7.png); }
  .certificaateDiv .content {
    width: 10.5cm; }
  .certificaateDiv .smallCard ul li {
    line-height: 16px !important; }
  .certificaateDiv .top {
    display: flex;
    align-items: center;
    border-bottom: 2px solid black;
    position: relative; }
    .certificaateDiv .top .logo {
      float: left;
      width: 20%;
      margin: 0px;
      margin-left: 10px;
      margin-right: -15px;
      height: 100%; }
      .certificaateDiv .top .logo img {
        width: 64%;
        height: 100%;
        overflow: visible;
        z-index: 99999; }
    .certificaateDiv .top h1 {
      font-size: 20px;
      margin-top: 1px;
      font-family: "Bookman Old Style", "serif";
      margin-bottom: 1px;
      font-weight: 900; }
    .certificaateDiv .top h2 {
      font-family: Arial;
      font-size: 13px;
      font-style: italic;
      float: left;
      margin-top: -4px;
      font-weight: 900;
      margin-bottom: 0px; }
    .certificaateDiv .top .date {
      float: right;
      font-size: 10px;
      margin-right: 2px;
      position: absolute;
      right: 0px;
      font-weight: 900;
      color: #000; }
  .certificaateDiv .middle {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 5px 0px;
    position: relative;
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: center;
    height: 5cm; }
    .certificaateDiv .middle .content ul {
      padding-left: 0px;
      margin: 0px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 20px; }
      .certificaateDiv .middle .content ul li {
        padding: 2.8px;
        list-style: none;
        font-family: arial, verdana, sans-serif;
        font-size: 11px;
        width: 10.5cm;
        line-height: 11px; }
    .certificaateDiv .middle .product-image {
      width: 3.3cm;
      height: 3.5cm;
      position: absolute;
      top: 4px;
      right: 0px;
      float: right;
      text-align: center;
      display: inline-block;
      overflow: hidden; }
      .certificaateDiv .middle .product-image img {
        width: 2.85cm;
        height: 2cm; }
      .certificaateDiv .middle .product-image .verticaltext {
        font-size: 6px;
        margin-top: -15px;
        left: 33px;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
        position: absolute;
        top: 40px;
        right: -67px; }
    .certificaateDiv .middle .certStatement {
      position: absolute;
      font-size: 8px;
      bottom: 8px; }
  .certificaateDiv .bottom {
    border-top: 2px solid black;
    text-align: center;
    font-size: 9px;
    color: #d80018;
    font-weight: 700; }
  .certificaateDiv span.last {
    text-align: center;
    font-size: 9px;
    color: #d80018;
    font-weight: 700; }
  .certificaateDiv .backside {
    font-family: arial, verdana, sans-serif;
    text-align: center; }
    .certificaateDiv .backside h4 {
      font-size: 12px;
      margin: 10px 0px 1px 0px !important; }
    .certificaateDiv .backside h2 {
      font-family: arial, verdana, sans-serif;
      text-align: center;
      color: #939393;
      font-size: 12px;
      margin: 0px 0px 0px 0px !important; }
    .certificaateDiv .backside p {
      font-family: arial, verdana, sans-serif;
      text-align: justify;
      padding: 3px;
      font-size: 8.1px;
      word-spacing: 2px;
      margin: 0px 0px 1px 0px !important;
      line-height: 9.5px;
      padding-top: 17px; }
    .certificaateDiv .backside .line {
      font-family: arial, verdana, sans-serif;
      text-align: center;
      border: 1px solid black;
      margin: 7px 0px 3px 0px !important;
      position: absolute;
      width: 10.7cm;
      bottom: 20px; }
    .certificaateDiv .backside .last {
      font-family: arial, verdana, sans-serif;
      text-align: center;
      word-spacing: normal;
      font-size: 8px;
      vertical-align: top;
      color: #000;
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0; }

.saveChangeBtn {
  background: #1890ff;
  border: 1px solid #1890ff;
  outline: none;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 600;
  color: white; }

.inputFlex {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .inputFlex input {
    width: 50% !important; }
  .inputFlex label.ant-checkbox-group-item {
    width: 47%; }

.addFieldsBtn {
  background: #1890ff;
  border: 1px solid #1890ff;
  padding: 5px 12px;
  font-size: 13px;
  color: white;
  font-weight: 600;
  margin-left: 5px; }

.certificateLoader {
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: white;
  position: absolute; }
  .certificateLoader .innerLoader {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    max-width: 400px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .certificateLoader .innerLoader .ant-space-item {
      width: 100%;
      height: 100%; }
      .certificateLoader .innerLoader .ant-space-item .ant-spin {
        width: 100%;
        height: 100%; }
        .certificateLoader .innerLoader .ant-space-item .ant-spin .ant-spin-dot {
          width: 100%;
          height: 100%; }
      .certificateLoader .innerLoader .ant-space-item .ant-spin-lg .ant-spin-dot i {
        width: 24px;
        height: 24px; }
  .certificateLoader .loadingText {
    margin-top: 40px;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    color: #1a91ff;
    -webkit-animation: loadingAnime 2s infinite;
            animation: loadingAnime 2s infinite; }

@-webkit-keyframes loadingAnime {
  33% {
    opacity: 1; }
  66% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@keyframes loadingAnime {
  33% {
    opacity: 1; }
  66% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

.mcMesSelect {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 32px;
  outline: none; }
  .mcMesSelect:hover {
    border-color: #1890ff; }

@media screen and (min-width: 1200px) {
  #bigCertificate {
    justify-content: center !important; } }

@media screen and (max-width: 1000px) {
  .appraContainer {
    min-width: 1140px !important; } }

.reciptPage {
  padding: 24px; }
  .reciptPage .innerRecieptPage {
    width: 100%;
    zoom: 90%;
    width: 100%;
    zoom: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .reciptPage .innerRecieptPage .printRecipt {
      width: 47%; }
      .reciptPage .innerRecieptPage .printRecipt .headReciept {
        font-size: 16px;
        text-align: center; }
      .reciptPage .innerRecieptPage .printRecipt .recieptDetail {
        display: flex; }
        .reciptPage .innerRecieptPage .printRecipt .recieptDetail .table1 {
          width: 60%;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content; }
        .reciptPage .innerRecieptPage .printRecipt .recieptDetail .table2 {
          width: 40%; }
        .reciptPage .innerRecieptPage .printRecipt .recieptDetail .customerDet th {
          font-size: 11.4px;
          font-weight: bold; }
        .reciptPage .innerRecieptPage .printRecipt .recieptDetail .customerDet td {
          font-size: 14.4px; }
        .reciptPage .innerRecieptPage .printRecipt .recieptDetail .table2 {
          font-size: 14.4px; }
          .reciptPage .innerRecieptPage .printRecipt .recieptDetail .table2 td {
            text-align: right; }
    .reciptPage .innerRecieptPage .itemListRecipt table {
      border-collapse: collapse;
      border: 1px solid black;
      width: 100%;
      font-size: 0.84em; }
      .reciptPage .innerRecieptPage .itemListRecipt table th {
        border: 1px solid black;
        background: #b2b2b2;
        font-size: 13.44px;
        text-align: center; }
      .reciptPage .innerRecieptPage .itemListRecipt table td {
        text-align: center;
        font-size: 13.44px; }
      .reciptPage .innerRecieptPage .itemListRecipt table .td1 {
        font-size: 0.9em;
        font-style: italic; }
    .reciptPage .innerRecieptPage .recipetTotalTable {
      width: 78%;
      float: right;
      font-size: 12.8px;
      margin-top: 15px; }
      .reciptPage .innerRecieptPage .recipetTotalTable tr th + th {
        text-align: right; }

.printReciptBtn {
  background: #d40000;
  color: white;
  border: none;
  padding: 5px 20px;
  font-weight: bold;
  border-radius: 25px;
  margin: 0px 10px; }

.backReciptBtn {
  background: #41a4ff;
  color: white;
  border: none;
  padding: 5px 20px;
  font-weight: bold;
  border-radius: 25px;
  margin: 0px 10px;
  margin-right: 5px; }

.reasonTh {
  font-size: 10px; }

.takeInPrint {
  padding: 24px; }
  .takeInPrint .innerRecieptPage {
    width: 100%;
    zoom: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .takeInPrint .innerRecieptPage .printRecipt {
      width: 47%; }
      .takeInPrint .innerRecieptPage .printRecipt .headReciept {
        font-size: 16px;
        text-align: center; }
      .takeInPrint .innerRecieptPage .printRecipt .recieptDetail {
        display: flex; }
        .takeInPrint .innerRecieptPage .printRecipt .recieptDetail .table1 {
          width: 60%; }
        .takeInPrint .innerRecieptPage .printRecipt .recieptDetail .table2 {
          width: 40%; }
        .takeInPrint .innerRecieptPage .printRecipt .recieptDetail .customerDet th {
          font-size: 11.4px;
          font-weight: bold; }
        .takeInPrint .innerRecieptPage .printRecipt .recieptDetail .customerDet td {
          font-size: 14.4px; }
        .takeInPrint .innerRecieptPage .printRecipt .recieptDetail .table2 {
          font-size: 14.4px; }
          .takeInPrint .innerRecieptPage .printRecipt .recieptDetail .table2 td {
            text-align: right; }
    .takeInPrint .innerRecieptPage .itemListRecipt table {
      border-collapse: collapse;
      border: 1px solid black;
      width: 100%;
      font-size: 0.84em; }
      .takeInPrint .innerRecieptPage .itemListRecipt table th {
        border: 1px solid black;
        background: #b2b2b2;
        font-size: 13.44px;
        text-align: center; }
      .takeInPrint .innerRecieptPage .itemListRecipt table td {
        text-align: center;
        font-size: 13.44px; }
      .takeInPrint .innerRecieptPage .itemListRecipt table .td1 {
        font-size: 0.9em;
        font-style: italic; }
    .takeInPrint .innerRecieptPage .recipetTotalTable {
      width: 45%;
      float: right;
      font-size: 12.8px;
      margin-top: 15px; }
      .takeInPrint .innerRecieptPage .recipetTotalTable tr th + th {
        text-align: right; }
  .takeInPrint .recipetTotal {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .takeInPrint .recipetTotal .signCust {
      margin-top: 20px;
      padding: 0px 15px; }
      .takeInPrint .recipetTotal .signCust p {
        font-size: 11px;
        text-align: center;
        font-weight: 700; }
      .takeInPrint .recipetTotal .signCust .signBox {
        text-align: center;
        font-weight: 600;
        margin-top: 30px; }

.printModal {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff8f;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  flex-direction: column; }

.printReciptBtn {
  background: #d40000;
  color: white;
  border: none;
  padding: 5px 20px;
  font-weight: bold;
  border-radius: 25px;
  margin: 0px 10px; }

.backReciptBtn {
  background: #41a4ff;
  color: white;
  border: none;
  padding: 5px 20px;
  font-weight: bold;
  border-radius: 25px;
  margin: 0px 10px;
  margin-right: 5px; }

@media print {
  @page {
    width: 980px !important;
    max-width: 1000px !important;
    margin: 10px 50px !important; }
  body {
    margin: 1.6cm;
    width: 980px;
    max-width: 1000px;
    margin: 5px; } }

.certificateDiv .innerCertificate .results {
  width: 50%; }
  .certificateDiv .innerCertificate .results .logoDiv {
    text-align: center; }
    .certificateDiv .innerCertificate .results .logoDiv img {
      margin-right: 81px;
      width: 188px; }

.certificateDiv .innerCertificate .details {
  width: 50%; }

.tablerow_height {
  height: 40px; }
  .tablerow_height.td_heading {
    height: 25px; }

.certificateDiv .innerCertificate .results {
  width: 50%; }
  .certificateDiv .innerCertificate .results .logoDiv {
    text-align: center; }
    .certificateDiv .innerCertificate .results .logoDiv img {
      margin-right: 81px;
      width: 188px; }

.certificateDiv .innerCertificate .details {
  width: 50%; }

/*[ FONT SIZE ]
///////////////////////////////////////////////////////////
*/
.fs-1 {
  font-size: 1px !important; }

.fs-2 {
  font-size: 2px !important; }

.fs-3 {
  font-size: 3px !important; }

.fs-4 {
  font-size: 4px !important; }

.fs-5 {
  font-size: 5px !important; }

.fs-6 {
  font-size: 6px !important; }

.fs-7 {
  font-size: 7px !important; }

.fs-8 {
  font-size: 8px !important; }

.fs-9 {
  font-size: 9px !important; }

.fs-10 {
  font-size: 10px !important; }

.fs-11 {
  font-size: 11px !important; }

.fs-12 {
  font-size: 12px !important; }

.fs-13 {
  font-size: 13px !important; }

.fs-14 {
  font-size: 14px !important; }

.fs-15 {
  font-size: 15px !important; }

.fs-16 {
  font-size: 16px !important; }

.fs-17 {
  font-size: 17px !important; }

.fs-18 {
  font-size: 18px !important; }

.fs-19 {
  font-size: 19px !important; }

.fs-20 {
  font-size: 20px !important; }

.fs-21 {
  font-size: 21px !important; }

.fs-22 {
  font-size: 22px !important; }

.fs-23 {
  font-size: 23px !important; }

.fs-24 {
  font-size: 24px !important; }

.fs-25 {
  font-size: 25px !important; }

.fs-26 {
  font-size: 26px !important; }

.fs-27 {
  font-size: 27px !important; }

.fs-28 {
  font-size: 28px !important; }

.fs-29 {
  font-size: 29px !important; }

.fs-30 {
  font-size: 30px !important; }

.fs-31 {
  font-size: 31px !important; }

.fs-32 {
  font-size: 32px !important; }

.fs-33 {
  font-size: 33px !important; }

.fs-34 {
  font-size: 34px !important; }

.fs-35 {
  font-size: 35px !important; }

.fs-36 {
  font-size: 36px !important; }

.fs-37 {
  font-size: 37px !important; }

.fs-38 {
  font-size: 38px !important; }

.fs-39 {
  font-size: 39px !important; }

.fs-40 {
  font-size: 40px !important; }

.fs-41 {
  font-size: 41px !important; }

.fs-42 {
  font-size: 42px !important; }

.fs-43 {
  font-size: 43px !important; }

.fs-44 {
  font-size: 44px !important; }

.fs-45 {
  font-size: 45px !important; }

.fs-46 {
  font-size: 46px !important; }

.fs-47 {
  font-size: 47px !important; }

.fs-48 {
  font-size: 48px !important; }

.fs-49 {
  font-size: 49px !important; }

.fs-50 {
  font-size: 50px !important; }

.fs-51 {
  font-size: 51px !important; }

.fs-52 {
  font-size: 52px !important; }

.fs-53 {
  font-size: 53px !important; }

.fs-54 {
  font-size: 54px !important; }

.fs-55 {
  font-size: 55px !important; }

.fs-56 {
  font-size: 56px !important; }

.fs-57 {
  font-size: 57px !important; }

.fs-58 {
  font-size: 58px !important; }

.fs-59 {
  font-size: 59px !important; }

.fs-60 {
  font-size: 60px !important; }

.fs-61 {
  font-size: 61px !important; }

.fs-62 {
  font-size: 62px !important; }

.fs-63 {
  font-size: 63px !important; }

.fs-64 {
  font-size: 64px !important; }

.fs-65 {
  font-size: 65px !important; }

.fs-66 {
  font-size: 66px !important; }

.fs-67 {
  font-size: 67px !important; }

.fs-68 {
  font-size: 68px !important; }

.fs-69 {
  font-size: 69px !important; }

.fs-70 {
  font-size: 70px !important; }

.fs-71 {
  font-size: 71px !important; }

.fs-72 {
  font-size: 72px !important; }

.fs-73 {
  font-size: 73px !important; }

.fs-74 {
  font-size: 74px !important; }

.fs-75 {
  font-size: 75px !important; }

.fs-76 {
  font-size: 76px !important; }

.fs-77 {
  font-size: 77px !important; }

.fs-78 {
  font-size: 78px !important; }

.fs-79 {
  font-size: 79px !important; }

.fs-80 {
  font-size: 80px !important; }

.fs-81 {
  font-size: 81px !important; }

.fs-82 {
  font-size: 82px !important; }

.fs-83 {
  font-size: 83px !important; }

.fs-84 {
  font-size: 84px !important; }

.fs-85 {
  font-size: 85px !important; }

.fs-86 {
  font-size: 86px !important; }

.fs-87 {
  font-size: 87px !important; }

.fs-88 {
  font-size: 88px !important; }

.fs-89 {
  font-size: 89px !important; }

.fs-90 {
  font-size: 90px !important; }

.fs-91 {
  font-size: 91px !important; }

.fs-92 {
  font-size: 92px !important; }

.fs-93 {
  font-size: 93px !important; }

.fs-94 {
  font-size: 94px !important; }

.fs-95 {
  font-size: 95px !important; }

.fs-96 {
  font-size: 96px !important; }

.fs-97 {
  font-size: 97px !important; }

.fs-98 {
  font-size: 98px !important; }

.fs-99 {
  font-size: 99px !important; }

.fs-100 {
  font-size: 100px !important; }

.fs-101 {
  font-size: 101px !important; }

.fs-102 {
  font-size: 102px !important; }

.fs-103 {
  font-size: 103px !important; }

.fs-104 {
  font-size: 104px !important; }

.fs-105 {
  font-size: 105px !important; }

.fs-106 {
  font-size: 106px !important; }

.fs-107 {
  font-size: 107px !important; }

.fs-108 {
  font-size: 108px !important; }

.fs-109 {
  font-size: 109px !important; }

.fs-110 {
  font-size: 110px !important; }

.fs-111 {
  font-size: 111px !important; }

.fs-112 {
  font-size: 112px !important; }

.fs-113 {
  font-size: 113px !important; }

.fs-114 {
  font-size: 114px !important; }

.fs-115 {
  font-size: 115px !important; }

.fs-116 {
  font-size: 116px !important; }

.fs-117 {
  font-size: 117px !important; }

.fs-118 {
  font-size: 118px !important; }

.fs-119 {
  font-size: 119px !important; }

.fs-120 {
  font-size: 120px !important; }

.fs-121 {
  font-size: 121px !important; }

.fs-122 {
  font-size: 122px !important; }

.fs-123 {
  font-size: 123px !important; }

.fs-124 {
  font-size: 124px !important; }

.fs-125 {
  font-size: 125px !important; }

.fs-126 {
  font-size: 126px !important; }

.fs-127 {
  font-size: 127px !important; }

.fs-128 {
  font-size: 128px !important; }

.fs-129 {
  font-size: 129px !important; }

.fs-130 {
  font-size: 130px !important; }

.fs-131 {
  font-size: 131px !important; }

.fs-132 {
  font-size: 132px !important; }

.fs-133 {
  font-size: 133px !important; }

.fs-134 {
  font-size: 134px !important; }

.fs-135 {
  font-size: 135px !important; }

.fs-136 {
  font-size: 136px !important; }

.fs-137 {
  font-size: 137px !important; }

.fs-138 {
  font-size: 138px !important; }

.fs-139 {
  font-size: 139px !important; }

.fs-140 {
  font-size: 140px !important; }

.fs-141 {
  font-size: 141px !important; }

.fs-142 {
  font-size: 142px !important; }

.fs-143 {
  font-size: 143px !important; }

.fs-144 {
  font-size: 144px !important; }

.fs-145 {
  font-size: 145px !important; }

.fs-146 {
  font-size: 146px !important; }

.fs-147 {
  font-size: 147px !important; }

.fs-148 {
  font-size: 148px !important; }

.fs-149 {
  font-size: 149px !important; }

.fs-150 {
  font-size: 150px !important; }

.fs-151 {
  font-size: 151px !important; }

.fs-152 {
  font-size: 152px !important; }

.fs-153 {
  font-size: 153px !important; }

.fs-154 {
  font-size: 154px !important; }

.fs-155 {
  font-size: 155px !important; }

.fs-156 {
  font-size: 156px !important; }

.fs-157 {
  font-size: 157px !important; }

.fs-158 {
  font-size: 158px !important; }

.fs-159 {
  font-size: 159px !important; }

.fs-160 {
  font-size: 160px !important; }

.fs-161 {
  font-size: 161px !important; }

.fs-162 {
  font-size: 162px !important; }

.fs-163 {
  font-size: 163px !important; }

.fs-164 {
  font-size: 164px !important; }

.fs-165 {
  font-size: 165px !important; }

.fs-166 {
  font-size: 166px !important; }

.fs-167 {
  font-size: 167px !important; }

.fs-168 {
  font-size: 168px !important; }

.fs-169 {
  font-size: 169px !important; }

.fs-170 {
  font-size: 170px !important; }

.fs-171 {
  font-size: 171px !important; }

.fs-172 {
  font-size: 172px !important; }

.fs-173 {
  font-size: 173px !important; }

.fs-174 {
  font-size: 174px !important; }

.fs-175 {
  font-size: 175px !important; }

.fs-176 {
  font-size: 176px !important; }

.fs-177 {
  font-size: 177px !important; }

.fs-178 {
  font-size: 178px !important; }

.fs-179 {
  font-size: 179px !important; }

.fs-180 {
  font-size: 180px !important; }

.fs-181 {
  font-size: 181px !important; }

.fs-182 {
  font-size: 182px !important; }

.fs-183 {
  font-size: 183px !important; }

.fs-184 {
  font-size: 184px !important; }

.fs-185 {
  font-size: 185px !important; }

.fs-186 {
  font-size: 186px !important; }

.fs-187 {
  font-size: 187px !important; }

.fs-188 {
  font-size: 188px !important; }

.fs-189 {
  font-size: 189px !important; }

.fs-190 {
  font-size: 190px !important; }

.fs-191 {
  font-size: 191px !important; }

.fs-192 {
  font-size: 192px !important; }

.fs-193 {
  font-size: 193px !important; }

.fs-194 {
  font-size: 194px !important; }

.fs-195 {
  font-size: 195px !important; }

.fs-196 {
  font-size: 196px !important; }

.fs-197 {
  font-size: 197px !important; }

.fs-198 {
  font-size: 198px !important; }

.fs-199 {
  font-size: 199px !important; }

.fs-200 {
  font-size: 200px !important; }

/*[ PADDING ]
///////////////////////////////////////////////////////////
*/
.p-t-0 {
  padding-top: 0px !important; }

.p-t-1 {
  padding-top: 1px !important; }

.p-t-2 {
  padding-top: 2px !important; }

.p-t-3 {
  padding-top: 3px !important; }

.p-t-4 {
  padding-top: 4px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-t-6 {
  padding-top: 6px !important; }

.p-t-7 {
  padding-top: 7px !important; }

.p-t-8 {
  padding-top: 8px !important; }

.p-t-9 {
  padding-top: 9px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-t-11 {
  padding-top: 11px !important; }

.p-t-12 {
  padding-top: 12px !important; }

.p-t-13 {
  padding-top: 13px !important; }

.p-t-14 {
  padding-top: 14px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-t-16 {
  padding-top: 16px !important; }

.p-t-17 {
  padding-top: 17px !important; }

.p-t-18 {
  padding-top: 18px !important; }

.p-t-19 {
  padding-top: 19px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-t-21 {
  padding-top: 21px !important; }

.p-t-22 {
  padding-top: 22px !important; }

.p-t-23 {
  padding-top: 23px !important; }

.p-t-24 {
  padding-top: 24px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-t-26 {
  padding-top: 26px !important; }

.p-t-27 {
  padding-top: 27px !important; }

.p-t-28 {
  padding-top: 28px !important; }

.p-t-29 {
  padding-top: 29px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-t-31 {
  padding-top: 31px !important; }

.p-t-32 {
  padding-top: 32px !important; }

.p-t-33 {
  padding-top: 33px !important; }

.p-t-34 {
  padding-top: 34px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.p-t-36 {
  padding-top: 36px !important; }

.p-t-37 {
  padding-top: 37px !important; }

.p-t-38 {
  padding-top: 38px !important; }

.p-t-39 {
  padding-top: 39px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-t-41 {
  padding-top: 41px !important; }

.p-t-42 {
  padding-top: 42px !important; }

.p-t-43 {
  padding-top: 43px !important; }

.p-t-44 {
  padding-top: 44px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.p-t-46 {
  padding-top: 46px !important; }

.p-t-47 {
  padding-top: 47px !important; }

.p-t-48 {
  padding-top: 48px !important; }

.p-t-49 {
  padding-top: 49px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.p-t-51 {
  padding-top: 51px !important; }

.p-t-52 {
  padding-top: 52px !important; }

.p-t-53 {
  padding-top: 53px !important; }

.p-t-54 {
  padding-top: 54px !important; }

.p-t-55 {
  padding-top: 55px !important; }

.p-t-56 {
  padding-top: 56px !important; }

.p-t-57 {
  padding-top: 57px !important; }

.p-t-58 {
  padding-top: 58px !important; }

.p-t-59 {
  padding-top: 59px !important; }

.p-t-60 {
  padding-top: 60px !important; }

.p-t-61 {
  padding-top: 61px !important; }

.p-t-62 {
  padding-top: 62px !important; }

.p-t-63 {
  padding-top: 63px !important; }

.p-t-64 {
  padding-top: 64px !important; }

.p-t-65 {
  padding-top: 65px !important; }

.p-t-66 {
  padding-top: 66px !important; }

.p-t-67 {
  padding-top: 67px !important; }

.p-t-68 {
  padding-top: 68px !important; }

.p-t-69 {
  padding-top: 69px !important; }

.p-t-70 {
  padding-top: 70px !important; }

.p-t-71 {
  padding-top: 71px !important; }

.p-t-72 {
  padding-top: 72px !important; }

.p-t-73 {
  padding-top: 73px !important; }

.p-t-74 {
  padding-top: 74px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.p-t-76 {
  padding-top: 76px !important; }

.p-t-77 {
  padding-top: 77px !important; }

.p-t-78 {
  padding-top: 78px !important; }

.p-t-79 {
  padding-top: 79px !important; }

.p-t-80 {
  padding-top: 80px !important; }

.p-t-81 {
  padding-top: 81px !important; }

.p-t-82 {
  padding-top: 82px !important; }

.p-t-83 {
  padding-top: 83px !important; }

.p-t-84 {
  padding-top: 84px !important; }

.p-t-85 {
  padding-top: 85px !important; }

.p-t-86 {
  padding-top: 86px !important; }

.p-t-87 {
  padding-top: 87px !important; }

.p-t-88 {
  padding-top: 88px !important; }

.p-t-89 {
  padding-top: 89px !important; }

.p-t-90 {
  padding-top: 90px !important; }

.p-t-91 {
  padding-top: 91px !important; }

.p-t-92 {
  padding-top: 92px !important; }

.p-t-93 {
  padding-top: 93px !important; }

.p-t-94 {
  padding-top: 94px !important; }

.p-t-95 {
  padding-top: 95px !important; }

.p-t-96 {
  padding-top: 96px !important; }

.p-t-97 {
  padding-top: 97px !important; }

.p-t-98 {
  padding-top: 98px !important; }

.p-t-99 {
  padding-top: 99px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.p-t-101 {
  padding-top: 101px !important; }

.p-t-102 {
  padding-top: 102px !important; }

.p-t-103 {
  padding-top: 103px !important; }

.p-t-104 {
  padding-top: 104px !important; }

.p-t-105 {
  padding-top: 105px !important; }

.p-t-106 {
  padding-top: 106px !important; }

.p-t-107 {
  padding-top: 107px !important; }

.p-t-108 {
  padding-top: 108px !important; }

.p-t-109 {
  padding-top: 109px !important; }

.p-t-110 {
  padding-top: 110px !important; }

.p-t-111 {
  padding-top: 111px !important; }

.p-t-112 {
  padding-top: 112px !important; }

.p-t-113 {
  padding-top: 113px !important; }

.p-t-114 {
  padding-top: 114px !important; }

.p-t-115 {
  padding-top: 115px !important; }

.p-t-116 {
  padding-top: 116px !important; }

.p-t-117 {
  padding-top: 117px !important; }

.p-t-118 {
  padding-top: 118px !important; }

.p-t-119 {
  padding-top: 119px !important; }

.p-t-120 {
  padding-top: 120px !important; }

.p-t-121 {
  padding-top: 121px !important; }

.p-t-122 {
  padding-top: 122px !important; }

.p-t-123 {
  padding-top: 123px !important; }

.p-t-124 {
  padding-top: 124px !important; }

.p-t-125 {
  padding-top: 125px !important; }

.p-t-126 {
  padding-top: 126px !important; }

.p-t-127 {
  padding-top: 127px !important; }

.p-t-128 {
  padding-top: 128px !important; }

.p-t-129 {
  padding-top: 129px !important; }

.p-t-130 {
  padding-top: 130px !important; }

.p-t-131 {
  padding-top: 131px !important; }

.p-t-132 {
  padding-top: 132px !important; }

.p-t-133 {
  padding-top: 133px !important; }

.p-t-134 {
  padding-top: 134px !important; }

.p-t-135 {
  padding-top: 135px !important; }

.p-t-136 {
  padding-top: 136px !important; }

.p-t-137 {
  padding-top: 137px !important; }

.p-t-138 {
  padding-top: 138px !important; }

.p-t-139 {
  padding-top: 139px !important; }

.p-t-140 {
  padding-top: 140px !important; }

.p-t-141 {
  padding-top: 141px !important; }

.p-t-142 {
  padding-top: 142px !important; }

.p-t-143 {
  padding-top: 143px !important; }

.p-t-144 {
  padding-top: 144px !important; }

.p-t-145 {
  padding-top: 145px !important; }

.p-t-146 {
  padding-top: 146px !important; }

.p-t-147 {
  padding-top: 147px !important; }

.p-t-148 {
  padding-top: 148px !important; }

.p-t-149 {
  padding-top: 149px !important; }

.p-t-150 {
  padding-top: 150px !important; }

.p-t-151 {
  padding-top: 151px !important; }

.p-t-152 {
  padding-top: 152px !important; }

.p-t-153 {
  padding-top: 153px !important; }

.p-t-154 {
  padding-top: 154px !important; }

.p-t-155 {
  padding-top: 155px !important; }

.p-t-156 {
  padding-top: 156px !important; }

.p-t-157 {
  padding-top: 157px !important; }

.p-t-158 {
  padding-top: 158px !important; }

.p-t-159 {
  padding-top: 159px !important; }

.p-t-160 {
  padding-top: 160px !important; }

.p-t-161 {
  padding-top: 161px !important; }

.p-t-162 {
  padding-top: 162px !important; }

.p-t-163 {
  padding-top: 163px !important; }

.p-t-164 {
  padding-top: 164px !important; }

.p-t-165 {
  padding-top: 165px !important; }

.p-t-166 {
  padding-top: 166px !important; }

.p-t-167 {
  padding-top: 167px !important; }

.p-t-168 {
  padding-top: 168px !important; }

.p-t-169 {
  padding-top: 169px !important; }

.p-t-170 {
  padding-top: 170px !important; }

.p-t-171 {
  padding-top: 171px !important; }

.p-t-172 {
  padding-top: 172px !important; }

.p-t-173 {
  padding-top: 173px !important; }

.p-t-174 {
  padding-top: 174px !important; }

.p-t-175 {
  padding-top: 175px !important; }

.p-t-176 {
  padding-top: 176px !important; }

.p-t-177 {
  padding-top: 177px !important; }

.p-t-178 {
  padding-top: 178px !important; }

.p-t-179 {
  padding-top: 179px !important; }

.p-t-180 {
  padding-top: 180px !important; }

.p-t-181 {
  padding-top: 181px !important; }

.p-t-182 {
  padding-top: 182px !important; }

.p-t-183 {
  padding-top: 183px !important; }

.p-t-184 {
  padding-top: 184px !important; }

.p-t-185 {
  padding-top: 185px !important; }

.p-t-186 {
  padding-top: 186px !important; }

.p-t-187 {
  padding-top: 187px !important; }

.p-t-188 {
  padding-top: 188px !important; }

.p-t-189 {
  padding-top: 189px !important; }

.p-t-190 {
  padding-top: 190px !important; }

.p-t-191 {
  padding-top: 191px !important; }

.p-t-192 {
  padding-top: 192px !important; }

.p-t-193 {
  padding-top: 193px !important; }

.p-t-194 {
  padding-top: 194px !important; }

.p-t-195 {
  padding-top: 195px !important; }

.p-t-196 {
  padding-top: 196px !important; }

.p-t-197 {
  padding-top: 197px !important; }

.p-t-198 {
  padding-top: 198px !important; }

.p-t-199 {
  padding-top: 199px !important; }

.p-t-200 {
  padding-top: 200px !important; }

.p-t-201 {
  padding-top: 201px !important; }

.p-t-202 {
  padding-top: 202px !important; }

.p-t-203 {
  padding-top: 203px !important; }

.p-t-204 {
  padding-top: 204px !important; }

.p-t-205 {
  padding-top: 205px !important; }

.p-t-206 {
  padding-top: 206px !important; }

.p-t-207 {
  padding-top: 207px !important; }

.p-t-208 {
  padding-top: 208px !important; }

.p-t-209 {
  padding-top: 209px !important; }

.p-t-210 {
  padding-top: 210px !important; }

.p-t-211 {
  padding-top: 211px !important; }

.p-t-212 {
  padding-top: 212px !important; }

.p-t-213 {
  padding-top: 213px !important; }

.p-t-214 {
  padding-top: 214px !important; }

.p-t-215 {
  padding-top: 215px !important; }

.p-t-216 {
  padding-top: 216px !important; }

.p-t-217 {
  padding-top: 217px !important; }

.p-t-218 {
  padding-top: 218px !important; }

.p-t-219 {
  padding-top: 219px !important; }

.p-t-220 {
  padding-top: 220px !important; }

.p-t-221 {
  padding-top: 221px !important; }

.p-t-222 {
  padding-top: 222px !important; }

.p-t-223 {
  padding-top: 223px !important; }

.p-t-224 {
  padding-top: 224px !important; }

.p-t-225 {
  padding-top: 225px !important; }

.p-t-226 {
  padding-top: 226px !important; }

.p-t-227 {
  padding-top: 227px !important; }

.p-t-228 {
  padding-top: 228px !important; }

.p-t-229 {
  padding-top: 229px !important; }

.p-t-230 {
  padding-top: 230px !important; }

.p-t-231 {
  padding-top: 231px !important; }

.p-t-232 {
  padding-top: 232px !important; }

.p-t-233 {
  padding-top: 233px !important; }

.p-t-234 {
  padding-top: 234px !important; }

.p-t-235 {
  padding-top: 235px !important; }

.p-t-236 {
  padding-top: 236px !important; }

.p-t-237 {
  padding-top: 237px !important; }

.p-t-238 {
  padding-top: 238px !important; }

.p-t-239 {
  padding-top: 239px !important; }

.p-t-240 {
  padding-top: 240px !important; }

.p-t-241 {
  padding-top: 241px !important; }

.p-t-242 {
  padding-top: 242px !important; }

.p-t-243 {
  padding-top: 243px !important; }

.p-t-244 {
  padding-top: 244px !important; }

.p-t-245 {
  padding-top: 245px !important; }

.p-t-246 {
  padding-top: 246px !important; }

.p-t-247 {
  padding-top: 247px !important; }

.p-t-248 {
  padding-top: 248px !important; }

.p-t-249 {
  padding-top: 249px !important; }

.p-t-250 {
  padding-top: 250px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.p-b-1 {
  padding-bottom: 1px !important; }

.p-b-2 {
  padding-bottom: 2px !important; }

.p-b-3 {
  padding-bottom: 3px !important; }

.p-b-4 {
  padding-bottom: 4px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.p-b-6 {
  padding-bottom: 6px !important; }

.p-b-7 {
  padding-bottom: 7px !important; }

.p-b-8 {
  padding-bottom: 8px !important; }

.p-b-9 {
  padding-bottom: 9px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.p-b-11 {
  padding-bottom: 11px !important; }

.p-b-12 {
  padding-bottom: 12px !important; }

.p-b-13 {
  padding-bottom: 13px !important; }

.p-b-14 {
  padding-bottom: 14px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.p-b-16 {
  padding-bottom: 16px !important; }

.p-b-17 {
  padding-bottom: 17px !important; }

.p-b-18 {
  padding-bottom: 18px !important; }

.p-b-19 {
  padding-bottom: 19px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.p-b-21 {
  padding-bottom: 21px !important; }

.p-b-22 {
  padding-bottom: 22px !important; }

.p-b-23 {
  padding-bottom: 23px !important; }

.p-b-24 {
  padding-bottom: 24px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.p-b-26 {
  padding-bottom: 26px !important; }

.p-b-27 {
  padding-bottom: 27px !important; }

.p-b-28 {
  padding-bottom: 28px !important; }

.p-b-29 {
  padding-bottom: 29px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.p-b-31 {
  padding-bottom: 31px !important; }

.p-b-32 {
  padding-bottom: 32px !important; }

.p-b-33 {
  padding-bottom: 33px !important; }

.p-b-34 {
  padding-bottom: 34px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.p-b-36 {
  padding-bottom: 36px !important; }

.p-b-37 {
  padding-bottom: 37px !important; }

.p-b-38 {
  padding-bottom: 38px !important; }

.p-b-39 {
  padding-bottom: 39px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.p-b-41 {
  padding-bottom: 41px !important; }

.p-b-42 {
  padding-bottom: 42px !important; }

.p-b-43 {
  padding-bottom: 43px !important; }

.p-b-44 {
  padding-bottom: 44px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.p-b-46 {
  padding-bottom: 46px !important; }

.p-b-47 {
  padding-bottom: 47px !important; }

.p-b-48 {
  padding-bottom: 48px !important; }

.p-b-49 {
  padding-bottom: 49px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.p-b-51 {
  padding-bottom: 51px !important; }

.p-b-52 {
  padding-bottom: 52px !important; }

.p-b-53 {
  padding-bottom: 53px !important; }

.p-b-54 {
  padding-bottom: 54px !important; }

.p-b-55 {
  padding-bottom: 55px !important; }

.p-b-56 {
  padding-bottom: 56px !important; }

.p-b-57 {
  padding-bottom: 57px !important; }

.p-b-58 {
  padding-bottom: 58px !important; }

.p-b-59 {
  padding-bottom: 59px !important; }

.p-b-60 {
  padding-bottom: 60px !important; }

.p-b-61 {
  padding-bottom: 61px !important; }

.p-b-62 {
  padding-bottom: 62px !important; }

.p-b-63 {
  padding-bottom: 63px !important; }

.p-b-64 {
  padding-bottom: 64px !important; }

.p-b-65 {
  padding-bottom: 65px !important; }

.p-b-66 {
  padding-bottom: 66px !important; }

.p-b-67 {
  padding-bottom: 67px !important; }

.p-b-68 {
  padding-bottom: 68px !important; }

.p-b-69 {
  padding-bottom: 69px !important; }

.p-b-70 {
  padding-bottom: 70px !important; }

.p-b-71 {
  padding-bottom: 71px !important; }

.p-b-72 {
  padding-bottom: 72px !important; }

.p-b-73 {
  padding-bottom: 73px !important; }

.p-b-74 {
  padding-bottom: 74px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.p-b-76 {
  padding-bottom: 76px !important; }

.p-b-77 {
  padding-bottom: 77px !important; }

.p-b-78 {
  padding-bottom: 78px !important; }

.p-b-79 {
  padding-bottom: 79px !important; }

.p-b-80 {
  padding-bottom: 80px !important; }

.p-b-81 {
  padding-bottom: 81px !important; }

.p-b-82 {
  padding-bottom: 82px !important; }

.p-b-83 {
  padding-bottom: 83px !important; }

.p-b-84 {
  padding-bottom: 84px !important; }

.p-b-85 {
  padding-bottom: 85px !important; }

.p-b-86 {
  padding-bottom: 86px !important; }

.p-b-87 {
  padding-bottom: 87px !important; }

.p-b-88 {
  padding-bottom: 88px !important; }

.p-b-89 {
  padding-bottom: 89px !important; }

.p-b-90 {
  padding-bottom: 90px !important; }

.p-b-91 {
  padding-bottom: 91px !important; }

.p-b-92 {
  padding-bottom: 92px !important; }

.p-b-93 {
  padding-bottom: 93px !important; }

.p-b-94 {
  padding-bottom: 94px !important; }

.p-b-95 {
  padding-bottom: 95px !important; }

.p-b-96 {
  padding-bottom: 96px !important; }

.p-b-97 {
  padding-bottom: 97px !important; }

.p-b-98 {
  padding-bottom: 98px !important; }

.p-b-99 {
  padding-bottom: 99px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.p-b-101 {
  padding-bottom: 101px !important; }

.p-b-102 {
  padding-bottom: 102px !important; }

.p-b-103 {
  padding-bottom: 103px !important; }

.p-b-104 {
  padding-bottom: 104px !important; }

.p-b-105 {
  padding-bottom: 105px !important; }

.p-b-106 {
  padding-bottom: 106px !important; }

.p-b-107 {
  padding-bottom: 107px !important; }

.p-b-108 {
  padding-bottom: 108px !important; }

.p-b-109 {
  padding-bottom: 109px !important; }

.p-b-110 {
  padding-bottom: 110px !important; }

.p-b-111 {
  padding-bottom: 111px !important; }

.p-b-112 {
  padding-bottom: 112px !important; }

.p-b-113 {
  padding-bottom: 113px !important; }

.p-b-114 {
  padding-bottom: 114px !important; }

.p-b-115 {
  padding-bottom: 115px !important; }

.p-b-116 {
  padding-bottom: 116px !important; }

.p-b-117 {
  padding-bottom: 117px !important; }

.p-b-118 {
  padding-bottom: 118px !important; }

.p-b-119 {
  padding-bottom: 119px !important; }

.p-b-120 {
  padding-bottom: 120px !important; }

.p-b-121 {
  padding-bottom: 121px !important; }

.p-b-122 {
  padding-bottom: 122px !important; }

.p-b-123 {
  padding-bottom: 123px !important; }

.p-b-124 {
  padding-bottom: 124px !important; }

.p-b-125 {
  padding-bottom: 125px !important; }

.p-b-126 {
  padding-bottom: 126px !important; }

.p-b-127 {
  padding-bottom: 127px !important; }

.p-b-128 {
  padding-bottom: 128px !important; }

.p-b-129 {
  padding-bottom: 129px !important; }

.p-b-130 {
  padding-bottom: 130px !important; }

.p-b-131 {
  padding-bottom: 131px !important; }

.p-b-132 {
  padding-bottom: 132px !important; }

.p-b-133 {
  padding-bottom: 133px !important; }

.p-b-134 {
  padding-bottom: 134px !important; }

.p-b-135 {
  padding-bottom: 135px !important; }

.p-b-136 {
  padding-bottom: 136px !important; }

.p-b-137 {
  padding-bottom: 137px !important; }

.p-b-138 {
  padding-bottom: 138px !important; }

.p-b-139 {
  padding-bottom: 139px !important; }

.p-b-140 {
  padding-bottom: 140px !important; }

.p-b-141 {
  padding-bottom: 141px !important; }

.p-b-142 {
  padding-bottom: 142px !important; }

.p-b-143 {
  padding-bottom: 143px !important; }

.p-b-144 {
  padding-bottom: 144px !important; }

.p-b-145 {
  padding-bottom: 145px !important; }

.p-b-146 {
  padding-bottom: 146px !important; }

.p-b-147 {
  padding-bottom: 147px !important; }

.p-b-148 {
  padding-bottom: 148px !important; }

.p-b-149 {
  padding-bottom: 149px !important; }

.p-b-150 {
  padding-bottom: 150px !important; }

.p-b-151 {
  padding-bottom: 151px !important; }

.p-b-152 {
  padding-bottom: 152px !important; }

.p-b-153 {
  padding-bottom: 153px !important; }

.p-b-154 {
  padding-bottom: 154px !important; }

.p-b-155 {
  padding-bottom: 155px !important; }

.p-b-156 {
  padding-bottom: 156px !important; }

.p-b-157 {
  padding-bottom: 157px !important; }

.p-b-158 {
  padding-bottom: 158px !important; }

.p-b-159 {
  padding-bottom: 159px !important; }

.p-b-160 {
  padding-bottom: 160px !important; }

.p-b-161 {
  padding-bottom: 161px !important; }

.p-b-162 {
  padding-bottom: 162px !important; }

.p-b-163 {
  padding-bottom: 163px !important; }

.p-b-164 {
  padding-bottom: 164px !important; }

.p-b-165 {
  padding-bottom: 165px !important; }

.p-b-166 {
  padding-bottom: 166px !important; }

.p-b-167 {
  padding-bottom: 167px !important; }

.p-b-168 {
  padding-bottom: 168px !important; }

.p-b-169 {
  padding-bottom: 169px !important; }

.p-b-170 {
  padding-bottom: 170px !important; }

.p-b-171 {
  padding-bottom: 171px !important; }

.p-b-172 {
  padding-bottom: 172px !important; }

.p-b-173 {
  padding-bottom: 173px !important; }

.p-b-174 {
  padding-bottom: 174px !important; }

.p-b-175 {
  padding-bottom: 175px !important; }

.p-b-176 {
  padding-bottom: 176px !important; }

.p-b-177 {
  padding-bottom: 177px !important; }

.p-b-178 {
  padding-bottom: 178px !important; }

.p-b-179 {
  padding-bottom: 179px !important; }

.p-b-180 {
  padding-bottom: 180px !important; }

.p-b-181 {
  padding-bottom: 181px !important; }

.p-b-182 {
  padding-bottom: 182px !important; }

.p-b-183 {
  padding-bottom: 183px !important; }

.p-b-184 {
  padding-bottom: 184px !important; }

.p-b-185 {
  padding-bottom: 185px !important; }

.p-b-186 {
  padding-bottom: 186px !important; }

.p-b-187 {
  padding-bottom: 187px !important; }

.p-b-188 {
  padding-bottom: 188px !important; }

.p-b-189 {
  padding-bottom: 189px !important; }

.p-b-190 {
  padding-bottom: 190px !important; }

.p-b-191 {
  padding-bottom: 191px !important; }

.p-b-192 {
  padding-bottom: 192px !important; }

.p-b-193 {
  padding-bottom: 193px !important; }

.p-b-194 {
  padding-bottom: 194px !important; }

.p-b-195 {
  padding-bottom: 195px !important; }

.p-b-196 {
  padding-bottom: 196px !important; }

.p-b-197 {
  padding-bottom: 197px !important; }

.p-b-198 {
  padding-bottom: 198px !important; }

.p-b-199 {
  padding-bottom: 199px !important; }

.p-b-200 {
  padding-bottom: 200px !important; }

.p-b-201 {
  padding-bottom: 201px !important; }

.p-b-202 {
  padding-bottom: 202px !important; }

.p-b-203 {
  padding-bottom: 203px !important; }

.p-b-204 {
  padding-bottom: 204px !important; }

.p-b-205 {
  padding-bottom: 205px !important; }

.p-b-206 {
  padding-bottom: 206px !important; }

.p-b-207 {
  padding-bottom: 207px !important; }

.p-b-208 {
  padding-bottom: 208px !important; }

.p-b-209 {
  padding-bottom: 209px !important; }

.p-b-210 {
  padding-bottom: 210px !important; }

.p-b-211 {
  padding-bottom: 211px !important; }

.p-b-212 {
  padding-bottom: 212px !important; }

.p-b-213 {
  padding-bottom: 213px !important; }

.p-b-214 {
  padding-bottom: 214px !important; }

.p-b-215 {
  padding-bottom: 215px !important; }

.p-b-216 {
  padding-bottom: 216px !important; }

.p-b-217 {
  padding-bottom: 217px !important; }

.p-b-218 {
  padding-bottom: 218px !important; }

.p-b-219 {
  padding-bottom: 219px !important; }

.p-b-220 {
  padding-bottom: 220px !important; }

.p-b-221 {
  padding-bottom: 221px !important; }

.p-b-222 {
  padding-bottom: 222px !important; }

.p-b-223 {
  padding-bottom: 223px !important; }

.p-b-224 {
  padding-bottom: 224px !important; }

.p-b-225 {
  padding-bottom: 225px !important; }

.p-b-226 {
  padding-bottom: 226px !important; }

.p-b-227 {
  padding-bottom: 227px !important; }

.p-b-228 {
  padding-bottom: 228px !important; }

.p-b-229 {
  padding-bottom: 229px !important; }

.p-b-230 {
  padding-bottom: 230px !important; }

.p-b-231 {
  padding-bottom: 231px !important; }

.p-b-232 {
  padding-bottom: 232px !important; }

.p-b-233 {
  padding-bottom: 233px !important; }

.p-b-234 {
  padding-bottom: 234px !important; }

.p-b-235 {
  padding-bottom: 235px !important; }

.p-b-236 {
  padding-bottom: 236px !important; }

.p-b-237 {
  padding-bottom: 237px !important; }

.p-b-238 {
  padding-bottom: 238px !important; }

.p-b-239 {
  padding-bottom: 239px !important; }

.p-b-240 {
  padding-bottom: 240px !important; }

.p-b-241 {
  padding-bottom: 241px !important; }

.p-b-242 {
  padding-bottom: 242px !important; }

.p-b-243 {
  padding-bottom: 243px !important; }

.p-b-244 {
  padding-bottom: 244px !important; }

.p-b-245 {
  padding-bottom: 245px !important; }

.p-b-246 {
  padding-bottom: 246px !important; }

.p-b-247 {
  padding-bottom: 247px !important; }

.p-b-248 {
  padding-bottom: 248px !important; }

.p-b-249 {
  padding-bottom: 249px !important; }

.p-b-250 {
  padding-bottom: 250px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.p-l-1 {
  padding-left: 1px !important; }

.p-l-2 {
  padding-left: 2px !important; }

.p-l-3 {
  padding-left: 3px !important; }

.p-l-4 {
  padding-left: 4px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.p-l-6 {
  padding-left: 6px !important; }

.p-l-7 {
  padding-left: 7px !important; }

.p-l-8 {
  padding-left: 8px !important; }

.p-l-9 {
  padding-left: 9px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.p-l-11 {
  padding-left: 11px !important; }

.p-l-12 {
  padding-left: 12px !important; }

.p-l-13 {
  padding-left: 13px !important; }

.p-l-14 {
  padding-left: 14px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.p-l-16 {
  padding-left: 16px !important; }

.p-l-17 {
  padding-left: 17px !important; }

.p-l-18 {
  padding-left: 18px !important; }

.p-l-19 {
  padding-left: 19px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.p-l-21 {
  padding-left: 21px !important; }

.p-l-22 {
  padding-left: 22px !important; }

.p-l-23 {
  padding-left: 23px !important; }

.p-l-24 {
  padding-left: 24px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.p-l-26 {
  padding-left: 26px !important; }

.p-l-27 {
  padding-left: 27px !important; }

.p-l-28 {
  padding-left: 28px !important; }

.p-l-29 {
  padding-left: 29px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.p-l-31 {
  padding-left: 31px !important; }

.p-l-32 {
  padding-left: 32px !important; }

.p-l-33 {
  padding-left: 33px !important; }

.p-l-34 {
  padding-left: 34px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.p-l-36 {
  padding-left: 36px !important; }

.p-l-37 {
  padding-left: 37px !important; }

.p-l-38 {
  padding-left: 38px !important; }

.p-l-39 {
  padding-left: 39px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.p-l-41 {
  padding-left: 41px !important; }

.p-l-42 {
  padding-left: 42px !important; }

.p-l-43 {
  padding-left: 43px !important; }

.p-l-44 {
  padding-left: 44px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.p-l-46 {
  padding-left: 46px !important; }

.p-l-47 {
  padding-left: 47px !important; }

.p-l-48 {
  padding-left: 48px !important; }

.p-l-49 {
  padding-left: 49px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.p-l-51 {
  padding-left: 51px !important; }

.p-l-52 {
  padding-left: 52px !important; }

.p-l-53 {
  padding-left: 53px !important; }

.p-l-54 {
  padding-left: 54px !important; }

.p-l-55 {
  padding-left: 55px !important; }

.p-l-56 {
  padding-left: 56px !important; }

.p-l-57 {
  padding-left: 57px !important; }

.p-l-58 {
  padding-left: 58px !important; }

.p-l-59 {
  padding-left: 59px !important; }

.p-l-60 {
  padding-left: 60px !important; }

.p-l-61 {
  padding-left: 61px !important; }

.p-l-62 {
  padding-left: 62px !important; }

.p-l-63 {
  padding-left: 63px !important; }

.p-l-64 {
  padding-left: 64px !important; }

.p-l-65 {
  padding-left: 65px !important; }

.p-l-66 {
  padding-left: 66px !important; }

.p-l-67 {
  padding-left: 67px !important; }

.p-l-68 {
  padding-left: 68px !important; }

.p-l-69 {
  padding-left: 69px !important; }

.p-l-70 {
  padding-left: 70px !important; }

.p-l-71 {
  padding-left: 71px !important; }

.p-l-72 {
  padding-left: 72px !important; }

.p-l-73 {
  padding-left: 73px !important; }

.p-l-74 {
  padding-left: 74px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.p-l-76 {
  padding-left: 76px !important; }

.p-l-77 {
  padding-left: 77px !important; }

.p-l-78 {
  padding-left: 78px !important; }

.p-l-79 {
  padding-left: 79px !important; }

.p-l-80 {
  padding-left: 80px !important; }

.p-l-81 {
  padding-left: 81px !important; }

.p-l-82 {
  padding-left: 82px !important; }

.p-l-83 {
  padding-left: 83px !important; }

.p-l-84 {
  padding-left: 84px !important; }

.p-l-85 {
  padding-left: 85px !important; }

.p-l-86 {
  padding-left: 86px !important; }

.p-l-87 {
  padding-left: 87px !important; }

.p-l-88 {
  padding-left: 88px !important; }

.p-l-89 {
  padding-left: 89px !important; }

.p-l-90 {
  padding-left: 90px !important; }

.p-l-91 {
  padding-left: 91px !important; }

.p-l-92 {
  padding-left: 92px !important; }

.p-l-93 {
  padding-left: 93px !important; }

.p-l-94 {
  padding-left: 94px !important; }

.p-l-95 {
  padding-left: 95px !important; }

.p-l-96 {
  padding-left: 96px !important; }

.p-l-97 {
  padding-left: 97px !important; }

.p-l-98 {
  padding-left: 98px !important; }

.p-l-99 {
  padding-left: 99px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.p-l-101 {
  padding-left: 101px !important; }

.p-l-102 {
  padding-left: 102px !important; }

.p-l-103 {
  padding-left: 103px !important; }

.p-l-104 {
  padding-left: 104px !important; }

.p-l-105 {
  padding-left: 105px !important; }

.p-l-106 {
  padding-left: 106px !important; }

.p-l-107 {
  padding-left: 107px !important; }

.p-l-108 {
  padding-left: 108px !important; }

.p-l-109 {
  padding-left: 109px !important; }

.p-l-110 {
  padding-left: 110px !important; }

.p-l-111 {
  padding-left: 111px !important; }

.p-l-112 {
  padding-left: 112px !important; }

.p-l-113 {
  padding-left: 113px !important; }

.p-l-114 {
  padding-left: 114px !important; }

.p-l-115 {
  padding-left: 115px !important; }

.p-l-116 {
  padding-left: 116px !important; }

.p-l-117 {
  padding-left: 117px !important; }

.p-l-118 {
  padding-left: 118px !important; }

.p-l-119 {
  padding-left: 119px !important; }

.p-l-120 {
  padding-left: 120px !important; }

.p-l-121 {
  padding-left: 121px !important; }

.p-l-122 {
  padding-left: 122px !important; }

.p-l-123 {
  padding-left: 123px !important; }

.p-l-124 {
  padding-left: 124px !important; }

.p-l-125 {
  padding-left: 125px !important; }

.p-l-126 {
  padding-left: 126px !important; }

.p-l-127 {
  padding-left: 127px !important; }

.p-l-128 {
  padding-left: 128px !important; }

.p-l-129 {
  padding-left: 129px !important; }

.p-l-130 {
  padding-left: 130px !important; }

.p-l-131 {
  padding-left: 131px !important; }

.p-l-132 {
  padding-left: 132px !important; }

.p-l-133 {
  padding-left: 133px !important; }

.p-l-134 {
  padding-left: 134px !important; }

.p-l-135 {
  padding-left: 135px !important; }

.p-l-136 {
  padding-left: 136px !important; }

.p-l-137 {
  padding-left: 137px !important; }

.p-l-138 {
  padding-left: 138px !important; }

.p-l-139 {
  padding-left: 139px !important; }

.p-l-140 {
  padding-left: 140px !important; }

.p-l-141 {
  padding-left: 141px !important; }

.p-l-142 {
  padding-left: 142px !important; }

.p-l-143 {
  padding-left: 143px !important; }

.p-l-144 {
  padding-left: 144px !important; }

.p-l-145 {
  padding-left: 145px !important; }

.p-l-146 {
  padding-left: 146px !important; }

.p-l-147 {
  padding-left: 147px !important; }

.p-l-148 {
  padding-left: 148px !important; }

.p-l-149 {
  padding-left: 149px !important; }

.p-l-150 {
  padding-left: 150px !important; }

.p-l-151 {
  padding-left: 151px !important; }

.p-l-152 {
  padding-left: 152px !important; }

.p-l-153 {
  padding-left: 153px !important; }

.p-l-154 {
  padding-left: 154px !important; }

.p-l-155 {
  padding-left: 155px !important; }

.p-l-156 {
  padding-left: 156px !important; }

.p-l-157 {
  padding-left: 157px !important; }

.p-l-158 {
  padding-left: 158px !important; }

.p-l-159 {
  padding-left: 159px !important; }

.p-l-160 {
  padding-left: 160px !important; }

.p-l-161 {
  padding-left: 161px !important; }

.p-l-162 {
  padding-left: 162px !important; }

.p-l-163 {
  padding-left: 163px !important; }

.p-l-164 {
  padding-left: 164px !important; }

.p-l-165 {
  padding-left: 165px !important; }

.p-l-166 {
  padding-left: 166px !important; }

.p-l-167 {
  padding-left: 167px !important; }

.p-l-168 {
  padding-left: 168px !important; }

.p-l-169 {
  padding-left: 169px !important; }

.p-l-170 {
  padding-left: 170px !important; }

.p-l-171 {
  padding-left: 171px !important; }

.p-l-172 {
  padding-left: 172px !important; }

.p-l-173 {
  padding-left: 173px !important; }

.p-l-174 {
  padding-left: 174px !important; }

.p-l-175 {
  padding-left: 175px !important; }

.p-l-176 {
  padding-left: 176px !important; }

.p-l-177 {
  padding-left: 177px !important; }

.p-l-178 {
  padding-left: 178px !important; }

.p-l-179 {
  padding-left: 179px !important; }

.p-l-180 {
  padding-left: 180px !important; }

.p-l-181 {
  padding-left: 181px !important; }

.p-l-182 {
  padding-left: 182px !important; }

.p-l-183 {
  padding-left: 183px !important; }

.p-l-184 {
  padding-left: 184px !important; }

.p-l-185 {
  padding-left: 185px !important; }

.p-l-186 {
  padding-left: 186px !important; }

.p-l-187 {
  padding-left: 187px !important; }

.p-l-188 {
  padding-left: 188px !important; }

.p-l-189 {
  padding-left: 189px !important; }

.p-l-190 {
  padding-left: 190px !important; }

.p-l-191 {
  padding-left: 191px !important; }

.p-l-192 {
  padding-left: 192px !important; }

.p-l-193 {
  padding-left: 193px !important; }

.p-l-194 {
  padding-left: 194px !important; }

.p-l-195 {
  padding-left: 195px !important; }

.p-l-196 {
  padding-left: 196px !important; }

.p-l-197 {
  padding-left: 197px !important; }

.p-l-198 {
  padding-left: 198px !important; }

.p-l-199 {
  padding-left: 199px !important; }

.p-l-200 {
  padding-left: 200px !important; }

.p-l-201 {
  padding-left: 201px !important; }

.p-l-202 {
  padding-left: 202px !important; }

.p-l-203 {
  padding-left: 203px !important; }

.p-l-204 {
  padding-left: 204px !important; }

.p-l-205 {
  padding-left: 205px !important; }

.p-l-206 {
  padding-left: 206px !important; }

.p-l-207 {
  padding-left: 207px !important; }

.p-l-208 {
  padding-left: 208px !important; }

.p-l-209 {
  padding-left: 209px !important; }

.p-l-210 {
  padding-left: 210px !important; }

.p-l-211 {
  padding-left: 211px !important; }

.p-l-212 {
  padding-left: 212px !important; }

.p-l-213 {
  padding-left: 213px !important; }

.p-l-214 {
  padding-left: 214px !important; }

.p-l-215 {
  padding-left: 215px !important; }

.p-l-216 {
  padding-left: 216px !important; }

.p-l-217 {
  padding-left: 217px !important; }

.p-l-218 {
  padding-left: 218px !important; }

.p-l-219 {
  padding-left: 219px !important; }

.p-l-220 {
  padding-left: 220px !important; }

.p-l-221 {
  padding-left: 221px !important; }

.p-l-222 {
  padding-left: 222px !important; }

.p-l-223 {
  padding-left: 223px !important; }

.p-l-224 {
  padding-left: 224px !important; }

.p-l-225 {
  padding-left: 225px !important; }

.p-l-226 {
  padding-left: 226px !important; }

.p-l-227 {
  padding-left: 227px !important; }

.p-l-228 {
  padding-left: 228px !important; }

.p-l-229 {
  padding-left: 229px !important; }

.p-l-230 {
  padding-left: 230px !important; }

.p-l-231 {
  padding-left: 231px !important; }

.p-l-232 {
  padding-left: 232px !important; }

.p-l-233 {
  padding-left: 233px !important; }

.p-l-234 {
  padding-left: 234px !important; }

.p-l-235 {
  padding-left: 235px !important; }

.p-l-236 {
  padding-left: 236px !important; }

.p-l-237 {
  padding-left: 237px !important; }

.p-l-238 {
  padding-left: 238px !important; }

.p-l-239 {
  padding-left: 239px !important; }

.p-l-240 {
  padding-left: 240px !important; }

.p-l-241 {
  padding-left: 241px !important; }

.p-l-242 {
  padding-left: 242px !important; }

.p-l-243 {
  padding-left: 243px !important; }

.p-l-244 {
  padding-left: 244px !important; }

.p-l-245 {
  padding-left: 245px !important; }

.p-l-246 {
  padding-left: 246px !important; }

.p-l-247 {
  padding-left: 247px !important; }

.p-l-248 {
  padding-left: 248px !important; }

.p-l-249 {
  padding-left: 249px !important; }

.p-l-250 {
  padding-left: 250px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.p-r-1 {
  padding-right: 1px !important; }

.p-r-2 {
  padding-right: 2px !important; }

.p-r-3 {
  padding-right: 3px !important; }

.p-r-4 {
  padding-right: 4px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.p-r-6 {
  padding-right: 6px !important; }

.p-r-7 {
  padding-right: 7px !important; }

.p-r-8 {
  padding-right: 8px !important; }

.p-r-9 {
  padding-right: 9px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.p-r-11 {
  padding-right: 11px !important; }

.p-r-12 {
  padding-right: 12px !important; }

.p-r-13 {
  padding-right: 13px !important; }

.p-r-14 {
  padding-right: 14px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.p-r-16 {
  padding-right: 16px !important; }

.p-r-17 {
  padding-right: 17px !important; }

.p-r-18 {
  padding-right: 18px !important; }

.p-r-19 {
  padding-right: 19px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.p-r-21 {
  padding-right: 21px !important; }

.p-r-22 {
  padding-right: 22px !important; }

.p-r-23 {
  padding-right: 23px !important; }

.p-r-24 {
  padding-right: 24px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.p-r-26 {
  padding-right: 26px !important; }

.p-r-27 {
  padding-right: 27px !important; }

.p-r-28 {
  padding-right: 28px !important; }

.p-r-29 {
  padding-right: 29px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.p-r-31 {
  padding-right: 31px !important; }

.p-r-32 {
  padding-right: 32px !important; }

.p-r-33 {
  padding-right: 33px !important; }

.p-r-34 {
  padding-right: 34px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.p-r-36 {
  padding-right: 36px !important; }

.p-r-37 {
  padding-right: 37px !important; }

.p-r-38 {
  padding-right: 38px !important; }

.p-r-39 {
  padding-right: 39px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.p-r-41 {
  padding-right: 41px !important; }

.p-r-42 {
  padding-right: 42px !important; }

.p-r-43 {
  padding-right: 43px !important; }

.p-r-44 {
  padding-right: 44px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.p-r-46 {
  padding-right: 46px !important; }

.p-r-47 {
  padding-right: 47px !important; }

.p-r-48 {
  padding-right: 48px !important; }

.p-r-49 {
  padding-right: 49px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.p-r-51 {
  padding-right: 51px !important; }

.p-r-52 {
  padding-right: 52px !important; }

.p-r-53 {
  padding-right: 53px !important; }

.p-r-54 {
  padding-right: 54px !important; }

.p-r-55 {
  padding-right: 55px !important; }

.p-r-56 {
  padding-right: 56px !important; }

.p-r-57 {
  padding-right: 57px !important; }

.p-r-58 {
  padding-right: 58px !important; }

.p-r-59 {
  padding-right: 59px !important; }

.p-r-60 {
  padding-right: 60px !important; }

.p-r-61 {
  padding-right: 61px !important; }

.p-r-62 {
  padding-right: 62px !important; }

.p-r-63 {
  padding-right: 63px !important; }

.p-r-64 {
  padding-right: 64px !important; }

.p-r-65 {
  padding-right: 65px !important; }

.p-r-66 {
  padding-right: 66px !important; }

.p-r-67 {
  padding-right: 67px !important; }

.p-r-68 {
  padding-right: 68px !important; }

.p-r-69 {
  padding-right: 69px !important; }

.p-r-70 {
  padding-right: 70px !important; }

.p-r-71 {
  padding-right: 71px !important; }

.p-r-72 {
  padding-right: 72px !important; }

.p-r-73 {
  padding-right: 73px !important; }

.p-r-74 {
  padding-right: 74px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.p-r-76 {
  padding-right: 76px !important; }

.p-r-77 {
  padding-right: 77px !important; }

.p-r-78 {
  padding-right: 78px !important; }

.p-r-79 {
  padding-right: 79px !important; }

.p-r-80 {
  padding-right: 80px !important; }

.p-r-81 {
  padding-right: 81px !important; }

.p-r-82 {
  padding-right: 82px !important; }

.p-r-83 {
  padding-right: 83px !important; }

.p-r-84 {
  padding-right: 84px !important; }

.p-r-85 {
  padding-right: 85px !important; }

.p-r-86 {
  padding-right: 86px !important; }

.p-r-87 {
  padding-right: 87px !important; }

.p-r-88 {
  padding-right: 88px !important; }

.p-r-89 {
  padding-right: 89px !important; }

.p-r-90 {
  padding-right: 90px !important; }

.p-r-91 {
  padding-right: 91px !important; }

.p-r-92 {
  padding-right: 92px !important; }

.p-r-93 {
  padding-right: 93px !important; }

.p-r-94 {
  padding-right: 94px !important; }

.p-r-95 {
  padding-right: 95px !important; }

.p-r-96 {
  padding-right: 96px !important; }

.p-r-97 {
  padding-right: 97px !important; }

.p-r-98 {
  padding-right: 98px !important; }

.p-r-99 {
  padding-right: 99px !important; }

.p-r-100 {
  padding-right: 100px !important; }

.p-r-101 {
  padding-right: 101px !important; }

.p-r-102 {
  padding-right: 102px !important; }

.p-r-103 {
  padding-right: 103px !important; }

.p-r-104 {
  padding-right: 104px !important; }

.p-r-105 {
  padding-right: 105px !important; }

.p-r-106 {
  padding-right: 106px !important; }

.p-r-107 {
  padding-right: 107px !important; }

.p-r-108 {
  padding-right: 108px !important; }

.p-r-109 {
  padding-right: 109px !important; }

.p-r-110 {
  padding-right: 110px !important; }

.p-r-111 {
  padding-right: 111px !important; }

.p-r-112 {
  padding-right: 112px !important; }

.p-r-113 {
  padding-right: 113px !important; }

.p-r-114 {
  padding-right: 114px !important; }

.p-r-115 {
  padding-right: 115px !important; }

.p-r-116 {
  padding-right: 116px !important; }

.p-r-117 {
  padding-right: 117px !important; }

.p-r-118 {
  padding-right: 118px !important; }

.p-r-119 {
  padding-right: 119px !important; }

.p-r-120 {
  padding-right: 120px !important; }

.p-r-121 {
  padding-right: 121px !important; }

.p-r-122 {
  padding-right: 122px !important; }

.p-r-123 {
  padding-right: 123px !important; }

.p-r-124 {
  padding-right: 124px !important; }

.p-r-125 {
  padding-right: 125px !important; }

.p-r-126 {
  padding-right: 126px !important; }

.p-r-127 {
  padding-right: 127px !important; }

.p-r-128 {
  padding-right: 128px !important; }

.p-r-129 {
  padding-right: 129px !important; }

.p-r-130 {
  padding-right: 130px !important; }

.p-r-131 {
  padding-right: 131px !important; }

.p-r-132 {
  padding-right: 132px !important; }

.p-r-133 {
  padding-right: 133px !important; }

.p-r-134 {
  padding-right: 134px !important; }

.p-r-135 {
  padding-right: 135px !important; }

.p-r-136 {
  padding-right: 136px !important; }

.p-r-137 {
  padding-right: 137px !important; }

.p-r-138 {
  padding-right: 138px !important; }

.p-r-139 {
  padding-right: 139px !important; }

.p-r-140 {
  padding-right: 140px !important; }

.p-r-141 {
  padding-right: 141px !important; }

.p-r-142 {
  padding-right: 142px !important; }

.p-r-143 {
  padding-right: 143px !important; }

.p-r-144 {
  padding-right: 144px !important; }

.p-r-145 {
  padding-right: 145px !important; }

.p-r-146 {
  padding-right: 146px !important; }

.p-r-147 {
  padding-right: 147px !important; }

.p-r-148 {
  padding-right: 148px !important; }

.p-r-149 {
  padding-right: 149px !important; }

.p-r-150 {
  padding-right: 150px !important; }

.p-r-151 {
  padding-right: 151px !important; }

.p-r-152 {
  padding-right: 152px !important; }

.p-r-153 {
  padding-right: 153px !important; }

.p-r-154 {
  padding-right: 154px !important; }

.p-r-155 {
  padding-right: 155px !important; }

.p-r-156 {
  padding-right: 156px !important; }

.p-r-157 {
  padding-right: 157px !important; }

.p-r-158 {
  padding-right: 158px !important; }

.p-r-159 {
  padding-right: 159px !important; }

.p-r-160 {
  padding-right: 160px !important; }

.p-r-161 {
  padding-right: 161px !important; }

.p-r-162 {
  padding-right: 162px !important; }

.p-r-163 {
  padding-right: 163px !important; }

.p-r-164 {
  padding-right: 164px !important; }

.p-r-165 {
  padding-right: 165px !important; }

.p-r-166 {
  padding-right: 166px !important; }

.p-r-167 {
  padding-right: 167px !important; }

.p-r-168 {
  padding-right: 168px !important; }

.p-r-169 {
  padding-right: 169px !important; }

.p-r-170 {
  padding-right: 170px !important; }

.p-r-171 {
  padding-right: 171px !important; }

.p-r-172 {
  padding-right: 172px !important; }

.p-r-173 {
  padding-right: 173px !important; }

.p-r-174 {
  padding-right: 174px !important; }

.p-r-175 {
  padding-right: 175px !important; }

.p-r-176 {
  padding-right: 176px !important; }

.p-r-177 {
  padding-right: 177px !important; }

.p-r-178 {
  padding-right: 178px !important; }

.p-r-179 {
  padding-right: 179px !important; }

.p-r-180 {
  padding-right: 180px !important; }

.p-r-181 {
  padding-right: 181px !important; }

.p-r-182 {
  padding-right: 182px !important; }

.p-r-183 {
  padding-right: 183px !important; }

.p-r-184 {
  padding-right: 184px !important; }

.p-r-185 {
  padding-right: 185px !important; }

.p-r-186 {
  padding-right: 186px !important; }

.p-r-187 {
  padding-right: 187px !important; }

.p-r-188 {
  padding-right: 188px !important; }

.p-r-189 {
  padding-right: 189px !important; }

.p-r-190 {
  padding-right: 190px !important; }

.p-r-191 {
  padding-right: 191px !important; }

.p-r-192 {
  padding-right: 192px !important; }

.p-r-193 {
  padding-right: 193px !important; }

.p-r-194 {
  padding-right: 194px !important; }

.p-r-195 {
  padding-right: 195px !important; }

.p-r-196 {
  padding-right: 196px !important; }

.p-r-197 {
  padding-right: 197px !important; }

.p-r-198 {
  padding-right: 198px !important; }

.p-r-199 {
  padding-right: 199px !important; }

.p-r-200 {
  padding-right: 200px !important; }

.p-r-201 {
  padding-right: 201px !important; }

.p-r-202 {
  padding-right: 202px !important; }

.p-r-203 {
  padding-right: 203px !important; }

.p-r-204 {
  padding-right: 204px !important; }

.p-r-205 {
  padding-right: 205px !important; }

.p-r-206 {
  padding-right: 206px !important; }

.p-r-207 {
  padding-right: 207px !important; }

.p-r-208 {
  padding-right: 208px !important; }

.p-r-209 {
  padding-right: 209px !important; }

.p-r-210 {
  padding-right: 210px !important; }

.p-r-211 {
  padding-right: 211px !important; }

.p-r-212 {
  padding-right: 212px !important; }

.p-r-213 {
  padding-right: 213px !important; }

.p-r-214 {
  padding-right: 214px !important; }

.p-r-215 {
  padding-right: 215px !important; }

.p-r-216 {
  padding-right: 216px !important; }

.p-r-217 {
  padding-right: 217px !important; }

.p-r-218 {
  padding-right: 218px !important; }

.p-r-219 {
  padding-right: 219px !important; }

.p-r-220 {
  padding-right: 220px !important; }

.p-r-221 {
  padding-right: 221px !important; }

.p-r-222 {
  padding-right: 222px !important; }

.p-r-223 {
  padding-right: 223px !important; }

.p-r-224 {
  padding-right: 224px !important; }

.p-r-225 {
  padding-right: 225px !important; }

.p-r-226 {
  padding-right: 226px !important; }

.p-r-227 {
  padding-right: 227px !important; }

.p-r-228 {
  padding-right: 228px !important; }

.p-r-229 {
  padding-right: 229px !important; }

.p-r-230 {
  padding-right: 230px !important; }

.p-r-231 {
  padding-right: 231px !important; }

.p-r-232 {
  padding-right: 232px !important; }

.p-r-233 {
  padding-right: 233px !important; }

.p-r-234 {
  padding-right: 234px !important; }

.p-r-235 {
  padding-right: 235px !important; }

.p-r-236 {
  padding-right: 236px !important; }

.p-r-237 {
  padding-right: 237px !important; }

.p-r-238 {
  padding-right: 238px !important; }

.p-r-239 {
  padding-right: 239px !important; }

.p-r-240 {
  padding-right: 240px !important; }

.p-r-241 {
  padding-right: 241px !important; }

.p-r-242 {
  padding-right: 242px !important; }

.p-r-243 {
  padding-right: 243px !important; }

.p-r-244 {
  padding-right: 244px !important; }

.p-r-245 {
  padding-right: 245px !important; }

.p-r-246 {
  padding-right: 246px !important; }

.p-r-247 {
  padding-right: 247px !important; }

.p-r-248 {
  padding-right: 248px !important; }

.p-r-249 {
  padding-right: 249px !important; }

.p-r-250 {
  padding-right: 250px !important; }

/*[ MARGIN ]
///////////////////////////////////////////////////////////
*/
.m-t-0 {
  margin-top: 0px !important; }

.m-t-1 {
  margin-top: 1px !important; }

.m-t-2 {
  margin-top: 2px !important; }

.m-t-3 {
  margin-top: 3px !important; }

.m-t-4 {
  margin-top: 4px !important; }

.m-t-5 {
  margin-top: 5px !important; }

.m-t-6 {
  margin-top: 6px !important; }

.m-t-7 {
  margin-top: 7px !important; }

.m-t-8 {
  margin-top: 8px !important; }

.m-t-9 {
  margin-top: 9px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.m-t-11 {
  margin-top: 11px !important; }

.m-t-12 {
  margin-top: 12px !important; }

.m-t-13 {
  margin-top: 13px !important; }

.m-t-14 {
  margin-top: 14px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.m-t-16 {
  margin-top: 16px !important; }

.m-t-17 {
  margin-top: 17px !important; }

.m-t-18 {
  margin-top: 18px !important; }

.m-t-19 {
  margin-top: 19px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.m-t-21 {
  margin-top: 21px !important; }

.m-t-22 {
  margin-top: 22px !important; }

.m-t-23 {
  margin-top: 23px !important; }

.m-t-24 {
  margin-top: 24px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.m-t-26 {
  margin-top: 26px !important; }

.m-t-27 {
  margin-top: 27px !important; }

.m-t-28 {
  margin-top: 28px !important; }

.m-t-29 {
  margin-top: 29px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.m-t-31 {
  margin-top: 31px !important; }

.m-t-32 {
  margin-top: 32px !important; }

.m-t-33 {
  margin-top: 33px !important; }

.m-t-34 {
  margin-top: 34px !important; }

.m-t-35 {
  margin-top: 35px !important; }

.m-t-36 {
  margin-top: 36px !important; }

.m-t-37 {
  margin-top: 37px !important; }

.m-t-38 {
  margin-top: 38px !important; }

.m-t-39 {
  margin-top: 39px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.m-t-41 {
  margin-top: 41px !important; }

.m-t-42 {
  margin-top: 42px !important; }

.m-t-43 {
  margin-top: 43px !important; }

.m-t-44 {
  margin-top: 44px !important; }

.m-t-45 {
  margin-top: 45px !important; }

.m-t-46 {
  margin-top: 46px !important; }

.m-t-47 {
  margin-top: 47px !important; }

.m-t-48 {
  margin-top: 48px !important; }

.m-t-49 {
  margin-top: 49px !important; }

.m-t-50 {
  margin-top: 50px !important; }

.m-t-51 {
  margin-top: 51px !important; }

.m-t-52 {
  margin-top: 52px !important; }

.m-t-53 {
  margin-top: 53px !important; }

.m-t-54 {
  margin-top: 54px !important; }

.m-t-55 {
  margin-top: 55px !important; }

.m-t-56 {
  margin-top: 56px !important; }

.m-t-57 {
  margin-top: 57px !important; }

.m-t-58 {
  margin-top: 58px !important; }

.m-t-59 {
  margin-top: 59px !important; }

.m-t-60 {
  margin-top: 60px !important; }

.m-t-61 {
  margin-top: 61px !important; }

.m-t-62 {
  margin-top: 62px !important; }

.m-t-63 {
  margin-top: 63px !important; }

.m-t-64 {
  margin-top: 64px !important; }

.m-t-65 {
  margin-top: 65px !important; }

.m-t-66 {
  margin-top: 66px !important; }

.m-t-67 {
  margin-top: 67px !important; }

.m-t-68 {
  margin-top: 68px !important; }

.m-t-69 {
  margin-top: 69px !important; }

.m-t-70 {
  margin-top: 70px !important; }

.m-t-71 {
  margin-top: 71px !important; }

.m-t-72 {
  margin-top: 72px !important; }

.m-t-73 {
  margin-top: 73px !important; }

.m-t-74 {
  margin-top: 74px !important; }

.m-t-75 {
  margin-top: 75px !important; }

.m-t-76 {
  margin-top: 76px !important; }

.m-t-77 {
  margin-top: 77px !important; }

.m-t-78 {
  margin-top: 78px !important; }

.m-t-79 {
  margin-top: 79px !important; }

.m-t-80 {
  margin-top: 80px !important; }

.m-t-81 {
  margin-top: 81px !important; }

.m-t-82 {
  margin-top: 82px !important; }

.m-t-83 {
  margin-top: 83px !important; }

.m-t-84 {
  margin-top: 84px !important; }

.m-t-85 {
  margin-top: 85px !important; }

.m-t-86 {
  margin-top: 86px !important; }

.m-t-87 {
  margin-top: 87px !important; }

.m-t-88 {
  margin-top: 88px !important; }

.m-t-89 {
  margin-top: 89px !important; }

.m-t-90 {
  margin-top: 90px !important; }

.m-t-91 {
  margin-top: 91px !important; }

.m-t-92 {
  margin-top: 92px !important; }

.m-t-93 {
  margin-top: 93px !important; }

.m-t-94 {
  margin-top: 94px !important; }

.m-t-95 {
  margin-top: 95px !important; }

.m-t-96 {
  margin-top: 96px !important; }

.m-t-97 {
  margin-top: 97px !important; }

.m-t-98 {
  margin-top: 98px !important; }

.m-t-99 {
  margin-top: 99px !important; }

.m-t-100 {
  margin-top: 100px !important; }

.m-t-101 {
  margin-top: 101px !important; }

.m-t-102 {
  margin-top: 102px !important; }

.m-t-103 {
  margin-top: 103px !important; }

.m-t-104 {
  margin-top: 104px !important; }

.m-t-105 {
  margin-top: 105px !important; }

.m-t-106 {
  margin-top: 106px !important; }

.m-t-107 {
  margin-top: 107px !important; }

.m-t-108 {
  margin-top: 108px !important; }

.m-t-109 {
  margin-top: 109px !important; }

.m-t-110 {
  margin-top: 110px !important; }

.m-t-111 {
  margin-top: 111px !important; }

.m-t-112 {
  margin-top: 112px !important; }

.m-t-113 {
  margin-top: 113px !important; }

.m-t-114 {
  margin-top: 114px !important; }

.m-t-115 {
  margin-top: 115px !important; }

.m-t-116 {
  margin-top: 116px !important; }

.m-t-117 {
  margin-top: 117px !important; }

.m-t-118 {
  margin-top: 118px !important; }

.m-t-119 {
  margin-top: 119px !important; }

.m-t-120 {
  margin-top: 120px !important; }

.m-t-121 {
  margin-top: 121px !important; }

.m-t-122 {
  margin-top: 122px !important; }

.m-t-123 {
  margin-top: 123px !important; }

.m-t-124 {
  margin-top: 124px !important; }

.m-t-125 {
  margin-top: 125px !important; }

.m-t-126 {
  margin-top: 126px !important; }

.m-t-127 {
  margin-top: 127px !important; }

.m-t-128 {
  margin-top: 128px !important; }

.m-t-129 {
  margin-top: 129px !important; }

.m-t-130 {
  margin-top: 130px !important; }

.m-t-131 {
  margin-top: 131px !important; }

.m-t-132 {
  margin-top: 132px !important; }

.m-t-133 {
  margin-top: 133px !important; }

.m-t-134 {
  margin-top: 134px !important; }

.m-t-135 {
  margin-top: 135px !important; }

.m-t-136 {
  margin-top: 136px !important; }

.m-t-137 {
  margin-top: 137px !important; }

.m-t-138 {
  margin-top: 138px !important; }

.m-t-139 {
  margin-top: 139px !important; }

.m-t-140 {
  margin-top: 140px !important; }

.m-t-141 {
  margin-top: 141px !important; }

.m-t-142 {
  margin-top: 142px !important; }

.m-t-143 {
  margin-top: 143px !important; }

.m-t-144 {
  margin-top: 144px !important; }

.m-t-145 {
  margin-top: 145px !important; }

.m-t-146 {
  margin-top: 146px !important; }

.m-t-147 {
  margin-top: 147px !important; }

.m-t-148 {
  margin-top: 148px !important; }

.m-t-149 {
  margin-top: 149px !important; }

.m-t-150 {
  margin-top: 150px !important; }

.m-t-151 {
  margin-top: 151px !important; }

.m-t-152 {
  margin-top: 152px !important; }

.m-t-153 {
  margin-top: 153px !important; }

.m-t-154 {
  margin-top: 154px !important; }

.m-t-155 {
  margin-top: 155px !important; }

.m-t-156 {
  margin-top: 156px !important; }

.m-t-157 {
  margin-top: 157px !important; }

.m-t-158 {
  margin-top: 158px !important; }

.m-t-159 {
  margin-top: 159px !important; }

.m-t-160 {
  margin-top: 160px !important; }

.m-t-161 {
  margin-top: 161px !important; }

.m-t-162 {
  margin-top: 162px !important; }

.m-t-163 {
  margin-top: 163px !important; }

.m-t-164 {
  margin-top: 164px !important; }

.m-t-165 {
  margin-top: 165px !important; }

.m-t-166 {
  margin-top: 166px !important; }

.m-t-167 {
  margin-top: 167px !important; }

.m-t-168 {
  margin-top: 168px !important; }

.m-t-169 {
  margin-top: 169px !important; }

.m-t-170 {
  margin-top: 170px !important; }

.m-t-171 {
  margin-top: 171px !important; }

.m-t-172 {
  margin-top: 172px !important; }

.m-t-173 {
  margin-top: 173px !important; }

.m-t-174 {
  margin-top: 174px !important; }

.m-t-175 {
  margin-top: 175px !important; }

.m-t-176 {
  margin-top: 176px !important; }

.m-t-177 {
  margin-top: 177px !important; }

.m-t-178 {
  margin-top: 178px !important; }

.m-t-179 {
  margin-top: 179px !important; }

.m-t-180 {
  margin-top: 180px !important; }

.m-t-181 {
  margin-top: 181px !important; }

.m-t-182 {
  margin-top: 182px !important; }

.m-t-183 {
  margin-top: 183px !important; }

.m-t-184 {
  margin-top: 184px !important; }

.m-t-185 {
  margin-top: 185px !important; }

.m-t-186 {
  margin-top: 186px !important; }

.m-t-187 {
  margin-top: 187px !important; }

.m-t-188 {
  margin-top: 188px !important; }

.m-t-189 {
  margin-top: 189px !important; }

.m-t-190 {
  margin-top: 190px !important; }

.m-t-191 {
  margin-top: 191px !important; }

.m-t-192 {
  margin-top: 192px !important; }

.m-t-193 {
  margin-top: 193px !important; }

.m-t-194 {
  margin-top: 194px !important; }

.m-t-195 {
  margin-top: 195px !important; }

.m-t-196 {
  margin-top: 196px !important; }

.m-t-197 {
  margin-top: 197px !important; }

.m-t-198 {
  margin-top: 198px !important; }

.m-t-199 {
  margin-top: 199px !important; }

.m-t-200 {
  margin-top: 200px !important; }

.m-t-201 {
  margin-top: 201px !important; }

.m-t-202 {
  margin-top: 202px !important; }

.m-t-203 {
  margin-top: 203px !important; }

.m-t-204 {
  margin-top: 204px !important; }

.m-t-205 {
  margin-top: 205px !important; }

.m-t-206 {
  margin-top: 206px !important; }

.m-t-207 {
  margin-top: 207px !important; }

.m-t-208 {
  margin-top: 208px !important; }

.m-t-209 {
  margin-top: 209px !important; }

.m-t-210 {
  margin-top: 210px !important; }

.m-t-211 {
  margin-top: 211px !important; }

.m-t-212 {
  margin-top: 212px !important; }

.m-t-213 {
  margin-top: 213px !important; }

.m-t-214 {
  margin-top: 214px !important; }

.m-t-215 {
  margin-top: 215px !important; }

.m-t-216 {
  margin-top: 216px !important; }

.m-t-217 {
  margin-top: 217px !important; }

.m-t-218 {
  margin-top: 218px !important; }

.m-t-219 {
  margin-top: 219px !important; }

.m-t-220 {
  margin-top: 220px !important; }

.m-t-221 {
  margin-top: 221px !important; }

.m-t-222 {
  margin-top: 222px !important; }

.m-t-223 {
  margin-top: 223px !important; }

.m-t-224 {
  margin-top: 224px !important; }

.m-t-225 {
  margin-top: 225px !important; }

.m-t-226 {
  margin-top: 226px !important; }

.m-t-227 {
  margin-top: 227px !important; }

.m-t-228 {
  margin-top: 228px !important; }

.m-t-229 {
  margin-top: 229px !important; }

.m-t-230 {
  margin-top: 230px !important; }

.m-t-231 {
  margin-top: 231px !important; }

.m-t-232 {
  margin-top: 232px !important; }

.m-t-233 {
  margin-top: 233px !important; }

.m-t-234 {
  margin-top: 234px !important; }

.m-t-235 {
  margin-top: 235px !important; }

.m-t-236 {
  margin-top: 236px !important; }

.m-t-237 {
  margin-top: 237px !important; }

.m-t-238 {
  margin-top: 238px !important; }

.m-t-239 {
  margin-top: 239px !important; }

.m-t-240 {
  margin-top: 240px !important; }

.m-t-241 {
  margin-top: 241px !important; }

.m-t-242 {
  margin-top: 242px !important; }

.m-t-243 {
  margin-top: 243px !important; }

.m-t-244 {
  margin-top: 244px !important; }

.m-t-245 {
  margin-top: 245px !important; }

.m-t-246 {
  margin-top: 246px !important; }

.m-t-247 {
  margin-top: 247px !important; }

.m-t-248 {
  margin-top: 248px !important; }

.m-t-249 {
  margin-top: 249px !important; }

.m-t-250 {
  margin-top: 250px !important; }

.m-b-0 {
  margin-bottom: 0px !important; }

.m-b-1 {
  margin-bottom: 1px !important; }

.m-b-2 {
  margin-bottom: 2px !important; }

.m-b-3 {
  margin-bottom: 3px !important; }

.m-b-4 {
  margin-bottom: 4px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.m-b-6 {
  margin-bottom: 6px !important; }

.m-b-7 {
  margin-bottom: 7px !important; }

.m-b-8 {
  margin-bottom: 8px !important; }

.m-b-9 {
  margin-bottom: 9px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.m-b-11 {
  margin-bottom: 11px !important; }

.m-b-12 {
  margin-bottom: 12px !important; }

.m-b-13 {
  margin-bottom: 13px !important; }

.m-b-14 {
  margin-bottom: 14px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.m-b-16 {
  margin-bottom: 16px !important; }

.m-b-17 {
  margin-bottom: 17px !important; }

.m-b-18 {
  margin-bottom: 18px !important; }

.m-b-19 {
  margin-bottom: 19px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.m-b-21 {
  margin-bottom: 21px !important; }

.m-b-22 {
  margin-bottom: 22px !important; }

.m-b-23 {
  margin-bottom: 23px !important; }

.m-b-24 {
  margin-bottom: 24px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.m-b-26 {
  margin-bottom: 26px !important; }

.m-b-27 {
  margin-bottom: 27px !important; }

.m-b-28 {
  margin-bottom: 28px !important; }

.m-b-29 {
  margin-bottom: 29px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.m-b-31 {
  margin-bottom: 31px !important; }

.m-b-32 {
  margin-bottom: 32px !important; }

.m-b-33 {
  margin-bottom: 33px !important; }

.m-b-34 {
  margin-bottom: 34px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.m-b-36 {
  margin-bottom: 36px !important; }

.m-b-37 {
  margin-bottom: 37px !important; }

.m-b-38 {
  margin-bottom: 38px !important; }

.m-b-39 {
  margin-bottom: 39px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.m-b-41 {
  margin-bottom: 41px !important; }

.m-b-42 {
  margin-bottom: 42px !important; }

.m-b-43 {
  margin-bottom: 43px !important; }

.m-b-44 {
  margin-bottom: 44px !important; }

.m-b-45 {
  margin-bottom: 45px !important; }

.m-b-46 {
  margin-bottom: 46px !important; }

.m-b-47 {
  margin-bottom: 47px !important; }

.m-b-48 {
  margin-bottom: 48px !important; }

.m-b-49 {
  margin-bottom: 49px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.m-b-51 {
  margin-bottom: 51px !important; }

.m-b-52 {
  margin-bottom: 52px !important; }

.m-b-53 {
  margin-bottom: 53px !important; }

.m-b-54 {
  margin-bottom: 54px !important; }

.m-b-55 {
  margin-bottom: 55px !important; }

.m-b-56 {
  margin-bottom: 56px !important; }

.m-b-57 {
  margin-bottom: 57px !important; }

.m-b-58 {
  margin-bottom: 58px !important; }

.m-b-59 {
  margin-bottom: 59px !important; }

.m-b-60 {
  margin-bottom: 60px !important; }

.m-b-61 {
  margin-bottom: 61px !important; }

.m-b-62 {
  margin-bottom: 62px !important; }

.m-b-63 {
  margin-bottom: 63px !important; }

.m-b-64 {
  margin-bottom: 64px !important; }

.m-b-65 {
  margin-bottom: 65px !important; }

.m-b-66 {
  margin-bottom: 66px !important; }

.m-b-67 {
  margin-bottom: 67px !important; }

.m-b-68 {
  margin-bottom: 68px !important; }

.m-b-69 {
  margin-bottom: 69px !important; }

.m-b-70 {
  margin-bottom: 70px !important; }

.m-b-71 {
  margin-bottom: 71px !important; }

.m-b-72 {
  margin-bottom: 72px !important; }

.m-b-73 {
  margin-bottom: 73px !important; }

.m-b-74 {
  margin-bottom: 74px !important; }

.m-b-75 {
  margin-bottom: 75px !important; }

.m-b-76 {
  margin-bottom: 76px !important; }

.m-b-77 {
  margin-bottom: 77px !important; }

.m-b-78 {
  margin-bottom: 78px !important; }

.m-b-79 {
  margin-bottom: 79px !important; }

.m-b-80 {
  margin-bottom: 80px !important; }

.m-b-81 {
  margin-bottom: 81px !important; }

.m-b-82 {
  margin-bottom: 82px !important; }

.m-b-83 {
  margin-bottom: 83px !important; }

.m-b-84 {
  margin-bottom: 84px !important; }

.m-b-85 {
  margin-bottom: 85px !important; }

.m-b-86 {
  margin-bottom: 86px !important; }

.m-b-87 {
  margin-bottom: 87px !important; }

.m-b-88 {
  margin-bottom: 88px !important; }

.m-b-89 {
  margin-bottom: 89px !important; }

.m-b-90 {
  margin-bottom: 90px !important; }

.m-b-91 {
  margin-bottom: 91px !important; }

.m-b-92 {
  margin-bottom: 92px !important; }

.m-b-93 {
  margin-bottom: 93px !important; }

.m-b-94 {
  margin-bottom: 94px !important; }

.m-b-95 {
  margin-bottom: 95px !important; }

.m-b-96 {
  margin-bottom: 96px !important; }

.m-b-97 {
  margin-bottom: 97px !important; }

.m-b-98 {
  margin-bottom: 98px !important; }

.m-b-99 {
  margin-bottom: 99px !important; }

.m-b-100 {
  margin-bottom: 100px !important; }

.m-b-101 {
  margin-bottom: 101px !important; }

.m-b-102 {
  margin-bottom: 102px !important; }

.m-b-103 {
  margin-bottom: 103px !important; }

.m-b-104 {
  margin-bottom: 104px !important; }

.m-b-105 {
  margin-bottom: 105px !important; }

.m-b-106 {
  margin-bottom: 106px !important; }

.m-b-107 {
  margin-bottom: 107px !important; }

.m-b-108 {
  margin-bottom: 108px !important; }

.m-b-109 {
  margin-bottom: 109px !important; }

.m-b-110 {
  margin-bottom: 110px !important; }

.m-b-111 {
  margin-bottom: 111px !important; }

.m-b-112 {
  margin-bottom: 112px !important; }

.m-b-113 {
  margin-bottom: 113px !important; }

.m-b-114 {
  margin-bottom: 114px !important; }

.m-b-115 {
  margin-bottom: 115px !important; }

.m-b-116 {
  margin-bottom: 116px !important; }

.m-b-117 {
  margin-bottom: 117px !important; }

.m-b-118 {
  margin-bottom: 118px !important; }

.m-b-119 {
  margin-bottom: 119px !important; }

.m-b-120 {
  margin-bottom: 120px !important; }

.m-b-121 {
  margin-bottom: 121px !important; }

.m-b-122 {
  margin-bottom: 122px !important; }

.m-b-123 {
  margin-bottom: 123px !important; }

.m-b-124 {
  margin-bottom: 124px !important; }

.m-b-125 {
  margin-bottom: 125px !important; }

.m-b-126 {
  margin-bottom: 126px !important; }

.m-b-127 {
  margin-bottom: 127px !important; }

.m-b-128 {
  margin-bottom: 128px !important; }

.m-b-129 {
  margin-bottom: 129px !important; }

.m-b-130 {
  margin-bottom: 130px !important; }

.m-b-131 {
  margin-bottom: 131px !important; }

.m-b-132 {
  margin-bottom: 132px !important; }

.m-b-133 {
  margin-bottom: 133px !important; }

.m-b-134 {
  margin-bottom: 134px !important; }

.m-b-135 {
  margin-bottom: 135px !important; }

.m-b-136 {
  margin-bottom: 136px !important; }

.m-b-137 {
  margin-bottom: 137px !important; }

.m-b-138 {
  margin-bottom: 138px !important; }

.m-b-139 {
  margin-bottom: 139px !important; }

.m-b-140 {
  margin-bottom: 140px !important; }

.m-b-141 {
  margin-bottom: 141px !important; }

.m-b-142 {
  margin-bottom: 142px !important; }

.m-b-143 {
  margin-bottom: 143px !important; }

.m-b-144 {
  margin-bottom: 144px !important; }

.m-b-145 {
  margin-bottom: 145px !important; }

.m-b-146 {
  margin-bottom: 146px !important; }

.m-b-147 {
  margin-bottom: 147px !important; }

.m-b-148 {
  margin-bottom: 148px !important; }

.m-b-149 {
  margin-bottom: 149px !important; }

.m-b-150 {
  margin-bottom: 150px !important; }

.m-b-151 {
  margin-bottom: 151px !important; }

.m-b-152 {
  margin-bottom: 152px !important; }

.m-b-153 {
  margin-bottom: 153px !important; }

.m-b-154 {
  margin-bottom: 154px !important; }

.m-b-155 {
  margin-bottom: 155px !important; }

.m-b-156 {
  margin-bottom: 156px !important; }

.m-b-157 {
  margin-bottom: 157px !important; }

.m-b-158 {
  margin-bottom: 158px !important; }

.m-b-159 {
  margin-bottom: 159px !important; }

.m-b-160 {
  margin-bottom: 160px !important; }

.m-b-161 {
  margin-bottom: 161px !important; }

.m-b-162 {
  margin-bottom: 162px !important; }

.m-b-163 {
  margin-bottom: 163px !important; }

.m-b-164 {
  margin-bottom: 164px !important; }

.m-b-165 {
  margin-bottom: 165px !important; }

.m-b-166 {
  margin-bottom: 166px !important; }

.m-b-167 {
  margin-bottom: 167px !important; }

.m-b-168 {
  margin-bottom: 168px !important; }

.m-b-169 {
  margin-bottom: 169px !important; }

.m-b-170 {
  margin-bottom: 170px !important; }

.m-b-171 {
  margin-bottom: 171px !important; }

.m-b-172 {
  margin-bottom: 172px !important; }

.m-b-173 {
  margin-bottom: 173px !important; }

.m-b-174 {
  margin-bottom: 174px !important; }

.m-b-175 {
  margin-bottom: 175px !important; }

.m-b-176 {
  margin-bottom: 176px !important; }

.m-b-177 {
  margin-bottom: 177px !important; }

.m-b-178 {
  margin-bottom: 178px !important; }

.m-b-179 {
  margin-bottom: 179px !important; }

.m-b-180 {
  margin-bottom: 180px !important; }

.m-b-181 {
  margin-bottom: 181px !important; }

.m-b-182 {
  margin-bottom: 182px !important; }

.m-b-183 {
  margin-bottom: 183px !important; }

.m-b-184 {
  margin-bottom: 184px !important; }

.m-b-185 {
  margin-bottom: 185px !important; }

.m-b-186 {
  margin-bottom: 186px !important; }

.m-b-187 {
  margin-bottom: 187px !important; }

.m-b-188 {
  margin-bottom: 188px !important; }

.m-b-189 {
  margin-bottom: 189px !important; }

.m-b-190 {
  margin-bottom: 190px !important; }

.m-b-191 {
  margin-bottom: 191px !important; }

.m-b-192 {
  margin-bottom: 192px !important; }

.m-b-193 {
  margin-bottom: 193px !important; }

.m-b-194 {
  margin-bottom: 194px !important; }

.m-b-195 {
  margin-bottom: 195px !important; }

.m-b-196 {
  margin-bottom: 196px !important; }

.m-b-197 {
  margin-bottom: 197px !important; }

.m-b-198 {
  margin-bottom: 198px !important; }

.m-b-199 {
  margin-bottom: 199px !important; }

.m-b-200 {
  margin-bottom: 200px !important; }

.m-b-201 {
  margin-bottom: 201px !important; }

.m-b-202 {
  margin-bottom: 202px !important; }

.m-b-203 {
  margin-bottom: 203px !important; }

.m-b-204 {
  margin-bottom: 204px !important; }

.m-b-205 {
  margin-bottom: 205px !important; }

.m-b-206 {
  margin-bottom: 206px !important; }

.m-b-207 {
  margin-bottom: 207px !important; }

.m-b-208 {
  margin-bottom: 208px !important; }

.m-b-209 {
  margin-bottom: 209px !important; }

.m-b-210 {
  margin-bottom: 210px !important; }

.m-b-211 {
  margin-bottom: 211px !important; }

.m-b-212 {
  margin-bottom: 212px !important; }

.m-b-213 {
  margin-bottom: 213px !important; }

.m-b-214 {
  margin-bottom: 214px !important; }

.m-b-215 {
  margin-bottom: 215px !important; }

.m-b-216 {
  margin-bottom: 216px !important; }

.m-b-217 {
  margin-bottom: 217px !important; }

.m-b-218 {
  margin-bottom: 218px !important; }

.m-b-219 {
  margin-bottom: 219px !important; }

.m-b-220 {
  margin-bottom: 220px !important; }

.m-b-221 {
  margin-bottom: 221px !important; }

.m-b-222 {
  margin-bottom: 222px !important; }

.m-b-223 {
  margin-bottom: 223px !important; }

.m-b-224 {
  margin-bottom: 224px !important; }

.m-b-225 {
  margin-bottom: 225px !important; }

.m-b-226 {
  margin-bottom: 226px !important; }

.m-b-227 {
  margin-bottom: 227px !important; }

.m-b-228 {
  margin-bottom: 228px !important; }

.m-b-229 {
  margin-bottom: 229px !important; }

.m-b-230 {
  margin-bottom: 230px !important; }

.m-b-231 {
  margin-bottom: 231px !important; }

.m-b-232 {
  margin-bottom: 232px !important; }

.m-b-233 {
  margin-bottom: 233px !important; }

.m-b-234 {
  margin-bottom: 234px !important; }

.m-b-235 {
  margin-bottom: 235px !important; }

.m-b-236 {
  margin-bottom: 236px !important; }

.m-b-237 {
  margin-bottom: 237px !important; }

.m-b-238 {
  margin-bottom: 238px !important; }

.m-b-239 {
  margin-bottom: 239px !important; }

.m-b-240 {
  margin-bottom: 240px !important; }

.m-b-241 {
  margin-bottom: 241px !important; }

.m-b-242 {
  margin-bottom: 242px !important; }

.m-b-243 {
  margin-bottom: 243px !important; }

.m-b-244 {
  margin-bottom: 244px !important; }

.m-b-245 {
  margin-bottom: 245px !important; }

.m-b-246 {
  margin-bottom: 246px !important; }

.m-b-247 {
  margin-bottom: 247px !important; }

.m-b-248 {
  margin-bottom: 248px !important; }

.m-b-249 {
  margin-bottom: 249px !important; }

.m-b-250 {
  margin-bottom: 250px !important; }

.m-l-0 {
  margin-left: 0px !important; }

.m-l-1 {
  margin-left: 1px !important; }

.m-l-2 {
  margin-left: 2px !important; }

.m-l-3 {
  margin-left: 3px !important; }

.m-l-4 {
  margin-left: 4px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.m-l-6 {
  margin-left: 6px !important; }

.m-l-7 {
  margin-left: 7px !important; }

.m-l-8 {
  margin-left: 8px !important; }

.m-l-9 {
  margin-left: 9px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.m-l-11 {
  margin-left: 11px !important; }

.m-l-12 {
  margin-left: 12px !important; }

.m-l-13 {
  margin-left: 13px !important; }

.m-l-14 {
  margin-left: 14px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.m-l-16 {
  margin-left: 16px !important; }

.m-l-17 {
  margin-left: 17px !important; }

.m-l-18 {
  margin-left: 18px !important; }

.m-l-19 {
  margin-left: 19px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.m-l-21 {
  margin-left: 21px !important; }

.m-l-22 {
  margin-left: 22px !important; }

.m-l-23 {
  margin-left: 23px !important; }

.m-l-24 {
  margin-left: 24px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.m-l-26 {
  margin-left: 26px !important; }

.m-l-27 {
  margin-left: 27px !important; }

.m-l-28 {
  margin-left: 28px !important; }

.m-l-29 {
  margin-left: 29px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.m-l-31 {
  margin-left: 31px !important; }

.m-l-32 {
  margin-left: 32px !important; }

.m-l-33 {
  margin-left: 33px !important; }

.m-l-34 {
  margin-left: 34px !important; }

.m-l-35 {
  margin-left: 35px !important; }

.m-l-36 {
  margin-left: 36px !important; }

.m-l-37 {
  margin-left: 37px !important; }

.m-l-38 {
  margin-left: 38px !important; }

.m-l-39 {
  margin-left: 39px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.m-l-41 {
  margin-left: 41px !important; }

.m-l-42 {
  margin-left: 42px !important; }

.m-l-43 {
  margin-left: 43px !important; }

.m-l-44 {
  margin-left: 44px !important; }

.m-l-45 {
  margin-left: 45px !important; }

.m-l-46 {
  margin-left: 46px !important; }

.m-l-47 {
  margin-left: 47px !important; }

.m-l-48 {
  margin-left: 48px !important; }

.m-l-49 {
  margin-left: 49px !important; }

.m-l-50 {
  margin-left: 50px !important; }

.m-l-51 {
  margin-left: 51px !important; }

.m-l-52 {
  margin-left: 52px !important; }

.m-l-53 {
  margin-left: 53px !important; }

.m-l-54 {
  margin-left: 54px !important; }

.m-l-55 {
  margin-left: 55px !important; }

.m-l-56 {
  margin-left: 56px !important; }

.m-l-57 {
  margin-left: 57px !important; }

.m-l-58 {
  margin-left: 58px !important; }

.m-l-59 {
  margin-left: 59px !important; }

.m-l-60 {
  margin-left: 60px !important; }

.m-l-61 {
  margin-left: 61px !important; }

.m-l-62 {
  margin-left: 62px !important; }

.m-l-63 {
  margin-left: 63px !important; }

.m-l-64 {
  margin-left: 64px !important; }

.m-l-65 {
  margin-left: 65px !important; }

.m-l-66 {
  margin-left: 66px !important; }

.m-l-67 {
  margin-left: 67px !important; }

.m-l-68 {
  margin-left: 68px !important; }

.m-l-69 {
  margin-left: 69px !important; }

.m-l-70 {
  margin-left: 70px !important; }

.m-l-71 {
  margin-left: 71px !important; }

.m-l-72 {
  margin-left: 72px !important; }

.m-l-73 {
  margin-left: 73px !important; }

.m-l-74 {
  margin-left: 74px !important; }

.m-l-75 {
  margin-left: 75px !important; }

.m-l-76 {
  margin-left: 76px !important; }

.m-l-77 {
  margin-left: 77px !important; }

.m-l-78 {
  margin-left: 78px !important; }

.m-l-79 {
  margin-left: 79px !important; }

.m-l-80 {
  margin-left: 80px !important; }

.m-l-81 {
  margin-left: 81px !important; }

.m-l-82 {
  margin-left: 82px !important; }

.m-l-83 {
  margin-left: 83px !important; }

.m-l-84 {
  margin-left: 84px !important; }

.m-l-85 {
  margin-left: 85px !important; }

.m-l-86 {
  margin-left: 86px !important; }

.m-l-87 {
  margin-left: 87px !important; }

.m-l-88 {
  margin-left: 88px !important; }

.m-l-89 {
  margin-left: 89px !important; }

.m-l-90 {
  margin-left: 90px !important; }

.m-l-91 {
  margin-left: 91px !important; }

.m-l-92 {
  margin-left: 92px !important; }

.m-l-93 {
  margin-left: 93px !important; }

.m-l-94 {
  margin-left: 94px !important; }

.m-l-95 {
  margin-left: 95px !important; }

.m-l-96 {
  margin-left: 96px !important; }

.m-l-97 {
  margin-left: 97px !important; }

.m-l-98 {
  margin-left: 98px !important; }

.m-l-99 {
  margin-left: 99px !important; }

.m-l-100 {
  margin-left: 100px !important; }

.m-l-101 {
  margin-left: 101px !important; }

.m-l-102 {
  margin-left: 102px !important; }

.m-l-103 {
  margin-left: 103px !important; }

.m-l-104 {
  margin-left: 104px !important; }

.m-l-105 {
  margin-left: 105px !important; }

.m-l-106 {
  margin-left: 106px !important; }

.m-l-107 {
  margin-left: 107px !important; }

.m-l-108 {
  margin-left: 108px !important; }

.m-l-109 {
  margin-left: 109px !important; }

.m-l-110 {
  margin-left: 110px !important; }

.m-l-111 {
  margin-left: 111px !important; }

.m-l-112 {
  margin-left: 112px !important; }

.m-l-113 {
  margin-left: 113px !important; }

.m-l-114 {
  margin-left: 114px !important; }

.m-l-115 {
  margin-left: 115px !important; }

.m-l-116 {
  margin-left: 116px !important; }

.m-l-117 {
  margin-left: 117px !important; }

.m-l-118 {
  margin-left: 118px !important; }

.m-l-119 {
  margin-left: 119px !important; }

.m-l-120 {
  margin-left: 120px !important; }

.m-l-121 {
  margin-left: 121px !important; }

.m-l-122 {
  margin-left: 122px !important; }

.m-l-123 {
  margin-left: 123px !important; }

.m-l-124 {
  margin-left: 124px !important; }

.m-l-125 {
  margin-left: 125px !important; }

.m-l-126 {
  margin-left: 126px !important; }

.m-l-127 {
  margin-left: 127px !important; }

.m-l-128 {
  margin-left: 128px !important; }

.m-l-129 {
  margin-left: 129px !important; }

.m-l-130 {
  margin-left: 130px !important; }

.m-l-131 {
  margin-left: 131px !important; }

.m-l-132 {
  margin-left: 132px !important; }

.m-l-133 {
  margin-left: 133px !important; }

.m-l-134 {
  margin-left: 134px !important; }

.m-l-135 {
  margin-left: 135px !important; }

.m-l-136 {
  margin-left: 136px !important; }

.m-l-137 {
  margin-left: 137px !important; }

.m-l-138 {
  margin-left: 138px !important; }

.m-l-139 {
  margin-left: 139px !important; }

.m-l-140 {
  margin-left: 140px !important; }

.m-l-141 {
  margin-left: 141px !important; }

.m-l-142 {
  margin-left: 142px !important; }

.m-l-143 {
  margin-left: 143px !important; }

.m-l-144 {
  margin-left: 144px !important; }

.m-l-145 {
  margin-left: 145px !important; }

.m-l-146 {
  margin-left: 146px !important; }

.m-l-147 {
  margin-left: 147px !important; }

.m-l-148 {
  margin-left: 148px !important; }

.m-l-149 {
  margin-left: 149px !important; }

.m-l-150 {
  margin-left: 150px !important; }

.m-l-151 {
  margin-left: 151px !important; }

.m-l-152 {
  margin-left: 152px !important; }

.m-l-153 {
  margin-left: 153px !important; }

.m-l-154 {
  margin-left: 154px !important; }

.m-l-155 {
  margin-left: 155px !important; }

.m-l-156 {
  margin-left: 156px !important; }

.m-l-157 {
  margin-left: 157px !important; }

.m-l-158 {
  margin-left: 158px !important; }

.m-l-159 {
  margin-left: 159px !important; }

.m-l-160 {
  margin-left: 160px !important; }

.m-l-161 {
  margin-left: 161px !important; }

.m-l-162 {
  margin-left: 162px !important; }

.m-l-163 {
  margin-left: 163px !important; }

.m-l-164 {
  margin-left: 164px !important; }

.m-l-165 {
  margin-left: 165px !important; }

.m-l-166 {
  margin-left: 166px !important; }

.m-l-167 {
  margin-left: 167px !important; }

.m-l-168 {
  margin-left: 168px !important; }

.m-l-169 {
  margin-left: 169px !important; }

.m-l-170 {
  margin-left: 170px !important; }

.m-l-171 {
  margin-left: 171px !important; }

.m-l-172 {
  margin-left: 172px !important; }

.m-l-173 {
  margin-left: 173px !important; }

.m-l-174 {
  margin-left: 174px !important; }

.m-l-175 {
  margin-left: 175px !important; }

.m-l-176 {
  margin-left: 176px !important; }

.m-l-177 {
  margin-left: 177px !important; }

.m-l-178 {
  margin-left: 178px !important; }

.m-l-179 {
  margin-left: 179px !important; }

.m-l-180 {
  margin-left: 180px !important; }

.m-l-181 {
  margin-left: 181px !important; }

.m-l-182 {
  margin-left: 182px !important; }

.m-l-183 {
  margin-left: 183px !important; }

.m-l-184 {
  margin-left: 184px !important; }

.m-l-185 {
  margin-left: 185px !important; }

.m-l-186 {
  margin-left: 186px !important; }

.m-l-187 {
  margin-left: 187px !important; }

.m-l-188 {
  margin-left: 188px !important; }

.m-l-189 {
  margin-left: 189px !important; }

.m-l-190 {
  margin-left: 190px !important; }

.m-l-191 {
  margin-left: 191px !important; }

.m-l-192 {
  margin-left: 192px !important; }

.m-l-193 {
  margin-left: 193px !important; }

.m-l-194 {
  margin-left: 194px !important; }

.m-l-195 {
  margin-left: 195px !important; }

.m-l-196 {
  margin-left: 196px !important; }

.m-l-197 {
  margin-left: 197px !important; }

.m-l-198 {
  margin-left: 198px !important; }

.m-l-199 {
  margin-left: 199px !important; }

.m-l-200 {
  margin-left: 200px !important; }

.m-l-201 {
  margin-left: 201px !important; }

.m-l-202 {
  margin-left: 202px !important; }

.m-l-203 {
  margin-left: 203px !important; }

.m-l-204 {
  margin-left: 204px !important; }

.m-l-205 {
  margin-left: 205px !important; }

.m-l-206 {
  margin-left: 206px !important; }

.m-l-207 {
  margin-left: 207px !important; }

.m-l-208 {
  margin-left: 208px !important; }

.m-l-209 {
  margin-left: 209px !important; }

.m-l-210 {
  margin-left: 210px !important; }

.m-l-211 {
  margin-left: 211px !important; }

.m-l-212 {
  margin-left: 212px !important; }

.m-l-213 {
  margin-left: 213px !important; }

.m-l-214 {
  margin-left: 214px !important; }

.m-l-215 {
  margin-left: 215px !important; }

.m-l-216 {
  margin-left: 216px !important; }

.m-l-217 {
  margin-left: 217px !important; }

.m-l-218 {
  margin-left: 218px !important; }

.m-l-219 {
  margin-left: 219px !important; }

.m-l-220 {
  margin-left: 220px !important; }

.m-l-221 {
  margin-left: 221px !important; }

.m-l-222 {
  margin-left: 222px !important; }

.m-l-223 {
  margin-left: 223px !important; }

.m-l-224 {
  margin-left: 224px !important; }

.m-l-225 {
  margin-left: 225px !important; }

.m-l-226 {
  margin-left: 226px !important; }

.m-l-227 {
  margin-left: 227px !important; }

.m-l-228 {
  margin-left: 228px !important; }

.m-l-229 {
  margin-left: 229px !important; }

.m-l-230 {
  margin-left: 230px !important; }

.m-l-231 {
  margin-left: 231px !important; }

.m-l-232 {
  margin-left: 232px !important; }

.m-l-233 {
  margin-left: 233px !important; }

.m-l-234 {
  margin-left: 234px !important; }

.m-l-235 {
  margin-left: 235px !important; }

.m-l-236 {
  margin-left: 236px !important; }

.m-l-237 {
  margin-left: 237px !important; }

.m-l-238 {
  margin-left: 238px !important; }

.m-l-239 {
  margin-left: 239px !important; }

.m-l-240 {
  margin-left: 240px !important; }

.m-l-241 {
  margin-left: 241px !important; }

.m-l-242 {
  margin-left: 242px !important; }

.m-l-243 {
  margin-left: 243px !important; }

.m-l-244 {
  margin-left: 244px !important; }

.m-l-245 {
  margin-left: 245px !important; }

.m-l-246 {
  margin-left: 246px !important; }

.m-l-247 {
  margin-left: 247px !important; }

.m-l-248 {
  margin-left: 248px !important; }

.m-l-249 {
  margin-left: 249px !important; }

.m-l-250 {
  margin-left: 250px !important; }

.m-r-0 {
  margin-right: 0px !important; }

.m-r-1 {
  margin-right: 1px !important; }

.m-r-2 {
  margin-right: 2px !important; }

.m-r-3 {
  margin-right: 3px !important; }

.m-r-4 {
  margin-right: 4px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.m-r-6 {
  margin-right: 6px !important; }

.m-r-7 {
  margin-right: 7px !important; }

.m-r-8 {
  margin-right: 8px !important; }

.m-r-9 {
  margin-right: 9px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.m-r-11 {
  margin-right: 11px !important; }

.m-r-12 {
  margin-right: 12px !important; }

.m-r-13 {
  margin-right: 13px !important; }

.m-r-14 {
  margin-right: 14px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.m-r-16 {
  margin-right: 16px !important; }

.m-r-17 {
  margin-right: 17px !important; }

.m-r-18 {
  margin-right: 18px !important; }

.m-r-19 {
  margin-right: 19px !important; }

.m-r-20 {
  margin-right: 20px !important; }

.m-r-21 {
  margin-right: 21px !important; }

.m-r-22 {
  margin-right: 22px !important; }

.m-r-23 {
  margin-right: 23px !important; }

.m-r-24 {
  margin-right: 24px !important; }

.m-r-25 {
  margin-right: 25px !important; }

.m-r-26 {
  margin-right: 26px !important; }

.m-r-27 {
  margin-right: 27px !important; }

.m-r-28 {
  margin-right: 28px !important; }

.m-r-29 {
  margin-right: 29px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.m-r-31 {
  margin-right: 31px !important; }

.m-r-32 {
  margin-right: 32px !important; }

.m-r-33 {
  margin-right: 33px !important; }

.m-r-34 {
  margin-right: 34px !important; }

.m-r-35 {
  margin-right: 35px !important; }

.m-r-36 {
  margin-right: 36px !important; }

.m-r-37 {
  margin-right: 37px !important; }

.m-r-38 {
  margin-right: 38px !important; }

.m-r-39 {
  margin-right: 39px !important; }

.m-r-40 {
  margin-right: 40px !important; }

.m-r-41 {
  margin-right: 41px !important; }

.m-r-42 {
  margin-right: 42px !important; }

.m-r-43 {
  margin-right: 43px !important; }

.m-r-44 {
  margin-right: 44px !important; }

.m-r-45 {
  margin-right: 45px !important; }

.m-r-46 {
  margin-right: 46px !important; }

.m-r-47 {
  margin-right: 47px !important; }

.m-r-48 {
  margin-right: 48px !important; }

.m-r-49 {
  margin-right: 49px !important; }

.m-r-50 {
  margin-right: 50px !important; }

.m-r-51 {
  margin-right: 51px !important; }

.m-r-52 {
  margin-right: 52px !important; }

.m-r-53 {
  margin-right: 53px !important; }

.m-r-54 {
  margin-right: 54px !important; }

.m-r-55 {
  margin-right: 55px !important; }

.m-r-56 {
  margin-right: 56px !important; }

.m-r-57 {
  margin-right: 57px !important; }

.m-r-58 {
  margin-right: 58px !important; }

.m-r-59 {
  margin-right: 59px !important; }

.m-r-60 {
  margin-right: 60px !important; }

.m-r-61 {
  margin-right: 61px !important; }

.m-r-62 {
  margin-right: 62px !important; }

.m-r-63 {
  margin-right: 63px !important; }

.m-r-64 {
  margin-right: 64px !important; }

.m-r-65 {
  margin-right: 65px !important; }

.m-r-66 {
  margin-right: 66px !important; }

.m-r-67 {
  margin-right: 67px !important; }

.m-r-68 {
  margin-right: 68px !important; }

.m-r-69 {
  margin-right: 69px !important; }

.m-r-70 {
  margin-right: 70px !important; }

.m-r-71 {
  margin-right: 71px !important; }

.m-r-72 {
  margin-right: 72px !important; }

.m-r-73 {
  margin-right: 73px !important; }

.m-r-74 {
  margin-right: 74px !important; }

.m-r-75 {
  margin-right: 75px !important; }

.m-r-76 {
  margin-right: 76px !important; }

.m-r-77 {
  margin-right: 77px !important; }

.m-r-78 {
  margin-right: 78px !important; }

.m-r-79 {
  margin-right: 79px !important; }

.m-r-80 {
  margin-right: 80px !important; }

.m-r-81 {
  margin-right: 81px !important; }

.m-r-82 {
  margin-right: 82px !important; }

.m-r-83 {
  margin-right: 83px !important; }

.m-r-84 {
  margin-right: 84px !important; }

.m-r-85 {
  margin-right: 85px !important; }

.m-r-86 {
  margin-right: 86px !important; }

.m-r-87 {
  margin-right: 87px !important; }

.m-r-88 {
  margin-right: 88px !important; }

.m-r-89 {
  margin-right: 89px !important; }

.m-r-90 {
  margin-right: 90px !important; }

.m-r-91 {
  margin-right: 91px !important; }

.m-r-92 {
  margin-right: 92px !important; }

.m-r-93 {
  margin-right: 93px !important; }

.m-r-94 {
  margin-right: 94px !important; }

.m-r-95 {
  margin-right: 95px !important; }

.m-r-96 {
  margin-right: 96px !important; }

.m-r-97 {
  margin-right: 97px !important; }

.m-r-98 {
  margin-right: 98px !important; }

.m-r-99 {
  margin-right: 99px !important; }

.m-r-100 {
  margin-right: 100px !important; }

.m-r-101 {
  margin-right: 101px !important; }

.m-r-102 {
  margin-right: 102px !important; }

.m-r-103 {
  margin-right: 103px !important; }

.m-r-104 {
  margin-right: 104px !important; }

.m-r-105 {
  margin-right: 105px !important; }

.m-r-106 {
  margin-right: 106px !important; }

.m-r-107 {
  margin-right: 107px !important; }

.m-r-108 {
  margin-right: 108px !important; }

.m-r-109 {
  margin-right: 109px !important; }

.m-r-110 {
  margin-right: 110px !important; }

.m-r-111 {
  margin-right: 111px !important; }

.m-r-112 {
  margin-right: 112px !important; }

.m-r-113 {
  margin-right: 113px !important; }

.m-r-114 {
  margin-right: 114px !important; }

.m-r-115 {
  margin-right: 115px !important; }

.m-r-116 {
  margin-right: 116px !important; }

.m-r-117 {
  margin-right: 117px !important; }

.m-r-118 {
  margin-right: 118px !important; }

.m-r-119 {
  margin-right: 119px !important; }

.m-r-120 {
  margin-right: 120px !important; }

.m-r-121 {
  margin-right: 121px !important; }

.m-r-122 {
  margin-right: 122px !important; }

.m-r-123 {
  margin-right: 123px !important; }

.m-r-124 {
  margin-right: 124px !important; }

.m-r-125 {
  margin-right: 125px !important; }

.m-r-126 {
  margin-right: 126px !important; }

.m-r-127 {
  margin-right: 127px !important; }

.m-r-128 {
  margin-right: 128px !important; }

.m-r-129 {
  margin-right: 129px !important; }

.m-r-130 {
  margin-right: 130px !important; }

.m-r-131 {
  margin-right: 131px !important; }

.m-r-132 {
  margin-right: 132px !important; }

.m-r-133 {
  margin-right: 133px !important; }

.m-r-134 {
  margin-right: 134px !important; }

.m-r-135 {
  margin-right: 135px !important; }

.m-r-136 {
  margin-right: 136px !important; }

.m-r-137 {
  margin-right: 137px !important; }

.m-r-138 {
  margin-right: 138px !important; }

.m-r-139 {
  margin-right: 139px !important; }

.m-r-140 {
  margin-right: 140px !important; }

.m-r-141 {
  margin-right: 141px !important; }

.m-r-142 {
  margin-right: 142px !important; }

.m-r-143 {
  margin-right: 143px !important; }

.m-r-144 {
  margin-right: 144px !important; }

.m-r-145 {
  margin-right: 145px !important; }

.m-r-146 {
  margin-right: 146px !important; }

.m-r-147 {
  margin-right: 147px !important; }

.m-r-148 {
  margin-right: 148px !important; }

.m-r-149 {
  margin-right: 149px !important; }

.m-r-150 {
  margin-right: 150px !important; }

.m-r-151 {
  margin-right: 151px !important; }

.m-r-152 {
  margin-right: 152px !important; }

.m-r-153 {
  margin-right: 153px !important; }

.m-r-154 {
  margin-right: 154px !important; }

.m-r-155 {
  margin-right: 155px !important; }

.m-r-156 {
  margin-right: 156px !important; }

.m-r-157 {
  margin-right: 157px !important; }

.m-r-158 {
  margin-right: 158px !important; }

.m-r-159 {
  margin-right: 159px !important; }

.m-r-160 {
  margin-right: 160px !important; }

.m-r-161 {
  margin-right: 161px !important; }

.m-r-162 {
  margin-right: 162px !important; }

.m-r-163 {
  margin-right: 163px !important; }

.m-r-164 {
  margin-right: 164px !important; }

.m-r-165 {
  margin-right: 165px !important; }

.m-r-166 {
  margin-right: 166px !important; }

.m-r-167 {
  margin-right: 167px !important; }

.m-r-168 {
  margin-right: 168px !important; }

.m-r-169 {
  margin-right: 169px !important; }

.m-r-170 {
  margin-right: 170px !important; }

.m-r-171 {
  margin-right: 171px !important; }

.m-r-172 {
  margin-right: 172px !important; }

.m-r-173 {
  margin-right: 173px !important; }

.m-r-174 {
  margin-right: 174px !important; }

.m-r-175 {
  margin-right: 175px !important; }

.m-r-176 {
  margin-right: 176px !important; }

.m-r-177 {
  margin-right: 177px !important; }

.m-r-178 {
  margin-right: 178px !important; }

.m-r-179 {
  margin-right: 179px !important; }

.m-r-180 {
  margin-right: 180px !important; }

.m-r-181 {
  margin-right: 181px !important; }

.m-r-182 {
  margin-right: 182px !important; }

.m-r-183 {
  margin-right: 183px !important; }

.m-r-184 {
  margin-right: 184px !important; }

.m-r-185 {
  margin-right: 185px !important; }

.m-r-186 {
  margin-right: 186px !important; }

.m-r-187 {
  margin-right: 187px !important; }

.m-r-188 {
  margin-right: 188px !important; }

.m-r-189 {
  margin-right: 189px !important; }

.m-r-190 {
  margin-right: 190px !important; }

.m-r-191 {
  margin-right: 191px !important; }

.m-r-192 {
  margin-right: 192px !important; }

.m-r-193 {
  margin-right: 193px !important; }

.m-r-194 {
  margin-right: 194px !important; }

.m-r-195 {
  margin-right: 195px !important; }

.m-r-196 {
  margin-right: 196px !important; }

.m-r-197 {
  margin-right: 197px !important; }

.m-r-198 {
  margin-right: 198px !important; }

.m-r-199 {
  margin-right: 199px !important; }

.m-r-200 {
  margin-right: 200px !important; }

.m-r-201 {
  margin-right: 201px !important; }

.m-r-202 {
  margin-right: 202px !important; }

.m-r-203 {
  margin-right: 203px !important; }

.m-r-204 {
  margin-right: 204px !important; }

.m-r-205 {
  margin-right: 205px !important; }

.m-r-206 {
  margin-right: 206px !important; }

.m-r-207 {
  margin-right: 207px !important; }

.m-r-208 {
  margin-right: 208px !important; }

.m-r-209 {
  margin-right: 209px !important; }

.m-r-210 {
  margin-right: 210px !important; }

.m-r-211 {
  margin-right: 211px !important; }

.m-r-212 {
  margin-right: 212px !important; }

.m-r-213 {
  margin-right: 213px !important; }

.m-r-214 {
  margin-right: 214px !important; }

.m-r-215 {
  margin-right: 215px !important; }

.m-r-216 {
  margin-right: 216px !important; }

.m-r-217 {
  margin-right: 217px !important; }

.m-r-218 {
  margin-right: 218px !important; }

.m-r-219 {
  margin-right: 219px !important; }

.m-r-220 {
  margin-right: 220px !important; }

.m-r-221 {
  margin-right: 221px !important; }

.m-r-222 {
  margin-right: 222px !important; }

.m-r-223 {
  margin-right: 223px !important; }

.m-r-224 {
  margin-right: 224px !important; }

.m-r-225 {
  margin-right: 225px !important; }

.m-r-226 {
  margin-right: 226px !important; }

.m-r-227 {
  margin-right: 227px !important; }

.m-r-228 {
  margin-right: 228px !important; }

.m-r-229 {
  margin-right: 229px !important; }

.m-r-230 {
  margin-right: 230px !important; }

.m-r-231 {
  margin-right: 231px !important; }

.m-r-232 {
  margin-right: 232px !important; }

.m-r-233 {
  margin-right: 233px !important; }

.m-r-234 {
  margin-right: 234px !important; }

.m-r-235 {
  margin-right: 235px !important; }

.m-r-236 {
  margin-right: 236px !important; }

.m-r-237 {
  margin-right: 237px !important; }

.m-r-238 {
  margin-right: 238px !important; }

.m-r-239 {
  margin-right: 239px !important; }

.m-r-240 {
  margin-right: 240px !important; }

.m-r-241 {
  margin-right: 241px !important; }

.m-r-242 {
  margin-right: 242px !important; }

.m-r-243 {
  margin-right: 243px !important; }

.m-r-244 {
  margin-right: 244px !important; }

.m-r-245 {
  margin-right: 245px !important; }

.m-r-246 {
  margin-right: 246px !important; }

.m-r-247 {
  margin-right: 247px !important; }

.m-r-248 {
  margin-right: 248px !important; }

.m-r-249 {
  margin-right: 249px !important; }

.m-r-250 {
  margin-right: 250px !important; }

.m-l-r-auto {
  margin-left: auto;
  margin-right: auto !important; }

.m-l-auto {
  margin-left: auto !important; }

.m-r-auto {
  margin-right: auto !important; }

/*[ TEXT ]
///////////////////////////////////////////////////////////
*/
/* ------------------------------------ */
.text-white {
  color: white !important; }

.text-black {
  color: black !important; }

.text-hov-white:hover {
  color: white !important; }

/* ------------------------------------ */
.text-up {
  text-transform: uppercase !important; }

/* ------------------------------------ */
.text-center {
  text-align: center !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-middle {
  vertical-align: middle !important; }

/* ------------------------------------ */
.lh-1-0 {
  line-height: 1.0 !important; }

.lh-1-1 {
  line-height: 1.1 !important; }

.lh-1-2 {
  line-height: 1.2 !important; }

.lh-1-3 {
  line-height: 1.3 !important; }

.lh-1-4 {
  line-height: 1.4 !important; }

.lh-1-5 {
  line-height: 1.5 !important; }

.lh-1-6 {
  line-height: 1.6 !important; }

.lh-1-7 {
  line-height: 1.7 !important; }

.lh-1-8 {
  line-height: 1.8 !important; }

.lh-1-9 {
  line-height: 1.9 !important; }

.lh-2-0 {
  line-height: 2.0 !important; }

.lh-2-1 {
  line-height: 2.1 !important; }

.lh-2-2 {
  line-height: 2.2 !important; }

.lh-2-3 {
  line-height: 2.3 !important; }

.lh-2-4 {
  line-height: 2.4 !important; }

.lh-2-5 {
  line-height: 2.5 !important; }

.lh-2-6 {
  line-height: 2.6 !important; }

.lh-2-7 {
  line-height: 2.7 !important; }

.lh-2-8 {
  line-height: 2.8 !important; }

.lh-2-9 {
  line-height: 2.9 !important; }

/*[ SHAPE ]
///////////////////////////////////////////////////////////
*/
/*[ Display ]
-----------------------------------------------------------
*/
.dis-none {
  display: none !important; }

.dis-block {
  display: block !important; }

.dis-inline {
  display: inline !important; }

.dis-inline-block {
  display: inline-block !important; }

.dis-flex {
  display: flex; }

/*[ Position ]
-----------------------------------------------------------
*/
.pos-relative {
  position: relative !important; }

.pos-absolute {
  position: absolute !important; }

.pos-fixed {
  position: fixed !important; }

/*[ float ]
-----------------------------------------------------------
*/
.float-l {
  float: left !important; }

.float-r {
  float: right !important; }

/*[ Width & Height ]
-----------------------------------------------------------
*/
.sizefull {
  width: 100%;
  height: 100%; }

.w-full {
  width: 100% !important; }

.h-full {
  height: 100% !important; }

.max-w-full {
  max-width: 100% !important; }

.max-h-full {
  max-height: 100% !important; }

.min-w-full {
  min-width: 100% !important; }

.min-h-full {
  min-height: 100% !important; }

/*[ Top Bottom Left Right ]
-----------------------------------------------------------
*/
.top-0 {
  top: 0 !important; }

.bottom-0 {
  bottom: 0 !important; }

.left-0 {
  left: 0 !important; }

.right-0 {
  right: 0 !important; }

.top-auto {
  top: auto !important; }

.bottom-auto {
  bottom: auto !important; }

.left-auto {
  left: auto !important; }

.right-auto {
  right: auto !important; }

/*[ Opacity ]
-----------------------------------------------------------
*/
.op-0-0 {
  opacity: 0 !important; }

.op-0-1 {
  opacity: 0.1 !important; }

.op-0-2 {
  opacity: 0.2 !important; }

.op-0-3 {
  opacity: 0.3 !important; }

.op-0-4 {
  opacity: 0.4 !important; }

.op-0-5 {
  opacity: 0.5 !important; }

.op-0-6 {
  opacity: 0.6 !important; }

.op-0-7 {
  opacity: 0.7 !important; }

.op-0-8 {
  opacity: 0.8 !important; }

.op-0-9 {
  opacity: 0.9 !important; }

.op-1-0 {
  opacity: 1 !important; }

/*[ Background ]
-----------------------------------------------------------
*/
.bgwhite {
  background-color: white !important; }

.bgblack {
  background-color: black !important; }

/*[ Wrap Picture ]
-----------------------------------------------------------
*/
.wrap-pic-w img {
  width: 100% !important; }

.wrap-pic-max-w img {
  max-width: 100% !important; }

/* ------------------------------------ */
.wrap-pic-h img {
  height: 100% !important; }

.wrap-pic-max-h img {
  max-height: 100% !important; }

/* ------------------------------------ */
.wrap-pic-cir {
  border-radius: 50%;
  overflow: hidden; }

.wrap-pic-cir img {
  width: 100%; }

/*[ Hover ]
-----------------------------------------------------------
*/
.hov-pointer:hover {
  cursor: pointer !important; }

/* ------------------------------------ */
.hov-img-zoom {
  display: block;
  overflow: hidden; }

.hov-img-zoom img {
  width: 100%;
  transition: all 0.6s; }

.hov-img-zoom:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

/*[  ]
-----------------------------------------------------------
*/
.bo-cir {
  border-radius: 50% !important; }

.of-hidden {
  overflow: hidden !important; }

.visible-false {
  visibility: hidden !important; }

.visible-true {
  visibility: visible !important; }

/*[ Transition ]
-----------------------------------------------------------
*/
.trans-0-1 {
  transition: all 0.1s; }

.trans-0-2 {
  transition: all 0.2s; }

.trans-0-3 {
  transition: all 0.3s; }

.trans-0-4 {
  transition: all 0.4s; }

.trans-0-5 {
  transition: all 0.5s; }

.trans-0-6 {
  transition: all 0.6s; }

.trans-0-9 {
  transition: all 0.9s; }

.trans-1-0 {
  transition: all 1s; }

/*[ Layout ]
///////////////////////////////////////////////////////////
*/
/*[ Flex ]
-----------------------------------------------------------
*/
/* ------------------------------------ */
.flex-w {
  display: flex;
  -o-flex-wrap: wrap;
  flex-wrap: wrap; }

/* ------------------------------------ */
.flex-l {
  display: flex;
  justify-content: flex-start; }

.flex-r {
  display: flex;
  justify-content: flex-end; }

.flex-c {
  display: flex;
  justify-content: center; }

.flex-sa {
  display: flex;
  justify-content: space-around; }

.flex-sb {
  display: flex;
  justify-content: space-between; }

/* ------------------------------------ */
.flex-t {
  display: flex;
  -ms-align-items: flex-start;
  align-items: flex-start; }

.flex-b {
  display: flex;
  -ms-align-items: flex-end;
  align-items: flex-end; }

.flex-m {
  display: flex;
  -ms-align-items: center;
  align-items: center; }

.flex-str {
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch; }

/* ------------------------------------ */
.flex-row {
  display: flex;
  -moz-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row; }

.flex-row-rev {
  display: flex;
  -moz-flex-direction: row-reverse;
  -o-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.flex-col {
  display: flex;
  -moz-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column; }

.flex-col-rev {
  display: flex;
  -moz-flex-direction: column-reverse;
  -o-flex-direction: column-reverse;
  flex-direction: column-reverse; }

/* ------------------------------------ */
.flex-c-m {
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center; }

.flex-c-t {
  display: flex;
  justify-content: center;
  -ms-align-items: flex-start;
  align-items: flex-start; }

.flex-c-b {
  display: flex;
  justify-content: center;
  -ms-align-items: flex-end;
  align-items: flex-end; }

.flex-c-str {
  display: flex;
  justify-content: center;
  -ms-align-items: stretch;
  align-items: stretch; }

.flex-l-m {
  display: flex;
  justify-content: flex-start;
  -ms-align-items: center;
  align-items: center; }

.flex-r-m {
  display: flex;
  justify-content: flex-end;
  -ms-align-items: center;
  align-items: center; }

.flex-sa-m {
  display: flex;
  justify-content: space-around;
  -ms-align-items: center;
  align-items: center; }

.flex-sb-m {
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center; }

/* ------------------------------------ */
.flex-col-l {
  display: flex;
  -moz-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: flex-start;
  align-items: flex-start; }

.flex-col-r {
  display: flex;
  -moz-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: flex-end;
  align-items: flex-end; }

.flex-col-c {
  display: flex;
  -moz-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center; }

.flex-col-l-m {
  display: flex;
  -moz-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: flex-start;
  align-items: flex-start;
  justify-content: center; }

.flex-col-r-m {
  display: flex;
  -moz-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: flex-end;
  align-items: flex-end;
  justify-content: center; }

.flex-col-c-m {
  display: flex;
  -moz-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center; }

.flex-col-str {
  display: flex;
  -moz-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: stretch;
  align-items: stretch; }

.flex-col-sb {
  display: flex;
  -moz-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  justify-content: space-between; }

/* ------------------------------------ */
.flex-col-rev-l {
  display: flex;
  -moz-flex-direction: column-reverse;
  -o-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -ms-align-items: flex-start;
  align-items: flex-start; }

.flex-col-rev-r {
  display: flex;
  -moz-flex-direction: column-reverse;
  -o-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -ms-align-items: flex-end;
  align-items: flex-end; }

.flex-col-rev-c {
  display: flex;
  -moz-flex-direction: column-reverse;
  -o-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -ms-align-items: center;
  align-items: center; }

.flex-col-rev-str {
  display: flex;
  -moz-flex-direction: column-reverse;
  -o-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -ms-align-items: stretch;
  align-items: stretch; }

/*[ Absolute ]
-----------------------------------------------------------
*/
.ab-c-m {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.ab-c-t {
  position: absolute;
  top: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.ab-c-b {
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.ab-l-m {
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.ab-r-m {
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.ab-t-l {
  position: absolute;
  left: 0px;
  top: 0px; }

.ab-t-r {
  position: absolute;
  right: 0px;
  top: 0px; }

.ab-b-l {
  position: absolute;
  left: 0px;
  bottom: 0px; }

.ab-b-r {
  position: absolute;
  right: 0px;
  bottom: 0px; }

.limiter {
  /*---------------------------------------------*/
  /*---------------------------------------------*/
  /*---------------------------------------------*/
  /*---------------------------------------------*/
  /*//////////////////////////////////////////////////////////////////
  [ Utility ]*/
  /*//////////////////////////////////////////////////////////////////
  [ login ]*/
  /*------------------------------------------------------------------
  [ Form ]*/
  /*------------------------------------------------------------------
  [ Input ]*/
  /*---------------------------------------------*/
  /*---------------------------------------------*/
  /*------------------------------------------------------------------
  [ Button ]*/
  /*------------------------------------------------------------------
  [ Responsive ]*/
  /*------------------------------------------------------------------
  [ Alert validate ]*/ }
  .limiter .logo {
    width: 80px; }
  .limiter * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box; }
  .limiter body,
  .limiter html {
    height: 100%;
    font-family: Lato, sans-serif; }
  .limiter a {
    font-family: Lato;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s; }
  .limiter a:focus {
    outline: none !important; }
  .limiter .signupSelect select {
    font-family: Lato;
    font-size: 15px;
    color: #555555;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px;
    border: none;
    outline: none; }
  .limiter a:hover {
    text-decoration: none;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(left, #21d4fd, #b721ff); }
  .limiter h1,
  .limiter h2,
  .limiter h3,
  .limiter h4,
  .limiter h5,
  .limiter h6 {
    margin: 0px; }
  .limiter p {
    font-family: Lato;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px; }
  .limiter ul,
  .limiter li {
    margin: 0px;
    list-style-type: none; }
  .limiter input {
    outline: none;
    border: none; }
  .limiter textarea {
    outline: none;
    border: none; }
  .limiter textarea:focus,
  .limiter input:focus {
    border-color: transparent !important; }
  .limiter input:focus::-webkit-input-placeholder {
    color: transparent; }
  .limiter input:focus:-moz-placeholder {
    color: transparent; }
  .limiter input:focus::-moz-placeholder {
    color: transparent; }
  .limiter input:focus:-ms-input-placeholder {
    color: transparent; }
  .limiter textarea:focus::-webkit-input-placeholder {
    color: transparent; }
  .limiter textarea:focus:-moz-placeholder {
    color: transparent; }
  .limiter textarea:focus::-moz-placeholder {
    color: transparent; }
  .limiter textarea:focus:-ms-input-placeholder {
    color: transparent; }
  .limiter input::-webkit-input-placeholder {
    color: #adadad; }
  .limiter input:-moz-placeholder {
    color: #adadad; }
  .limiter input::-moz-placeholder {
    color: #adadad; }
  .limiter input:-ms-input-placeholder {
    color: #adadad; }
  .limiter textarea::-webkit-input-placeholder {
    color: #adadad; }
  .limiter textarea:-moz-placeholder {
    color: #adadad; }
  .limiter textarea::-moz-placeholder {
    color: #adadad; }
  .limiter textarea:-ms-input-placeholder {
    color: #adadad; }
  .limiter button {
    outline: none !important;
    border: none;
    background: transparent; }
  .limiter button:hover {
    cursor: pointer; }
  .limiter iframe {
    border: none !important; }
  .limiter .txt1 {
    font-family: Lato;
    font-size: 13px;
    color: #666666;
    line-height: 1.5; }
  .limiter .txt2 {
    font-family: Lato;
    font-size: 13px;
    color: #333333;
    line-height: 1.5; }
  .limiter .limiter {
    width: 100%;
    margin: 0 auto; }
  .limiter .container-login100 {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #f2f2f2; }
  .limiter .wrap-login100 {
    width: 390px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 40px 40px 40px 40px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1); }
  .limiter .login100-form {
    width: 100%; }
  .limiter .login100-form-title {
    display: block;
    font-family: Lato;
    font-size: 30px;
    color: #d40000;
    line-height: 1.2;
    text-align: center; }
  .limiter .login100-form-title i {
    font-size: 60px; }
  .limiter .wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px; }
  .limiter .input100 {
    font-family: Lato;
    font-size: 15px;
    color: #555555;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px; }
  .limiter .focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none; }
  .limiter .focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    transition: all 0.4s;
    background: -webkit-linear-gradient(left, #d40000, #001529); }
  .limiter .focus-input100::after {
    font-family: Lato;
    font-size: 15px;
    color: #999999;
    line-height: 1.2;
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: -15px;
    left: 0px;
    padding-left: 5px;
    transition: all 0.4s; }
  .limiter .input100:focus + .focus-input100::after {
    top: -15px; }
  .limiter .input100:focus + .focus-input100::before {
    width: 100%; }
  .limiter .has-val.input100 + .focus-input100::after {
    top: -15px; }
  .limiter .has-val.input100 + .focus-input100::before {
    width: 100%; }
  .limiter .btn-show-pass {
    font-size: 15px;
    color: #999999;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 5px;
    cursor: pointer;
    transition: all 0.4s; }
  .limiter .btn-show-pass:hover {
    color: #6a7dfe;
    color: -webkit-linear-gradient(left, #001529, #d40000);
    color: -o-linear-gradient(left, #001529, #d40000);
    color: -moz-linear-gradient(left, #001529, #d40000);
    color: -webkit-linear-gradient(left, #001529, #d40000); }
  .limiter .btn-show-pass.active {
    color: #6a7dfe;
    color: -webkit-linear-gradient(left, #001529, #d40000);
    color: -o-linear-gradient(left, #001529, #d40000);
    color: -moz-linear-gradient(left, #001529, #d40000);
    color: -webkit-linear-gradient(left, #001529, #d40000); }
  .limiter .container-login100-form-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px; }
  .limiter .wrap-login100-form-btn {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto; }
  .limiter .login100-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: -webkit-linear-gradient(right, #d400004f, #d40000, #d4000059, #d40000);
    top: 0;
    left: -100%;
    transition: all 0.4s; }
  .limiter .login100-form-btn {
    font-family: Lato;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px; }
  .limiter .wrap-login100-form-btn:hover .login100-form-bgbtn {
    left: 0; }
  @media (max-width: 576px) {
    .limiter .wrap-login100 {
      padding: 77px 15px 33px 15px; } }
  .limiter .validate-input {
    position: relative; }
  .limiter .alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px;
    pointer-events: none;
    font-family: Lato;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s; }
  .limiter .alert-validate::after {
    content: "\F06A";
    font-family: FontAwesome;
    font-size: 16px;
    color: #c80000;
    display: block;
    position: absolute;
    background-color: #fff;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px; }
  .limiter .alert-validate:hover:before {
    visibility: visible;
    opacity: 1; }
  @media (max-width: 992px) {
    .limiter .alert-validate::before {
      visibility: visible;
      opacity: 1; } }

.site-layout .site-layout-background {
  position: relative; }

.UserPermissions {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .UserPermissions table {
    width: 30%;
    margin-top: 30px; }
    .UserPermissions table th + th {
      width: 30%; }
    .UserPermissions table th {
      border-bottom: 1px solid rgba(128, 128, 128, 0.452);
      height: 30px; }
    .UserPermissions table td {
      border-bottom: 1px solid rgba(128, 128, 128, 0.295); }
  .UserPermissions .seperater {
    width: 1px;
    height: 100%;
    background-color: red; }
  .UserPermissions .buttonDiv {
    width: calc(100%);
    padding: 10px;
    background: white;
    position: -webkit-sticky;
    position: sticky;
    top: -24px;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    margin-left: -10px; }

.AddPrices .innerAddPrice {
  display: flex; }
  .AddPrices .innerAddPrice .tabsDiv {
    max-width: 210px;
    width: 100%;
    box-shadow: 2px 0px 2px -2px black; }
    .AddPrices .innerAddPrice .tabsDiv .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px;
      cursor: pointer;
      transition: 0.3s; }
      .AddPrices .innerAddPrice .tabsDiv .tab:hover {
        background: #80808014; }
      .AddPrices .innerAddPrice .tabsDiv .tab p {
        font-size: 12px;
        margin: 0%; }
      .AddPrices .innerAddPrice .tabsDiv .tab svg {
        font-size: 12px; }
  .AddPrices .innerAddPrice .priceForm {
    padding: 10px 0px 0px;
    padding-left: 50px; }
    .AddPrices .innerAddPrice .priceForm .fieldPrice {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      padding: 10px 0px; }
      .AddPrices .innerAddPrice .priceForm .fieldPrice h3 {
        margin-right: 20px; }
      .AddPrices .innerAddPrice .priceForm .fieldPrice input {
        margin-right: 10px; }
    .AddPrices .innerAddPrice .priceForm button {
      background-color: #ce0001;
      color: white;
      font-size: 12px;
      font-weight: bold;
      border: none;
      padding: 4px 15px;
      border-radius: 15px;
      box-shadow: 0 0 8px -2px black; }

.dashboardMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 50px; }
  .dashboardMain.limiter .div {
    display: flex;
    margin-right: 20px;
    margin-top: 20px; }
    .dashboardMain.limiter .div .dropdown-toggle::after {
      margin: 0; }
    .dashboardMain.limiter .div .dropdown-toggle svg {
      display: none; }
    .dashboardMain.limiter .div .dropdown-menu {
      position: absolute;
      top: 50%;
      background-color: white;
      border: 1px solid #00152936;
      z-index: 999;
      border-radius: 5px;
      font-size: 16px;
      font-weight: normal;
      width: 200px;
      display: none; }
      .dashboardMain.limiter .div .dropdown-menu.show {
        display: block; }
        .dashboardMain.limiter .div .dropdown-menu.show .dropdown-item {
          padding: 5px 20px;
          color: #001529; }
          .dashboardMain.limiter .div .dropdown-menu.show .dropdown-item:hover {
            background-color: #00152936; }
        .dashboardMain.limiter .div .dropdown-menu.show .dropdown-item + .dropdown-item {
          border-top: 1px solid #00152936; }
    .dashboardMain.limiter .div .wrap-login100-form-btn {
      width: 178px;
      height: 130px;
      border-radius: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      margin: 0; }
      .dashboardMain.limiter .div .wrap-login100-form-btn.full {
        width: 200px;
        border-radius: 5px; }
      .dashboardMain.limiter .div .wrap-login100-form-btn button {
        height: 100%; }
    .dashboardMain.limiter .div .second {
      height: 100%;
      width: 20px;
      background-color: #001529d2;
      color: white;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: bold;
      cursor: pointer;
      margin-left: 2px;
      position: relative; }
      .dashboardMain.limiter .div .second:hover {
        background-color: #001529; }
  .dashboardMain .singleDiv {
    display: flex;
    width: 150px;
    height: 100px;
    justify-content: space-between; }
    .dashboardMain .singleDiv .first {
      height: 100%;
      width: 80%;
      background-color: #001529;
      color: white;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: bold; }
    .dashboardMain .singleDiv .second {
      height: 100%;
      width: 19%;
      background-color: #001529;
      color: white;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: bold; }

@font-face {
  font-family: oldBookman;
  src: url(/static/media/BOOKOSB.e6ad3e94.TTF); }

.loaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center; }
  .loaderDiv img {
    width: 130px;
    -webkit-animation: imageLoader 3s infinite ease-in-out;
            animation: imageLoader 3s infinite ease-in-out; }
  .loaderDiv h1 {
    font-family: oldBookman;
    color: #d40000;
    font-size: 25px;
    -webkit-animation: textLoader 2s infinite ease-in-out;
            animation: textLoader 2s infinite ease-in-out; }

.dayLoader {
  position: absolute;
  width: 100%;
  height: 100%; }
  .dayLoader img {
    width: 60px; }

@-webkit-keyframes textLoader {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@keyframes textLoader {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@-webkit-keyframes imageLoader {
  0% {
    -webkit-transform: rotateY(1deg);
            transform: rotateY(1deg); }
  50% {
    -webkit-transform: rotateY(721deg);
            transform: rotateY(721deg); }
  100% {
    -webkit-transform: rotateY(1deg);
            transform: rotateY(1deg); } }

@keyframes imageLoader {
  0% {
    -webkit-transform: rotateY(1deg);
            transform: rotateY(1deg); }
  50% {
    -webkit-transform: rotateY(721deg);
            transform: rotateY(721deg); }
  100% {
    -webkit-transform: rotateY(1deg);
            transform: rotateY(1deg); } }

@font-face {
  font-family: Lato;
  src: url(/static/media/Lato-Regular.2d36b1a9.ttf);
}



html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Lato !important;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.ant-layout-has-sider .logo {
  height: 32px;
  margin: 16px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-has-sider .logo img {
  height: 100%;
}
.ant-layout-has-sider .logo h3 {
  color: white;
  margin: 0;
  margin-left: 10px;
}

.site-layout .site-layout-background {
  background: #fff;
}
.ant-layout-has-sider {
  height: 100vh;
}
.ant-layout-has-sider .anticon-menu-fold,
.ant-layout-has-sider .anticon-menu-unfold {
  padding: 10px;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-header {
  display: flex;
  text-transform: capitalize;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #001529;
  border-color: #001529;
}

.ant-checkbox-checked::after,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #001529;
}
@media print{
  #bigCertificate {
      zoom:75%
    }
      .hollograme{
        display: none;
      }
}
#bigCertificate{
  text-transform: capitalize;
}

