.reciptPage {
    padding: 24px;
    .innerRecieptPage {
        width: 100%;
        zoom: 90%;
        width: 100%;
        zoom: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .printRecipt {
            width: 47%;
            .headReciept {
                font-size: 16px;
                text-align: center;
            }
            .recieptDetail {
                display: flex;
                .table1 {
                    width: 60%;
                    height: fit-content;
                }
                .table2 {
                    width: 40%;
                }
                .customerDet {
                    th {
                        font-size: 11.4px;
                        font-weight: bold;
                    }
                    td {
                        font-size: 14.4px;
                    }
                }
                .table2 {
                    font-size: 14.4px;
                    td {
                        text-align: right;
                    }
                }
            }
        }
        .itemListRecipt {
            table {
                border-collapse: collapse;
                border: 1px solid black;
                width: 100%;
                font-size: 0.84em;
                th {
                    border: 1px solid black;
                    background: #b2b2b2;
                    font-size: 13.44px;
                    text-align: center;
                }
                td {
                    text-align: center;
                    font-size: 13.44px;
                }
                .td1 {
                    font-size: 0.9em;
                    font-style: italic;
                }
            }
        }
        .recipetTotalTable {
            width: 78%;
            float: right;
            font-size: 12.8px;
            margin-top: 15px;
            tr {
                th + th {
                    text-align: right;
                }
            }
        }
    }
}
.printReciptBtn {
    background: #d40000;
    color: white;
    border: none;
    padding: 5px 20px;
    font-weight: bold;
    border-radius: 25px;
    margin: 0px 10px;
}
.backReciptBtn{
    background: #41a4ff;
    color: white;
    border: none;
    padding: 5px 20px;
    font-weight: bold;
    border-radius: 25px;
    margin: 0px 10px;
    margin-right:5px ;
}
.reasonTh{
    font-size: 10px;
}
