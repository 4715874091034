.layoutHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pageNameDiv {
    display: flex;
    align-items: center;
    h3 {
      margin: 0px;
    }
  }
  .avatarAvatar {
    display: flex;
    align-items: center;
    padding: 0px 30px;
    h5 {
      margin: 0px;
      padding: 0px 10px;
    }
  }
  .stoneRemain {
    background-color: black;
    opacity: 0.7;
    color: white;
    width: 40%;
    text-align: center;
    border-radius: 15px;
    height: 40px;
    z-index: 2;
    overflow: hidden;
    transition: 0.3s;
    position: relative;
    top: -22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 15px -3px black;
    svg {
      margin-top: 10px;
      font-size: 20px;
    }
    .countDiv {
      display: none;

      // display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &.stoneRemainOpen {
      height: 200px;
      display: flex;
      top: 18px;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;

      svg {
        // display: none;

        transform: rotate(180deg);
      }

      .countDiv {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin-top: 40px;
        h5 {
          color: white;
          line-height: 22px;
          font-size: 20px;
          font-weight: bold;
        }
        p {
          color: rgb(3, 251, 3);
          line-height: 20px;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
  .notificationsDiv {
    margin: 0px 10px;
    position: relative;
    .notiBellButn {
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;
    }
    .notifications {
      position: absolute;
      right: -4px;
      background: white;
      z-index: 1;
      box-shadow: 0px 4px 12px -5px black;
      line-height: 23px;
      width: 70vw;
      max-height: 70vh;
      overflow: auto;
      z-index: 90;
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
        border-radius: 5px;
      }
      .innerNoti {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .notiheadDiv {
          z-index: 9999;
          width: 100%;
        }
      }
      .TabsNoti {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px 15px;
        .tabBadge {
          width: 23%;
          .ant-scroll-number {
            background: #e66a6a;
          }
        }
        .tab {
          width: 100%;
          text-align: center;
          border: 1px solid black;
          padding: 7px;
          border-radius: 10px;
          color: black;
          font-weight: bold;
          cursor: pointer;
          transition: 0.2s;
          padding: 10px 0px;
          &:hover {
            background: #e66a6a;
            color: white;
            border: 1px solid #e66a6a;
            box-shadow: 0px 0px 4px -1px black;
          }
        }
      }
      .notHead {
        font-weight: 600;
        padding: 10px 15px;
        background: white;
        box-shadow: 0px 1px 6px -2px grey;
        position: sticky;
        top: 0;
        width: 100%;
      }
      .notiDiv {
        transition: 0.2s;
        padding: 10px 15px;
        width: 49%;
        &:hover {
          background-color: #00000008;
        }
        .NotiInfo {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .notiName {
            h5 {
              font-weight: bold;
              padding: 0px;
              color: #d40000;
            }
          }
          .notiDate {
            p {
              font-size: 12px;
              font-weight: bold;
              color: rgb(153, 153, 153);
            }
            h6 {
              font-weight: bold;
              color: #1890ff;
              text-align: right;
            }
          }
        }
        h6,
        p,
        h5 {
          margin: 0px;
        }
        .innerNotiDetail {
          font-size: 12px;
          color: rgb(153, 153, 153);
          p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-weight: 700;
              color: black;
            }
          }
        }
        .notiButtons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0px 5px;
          button {
            width: 45%;
            text-align: center;
            border: 1px solid black;
            padding: 7px;
            border-radius: 10px;
            color: black;
            font-weight: bold;
            cursor: pointer;
            transition: 0.2s;
            background: white;
            &.loadButton {
              background: #e66a6a;
              color: white;
              border: 1px solid #e66a6a;
              box-shadow: 0px 0px 4px -1px black;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:hover {
              background: #e66a6a;
              color: white;
              border: 1px solid #e66a6a;
              box-shadow: 0px 0px 4px -1px black;
            }
          }
        }
      }
      hr {
        border: 0.1px solid #00000012;
        margin: 0px;
      }
      .loaderMore {
        border: 1px solid #001529;
        background: #001529;
        color: white;
        /* display: flex; */
        width: 20%;
        padding: 7px 10px;
        text-align: center;
        font-weight: bold;
        margin: 15px auto 20px auto;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          box-shadow: 0px 0px 11px -4px black;
          transform: scale(1.02);
        }
      }
    }
  }
}
.siderLay {
  .ant-menu-item-selected {
    background-color: #1890ff !important;
    svg {
      color: #fff !important;
    }
  }
  height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
  }

  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 50px;
  }
}
.ant-menu-submenu {
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
  }

  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 50px;
  }
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-dark.ant-menu-inline .ant-menu-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    margin-right: 12px;
  }
}
.layoutIcon {
  width: 21px;
  &.setting {
    margin-right: 5px;
  }
}
.dashbaordIcon {
  width: 22px;
  fill: #a6adb4;
}

.imageBox {
  display: flex;
  flex-direction: column;
  .box {
    height: 120px;
    width: 120px;
    border: 1px solid rgb(153, 153, 153);
    margin-bottom: 10px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    padding: 10px;
  }
  input {
    height: 35px;
    font-size: 12px;
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #00000066 !important;
  position: relative;
}

.notificationloader {
  margin: 20px 0px;
  position: unset !important;
  img {
    width: 70px !important;
  }
}
.NoDataText {
  font-weight: bold;
  color: #00000036;
}
.branchSelectStone {
  color: white !important;

  .ant-select-selector {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
}
.ant-layout-sider {
  box-shadow: black 4px 0px 9px -5px;
}
.ant-layout-header {
  box-shadow: 0px 1px 6px -3px black;
}
.ant-menu-title-content a{
  position: absolute;
  z-index: 1000;
  top: 0;
  left:0;
}
.tobeHide{
position: unset !important;
}
.tobehonest{
  position: relative;
  // color: yellow;
}
.site-layout .site-layout-background.ant-layout-content {
  box-shadow: 0px 0px 10px -6px black;
  border-radius: 20px;
  padding: 14px !important;
}
// .ant-layout {
//   // background: linear-gradient(131deg, rgba(250, 0, 0, 1) 0%, rgba(230, 162, 51, 1) 97%) !important;
//   background: rgb(253, 29, 29) !important;
//   background: linear-gradient(-121deg, #ff9700 0%, #f31b1b 37%, #1147c8 100%) !important;
// }

// .site-layout .site-layout-background {
//   background: linear-gradient(-121deg, #ff9700 0%, #f31b1b 37%, #1147c8 100%) !important;
// }
// .ant-layout-content.site-layout-background {
//   border-radius: 10px;
//   background: linear-gradient(-121deg, #ff9700 0%, #f31b1b 37%, #1147c8 100%) !important;
//   box-shadow: 0px 0px 7px -3px black;
// }
// .ant-layout-header.site-layout-background.layoutHeader {
//   padding: 0px;
//   background: #3f51b5 !important;
// }
// .siderLay {
//   background: #3f51b5 !important;
//   box-shadow: -21px 0px 14px 19px black !important;
//   z-index: 999 !important;
//   .ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical {
//     background: #3f51b5 !important;
//   }
// }
// .ant-select-selector,
// .ant-input-affix-wrapper-lg,
// input,
// .ant-input-group-addon,
// .ant-btn.ant-btn-icon-only.ant-input-search-button,
// .ant-input-number,
// .ant-picker {
//   background-color: transparent !important;
//   border-color: #001529 !important;
//   input {
//     background-color: transparent !important;
//     border-color: #001529 !important;
//   }
// }
// .ant-layout-has-sider .logo{
// padding: 16px;
// margin: 0 !important;
// height: 80px !important;
// }

// .ant-avatar {
//   background: #df5f1e !important;
// }
// * {
//   &:not(.ant-picker-dropdown.ant-picker-dropdown-placement-topLeft, .ant-select-item.ant-select-item-option.ant-select-item-option-active) {
//     color: white !important;
//   }
// }
// .ant-picker-dropdown.ant-picker-dropdown-placement-topLeft,
// .ant-select-item.ant-select-item-option.ant-select-item-option-active,
// .ant-select-dropdown,
// .ant-modal-root {
//   color: black !important;
//   * {
//     color: black !important;
//   }
//   .ant-select-selector,
//   .ant-input-affix-wrapper-lg,
//   input,
//   .ant-input-number,
//   .ant-picker {
//     background-color: transparent !important;
//     border: 1px solid #d9d9d9 !important;
//     input {
//       background-color: transparent !important;
//       border: none !important;
//     }
//   }
//   button {
//     color: white !important;
//     span {
//       color: white !important;
//     }
//   }
// }
// .ant-picker-header {
//   button {
//     color: black !important;
//     span {
//       color: black !important;
//     }
//   }
// }
// .ant-picker-footer {
//   color: #1890ff !important;
//   .ant-picker-today-btn {
//     color: #1890ff !important;
//   }
// }
// table {
//   * {
//     color: black !important;
//   }
// }

// .login100-form-bgbtn {
//   background: #3684c3 !important;
// }
// .second.ant-dropdown-link {
//   background-color: #007066 !important;
//   .dropdown-item {
//     color: black !important;
//   }
// }

// .ant-pagination {
//   background: white !important;
//   padding: 10px !important;
//   * {
//     color: black !important;
//   }
// }

// .ant-tag {
//   color: black !important;
// }

.ant-table-content {
  th.ant-table-cell {
    background: #ad3a3a !important;
    color: white !important;
    border: 1px solid white;
  }
  .ant-table-tbody tr:hover td{
    background-color: #dfc1c1 !important;
    
  }

  .ant-table-row tr,
  .ant-table-tbody tr,
  .ant-table-tbody td {
    &:hover {
      background-color: #dfc1c1 !important;
      td {
        background-color: #dfc1c1 !important;
      }
    }
  }
  .ant-table-tbody > tr > td {
    background: #fde1e1;
  }
  .ant-table-expanded-row {
    th {
      padding: 1px 10px !important;
      color: #ad3a3a;
    }
    td {
      padding: 1px 10px !important;
    }
  }
}
.ItemReport .w100P {
  border-radius: 50px;
  .ant-select-selector {
    border-radius: 50px !important;
  }
  .ant-input-search{
      border-top-left-radius: 50px;
      input{
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
    button{
      border-top-right-radius: 50px !important;
      border-bottom-right-radius: 50px !important;
    }
  }
}
.tableDiv{
  box-shadow: 0px 0px 6px -2px black;
  margin-bottom: 10px;
}