.certificateDiv {
    .innerCertificate {
        .results {
            width: 50%;
            .logoDiv {
                text-align: center;
                img {
                    margin-right: 81px;
                    width: 188px;
                }
            }
        }
        .details {
            width: 50%;
        }
    }
}
