@font-face {
  font-family: oldBookman;
  src: url("./../../assets/bookman-old-style/BOOKOSB.TTF");
}

.loaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  img {
    width: 130px;
    animation: imageLoader 3s infinite ease-in-out;
  }
  h1 {
    font-family: oldBookman;
    color: #d40000;
    font-size: 25px;
    animation: textLoader 2s infinite ease-in-out;
  }
}
.dayLoader{
  position: absolute;
  width: 100%;
  height: 100%;
  img {
  width: 60px;
  }
}
@keyframes textLoader {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes imageLoader {
  0% {
    transform: rotateY(1deg);
  }
//   10% {
//     transform: rotateY(1deg);
//   }
  50% {
    transform: rotateY(721deg);
  }
//   90% {
//     transform: rotateY(1deg);
//   }
  100% {
    transform: rotateY(1deg);
  }
}
