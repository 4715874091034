.dashboardMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 50px;
  &.limiter {
    .div {
      display: flex;
      margin-right: 20px;
      margin-top: 20px;
      .dropdown-toggle {
        &::after {
          margin: 0;
        }
        svg {
          display: none;
        }
      }
      .dropdown-menu {
        position: absolute;
        top: 50%;
        background-color: white;
        border: 1px solid #00152936;
        z-index: 999;
        border-radius: 5px;
        font-size: 16px;
        font-weight: normal;
        width: 200px;
        display: none;
        &.show {
        display: block;
          .dropdown-item {
            padding: 5px 20px;
            color: #001529;
            &:hover {
              background-color: #00152936;
            }
          }
          .dropdown-item + .dropdown-item {
            border-top: 1px solid #00152936;
          }
        }
      }
      .wrap-login100-form-btn {
        width: 178px;
        height: 130px;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        &.full {
          width: 200px;
          border-radius: 5px;
        }
        margin: 0;
        button {
          height: 100%;
        }
      }
      .second {
        height: 100%;
        width: 20px;
        background-color: #001529d2;
        color: white;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: bold;
        cursor: pointer;
        margin-left: 2px;
        position: relative;
        &:hover {
          background-color: #001529;
        }
      }
    }
  }
  .singleDiv {
    display: flex;
    width: 150px;
    height: 100px;
    justify-content: space-between;
    .first {
      height: 100%;
      width: 80%;
      background-color: #001529;
      color: white;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: bold;
    }
    .second {
      height: 100%;
      width: 19%;
      background-color: #001529;
      color: white;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: bold;
    }
  }
}
