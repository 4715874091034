.accountModal {
    .addDiv {
        .inputFields {
            .inputDiv {
                display: flex;
                button {
                    background: #4094ea;
                    border: 1px solid #4094ea;
                    color: white;
                    font-weight: bold;
                    padding: 3px 20px;
                    font-size: 12px;
                }
            }
        }
    }
}

.lockIcon{
    font-size: 13px;
}